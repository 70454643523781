import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import NavigationContainer from './NavigationContainer';
import Footer from './Footer';
import AlertsContainer from './AlertsContainer';
import logo from '../assets/images/albis_logo.png';

const Layout = ({ children }) => (
  <div>
    <div className="wrapper">
      <NavigationContainer />
      <b.Grid>
        <b.Row>
          <b.Col xs={12} sm={12} md={12}>
            {children}
          </b.Col>
        </b.Row>
        <b.Row>
          <b.Col xs={6} xsOffset={3} sm={4} smOffset={4} md={3} mdOffset={4}>
            <b.Image src={logo} responsive style={{ paddingTop: '20px' }} />
          </b.Col>
        </b.Row>
      </b.Grid>
    </div>
    <AlertsContainer />
    <Footer />
  </div>
);

Layout.propTypes = {
  children: T.element,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
