import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import formName from './formName';
import BigSpinner from '../../BigSpinner';
import structurizeValues from './structurizeValues';
import parseForm from './parseForm';
import * as act from '../../../promotion/actions';
import * as s from '../../../promotion/selectors';
import CreatePage from './CreatePage';

import handleValidationErrors from '../../../handleValidationErrors';

class CreatePageContainer extends Component {
  componentWillMount() {
    const { onShowPromotionUpdateForm } = this.props;
    onShowPromotionUpdateForm();
  }

  render() {
    const {
      isGetPromotionPending,
      isUpdatePending,
      onCancelUpdatePromotion,
      onDestroyForm,
      onUpdatePromotion,
      showPromotionUpdateFormForPromotionId,
    } = this.props;

    if (isGetPromotionPending) {
      return <BigSpinner />;
    }

    return (
      <CreatePage
        id={showPromotionUpdateFormForPromotionId}
        isUpdatePending={isUpdatePending}
        onCancelUpdatePromotion={() => onCancelUpdatePromotion(onDestroyForm)}
        onUpdatePromotion={(args) => onUpdatePromotion(onDestroyForm, args)}
      />
    );
  }
}

CreatePageContainer.propTypes = {
  isGetPromotionPending: T.bool.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancelUpdatePromotion: T.func.isRequired,
  onDestroyForm: T.func.isRequired,
  onShowPromotionUpdateForm: T.func.isRequired,
  onUpdatePromotion: T.func.isRequired,
  showPromotionUpdateFormForPromotionId: T.string.isRequired,
};

const mapStateToProps = (state) => ({
  isUpdatePending: s.getIsCreatePromotionPending(state),
  isGetPromotionPending: s.getIsGetPromotionPending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onShowPromotionUpdateForm() {
    dispatch(act.getContractTypes());
    dispatch(act.getProductGroups());
  },
  onCancelUpdatePromotion(onDestroyForm) {
    dispatch(destroy(formName));
    onDestroyForm();
  },
  onUpdatePromotion(onDestroyForm, formValues) {
    const parsedValues = parseForm(formValues);
    const structurizedValues = structurizeValues(parsedValues);

    return dispatch(act.updatePromotion(structurizedValues))
      .then(() => {
        onDestroyForm();
        dispatch(destroy(formName));
        dispatch(act.listPromotions());
      })
      .catch((err) => handleValidationErrors(
        err,
        'Die Edition des Sonderangebots ist fehlgeschlagen',
      ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageContainer);
