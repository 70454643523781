import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';

const navItems = [
  'Objektdaten eingeben',
  'Lieferant auswählen',
  'Rate auswählen',
  'Adressen eingeben und Antrag absenden',
];

const highlightNavItem = (text, isCurrent) => (
  isCurrent ? <b>{text}</b> : text
);

const NavItem = ({
  isCurrent,
  onClick,
  step,
  title,
}) => (
  <b.NavItem eventKey={step} disabled={!onClick} onClick={onClick}>
    {highlightNavItem(`Schritt ${step}: ${title}`, isCurrent)}
  </b.NavItem>
);

NavItem.propTypes = {
  isCurrent: T.bool.isRequired,
  onClick: T.func,
  step: T.number.isRequired,
  title: T.string.isRequired,
};

NavItem.defaultProps = {
  onClick: null,
};

const WizardNavigation = ({
  current,
  onClickHandlers,
}) => (
  <b.Nav stacked>
    {navItems.map((itemTitle, idx) => (
      <NavItem
        isCurrent={idx + 1 === current}
        key={itemTitle}
        onClick={onClickHandlers[idx]}
        step={idx + 1}
        title={itemTitle}
      />
    ))}
  </b.Nav>
);

WizardNavigation.propTypes = {
  current: T.number.isRequired,
  onClickHandlers: T.arrayOf(T.func.isRequired).isRequired,
};

export default WizardNavigation;
