import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import ListPage from './ListPage';
import BigSpinner from '../../BigSpinner';
import * as a from '../../../offer/actions';
import * as s from '../../../offer/selectors';
import * as u from '../../../user/selectors';
import * as m from '../../../offer/model';

class ListPageContainer extends Component {
  componentWillMount() {
    this.props.dispatch(a.listOffers());
  }

  render() {
    const {
      isPending, offers, retailerReferenceObj, userRole,
    } = this.props;
    if (isPending) {
      return <BigSpinner />;
    }
    return (
      <ListPage
        offers={offers}
        retailerReferenceObj={retailerReferenceObj}
        userRole={userRole}
      />
    );
  }
}

ListPageContainer.propTypes = {
  dispatch: T.func.isRequired,
  isPending: T.bool.isRequired,
  offers: T.arrayOf(m.offerSummaryShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  userRole: T.string,
};

ListPageContainer.defaultProps = {
  error: null,
  userRole: null,
};

const mapStateToProps = (state) => ({
  isPending: s.getIsListOffersPending(state),
  offers: s.listOffers(state),
  retailerReferenceObj: u.getRetailerReferenceObj(state),
  userRole: u.getUserRole(state),
}
);

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPageContainer);
