import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import redirect from '../../../redirect';
import formName from './formName';
import parseForm from './parseForm';
import CreatePage from './CreatePage';
import * as a from '../../../convertOffer/actions';
import * as offerAction from '../../../offer/actions';
import * as s from '../../../convertOffer/selectors';
import * as b from '../../../salesman/actions';
import * as u from '../../../user/selectors';
import * as l from '../../../lessee/actions';
import handleValidationErrors from '../../../handleValidationErrors';

class CreatePageContainer extends Component {
  componentWillMount() {
    const { dispatch, id, userRole } = this.props;
    dispatch(a.getContractTypes(userRole));
    dispatch(a.getProductGroups());
    dispatch(a.getLegalForms());
    dispatch(a.getRetailers());
    dispatch(a.listLeasingApplications());
    dispatch(b.listSalesmen());
    dispatch(offerAction.listOffers());
    dispatch(a.getOffer(id));
    if (userRole !== 'guest') {
      dispatch(b.listSalesmen());
    }
    if (userRole === 'lessee') {
      dispatch(l.getLesseeData());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userRole === 'guest' && this.props.userRole === 'lessee') {
      this.props.dispatch(b.listSalesmen());
    }
  }

  render() {
    const {
      isCreatePending,
      onCancelCreateApplication,
      onCreateApplication,
      id,
      userRole,
    } = this.props;
    return (
      <CreatePage
        isCreatePending={isCreatePending}
        onCancelCreateApplication={onCancelCreateApplication(userRole)}
        onCreateApplication={onCreateApplication}
        params={id}
      />
    );
  }
}

CreatePageContainer.propTypes = {
  dispatch: T.func.isRequired,
  isCreatePending: T.bool.isRequired,
  onCancelCreateApplication: T.func.isRequired,
  onCreateApplication: T.func.isRequired,
  id: T.number.isRequired,
  userRole: T.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.params.id, 10);
  return {
    userRole: u.getUserRole(state),
    id,
    isCreatePending: s.getIsCreateLeasingApplicationPending(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onCancelCreateApplication(userRole) {
    return () => {
      const path = userRole === 'guest' ? `/offers/${ownProps.params.id}` : '/applications';
      dispatch(destroy(formName));
      redirect.toPathname(path);
    };
  },
  onCreateApplication(formValues) {
    const parsedValues = parseForm(formValues);
    return dispatch(a.createLeasingApplication(parsedValues))
      .then((id) => {
        dispatch(destroy(formName));
        redirect.toPathname(`/applications/${id}`);
      })
      .catch((err) => handleValidationErrors(
        err,
        'Das Anlegen des Antrags ist fehlgeschlagen',
      ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageContainer);
