import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import 'react-select/dist/react-select.css';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import InputField from '../../InputField';
import SalutationRadioField from '../../SalutationRadioField';
import SelectField from '../../SelectField';
import formName from './formName';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import validate from './validate';
import { normalizeDate, normalizePostCode } from '../../normalizers';
import * as m from '../../../leasingApplication/model';

const checkStepsClickHandlers = (
  objectData,
  onStepObjectData,
  onStepRetailer,
  onStepRate,
) => {
  if (objectData.sale_and_lease_back) {
    return [onStepObjectData, null, onStepRate];
  }
  return [onStepObjectData, onStepRetailer, onStepRate];
};

const CreateWizardStepRegisterForm = ({
  registerError,
  handleSubmit,
  isGetLegalFormsPending,
  isPending,
  legalForms,
  objectData,
  onPrevious,
  onCancel,
  onStepObjectData,
  onStepRate,
  onStepRetailer,
  pristine,
  submitting,
  userEmail,
}) => (
  <b.Row>
    <b.Col sm={12} lg={3}>
      <WizardNavigation
        current={4}
        onClickHandlers={checkStepsClickHandlers(
          objectData,
          onStepObjectData,
          onStepRetailer,
          onStepRate,
        )}
      />
    </b.Col>
    <b.Col sm={12} lg={9}>
      <b.Form onSubmit={handleSubmit}>
        <legend>
          <b>Registrierung</b>
        </legend>
        {registerError && (
          <b.Alert bsStyle="danger">{registerError.message}</b.Alert>
        )}
        <div className="info-text">
          <strong>
            Bitte füllen Sie das folgende Formular vollständig aus. Ihre
            Adressdaten werden für die Einreichung einer Leasinganfrage
            benötigt.
          </strong>
          <strong>
            Mit der Übersendung Ihrer Daten akzeptieren Sie unsere
            {' '}
            <a href="/privacy" rel="noopener noreferrer" target="_blank">
              Datenschutzerklärung.
            </a>
          </strong>
        </div>
        <fieldset>
          <legend>Leasingnehmer</legend>
          <Field
            name="lessee_name"
            title="Firmenname"
            required
            component={InputField}
            type="text"
          />
          <Field
            name="lessee_legal_form"
            title={`Rechtsform ${isGetLegalFormsPending ? '…' : ''}`}
            required
            component={SelectField}
            values={_concat(
              [['', '']],
              _map(legalForms, (l) => [l.id, l.description]),
            )}
          />
          <Field
            name="lessee_street"
            title="Straße"
            required
            component={InputField}
            type="text"
          />
          <b.Row>
            <b.Col md={3}>
              <Field
                name="lessee_post_code"
                title="PLZ"
                required
                component={InputField}
                type="text"
                normalize={normalizePostCode}
              />
            </b.Col>
            <b.Col md={9}>
              <Field
                name="lessee_city"
                title="Ort"
                required
                component={InputField}
                type="text"
              />
            </b.Col>
          </b.Row>
          <Field
            name="lessee_phone"
            title="Telefonnummer"
            required
            component={InputField}
            type="text"
          />
          <div className="form-group">
            <b style={{ marginBottom: '5px' }}>E-Mail</b>
            <div
              style={{ backgroundColor: '#eeeeee' }}
              className="form-control"
            >
              {userEmail}
            </div>
          </div>
          <Field
            name="password"
            title="Passwort"
            required
            component={InputField}
            type="password"
          />
          <Field
            name="password_confirm"
            title="Passwort wiederholen"
            required
            component={InputField}
            type="password"
          />
        </fieldset>
        <div className="alert alert-info">
          Mit Ihrer E-Mail Adresse und Ihrem neuen Passwort können Sie sich
          anschließend jederzeit im LGH Portal anmelden, um Ihre Anträge und
          Angebote zu verwalten.
        </div>
        <fieldset>
          <legend>Geschäftsführer / Inhaber</legend>
          <Field
            name="manager_salutation"
            title="Anrede"
            required
            component={SalutationRadioField}
          />
          <Field
            name="manager_first_name"
            title="Vorname"
            required
            component={InputField}
            type="text"
          />
          <Field
            name="manager_last_name"
            title="Nachname"
            required
            component={InputField}
            type="text"
          />
          <Field
            name="manager_street"
            title="Straße"
            required
            component={InputField}
            type="text"
          />
          <b.Row>
            <b.Col md={3}>
              <Field
                name="manager_post_code"
                title="PLZ"
                required
                component={InputField}
                type="text"
                normalize={normalizePostCode}
              />
            </b.Col>
            <b.Col md={9}>
              <Field
                name="manager_city"
                title="Ort"
                required
                component={InputField}
                type="text"
              />
            </b.Col>
          </b.Row>
          <Field
            name="manager_birth_date"
            title="Geburtsdatum"
            required
            placeholder="TT.MM.JJJJ"
            component={InputField}
            type="text"
            normalize={normalizeDate}
          />
        </fieldset>
        <WizardButtons
          isPending={isPending}
          onCancel={onCancel}
          onPrevious={onPrevious}
          pristine={pristine}
          submitting={submitting}
        />
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepRegisterForm.propTypes = {
  handleSubmit: T.func.isRequired,
  isGetLegalFormsPending: T.bool.isRequired,
  isPending: T.bool.isRequired,
  legalForms: T.arrayOf(m.legalFormShape).isRequired,
  objectData: T.shape({ sale_and_lease_back: T.bool }).isRequired,
  onPrevious: T.func.isRequired,
  onCancel: T.func.isRequired,
  onStepObjectData: T.func.isRequired,
  onStepRate: T.func.isRequired,
  onStepRetailer: T.func.isRequired,
  pristine: T.bool.isRequired,
  registerError: T.any,
  submitting: T.bool.isRequired,
  userEmail: T.string.isRequired,
};

CreateWizardStepRegisterForm.defaultProps = {
  registerError: null,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepRegisterForm);
