import React from 'react';
import * as r from 'react-router';
import CreatePageContainer from '../components/salesman/create/CreatePageContainer';
import DetailsPageContainer from '../components/salesman/details/DetailsPageContainer';
import ListPageContainer from '../components/salesman/list/ListPageContainer';

const salesmanRoutes = (
  <r.Route path="salesmen">
    <r.IndexRoute components={ListPageContainer} />
    <r.Route path="create" components={CreatePageContainer} />
    <r.Route path=":id(/:detail)" components={DetailsPageContainer} />
  </r.Route>
);

export default salesmanRoutes;
