import React from 'react';
import * as r from 'react-router';
import CreatePageContainer from '../components/changeOffer/create/CreatePageContainer';

const convertOfferRoutes = (
  <r.Route path="change_offer">
    <r.Route path="edit/:id" components={CreatePageContainer} />
  </r.Route>
);

export default convertOfferRoutes;
