import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import * as m from '../../../leasingApplication/model';
import LeasingApplication from './LeasingApplication';
import ApplicationsFilters from './ApplicationsFilter';
import { albisSalesContactUL } from '../../../albisSalesContact';

const wellStyles = { margin: '0px 0px 25px 0px' };

const ListPage = ({
  leasingApplications, filterStatuses, retailerReferenceObj, userRole,
}) => {
  if (userRole === 'guest') {
    return (
      <div>
        <Helmet title="Anträge" />
        <div className="alert alert-info">
          <h5>
            Um Anträge einsehen zu können, müssen Sie sich zunächst
            vollständig registrieren. Sollten Sie Hilfe benötigen,
            kontaktieren Sie bitte den ALBIS Vertrieb:
          </h5>
          {albisSalesContactUL}
        </div>
      </div>
    );
  }
  return (
    <div>
      <Helmet title="Anträge" />
      <LinkContainer to="/applications/create">
        <b.Button bsStyle="primary" style={wellStyles}>
          <FA name="plus-circle icon-left" />
          Neuen Antrag stellen
        </b.Button>
      </LinkContainer>
      <ApplicationsFilters filterStatuses={filterStatuses} />

      {leasingApplications.map((app) => (
        <LeasingApplication
          key={app.id}
          app={app}
          retailerReferenceObj={retailerReferenceObj}
        />
      ))}
    </div>
  );
};

ListPage.propTypes = {
  filterStatuses: T.arrayOf(String).isRequired,
  leasingApplications: T.arrayOf(m.leasingApplicationSummaryShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  userRole: T.string,
};

ListPage.defaultProps = {
  userRole: false,
};

export default ListPage;
