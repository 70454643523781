import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import CreateWizard from './CreateWizard';

const CreatePage = ({
  isCreatePending,
  onCancelCreateInsurance,
  onCreateInsurance,
}) => (
  <div>
    <Helmet title="Insurance stellen" />
    <CreateWizard
      isCreatePending={isCreatePending}
      onCancel={onCancelCreateInsurance}
      onSubmit={onCreateInsurance}
    />
  </div>
);

CreatePage.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancelCreateInsurance: T.func.isRequired,
  onCreateInsurance: T.func.isRequired,
};

export default CreatePage;
