import moment from 'moment';
import accounting from 'accounting';
import { CONTRACT_TYPE_TA } from '../../../promotion/constants';

const parseForm = (values) => {
  const factorsObj = {};
  const insurancesObj = {};
  if (values.condition === 'factor') {
    const factors = Object.keys(values).filter((key) => key.includes('factor_value-'));
    factors.forEach((key) => {
      if (values[key]) factorsObj[key] = Number(values[key].replace(',', '.'));
    });
    const insurances = Object.keys(values).filter((key) => key.includes('insurance_parameters-'));
    insurances.forEach((key) => {
      if (values[key] && values[key] !== 'Versicherung') insurancesObj[key] = values[key];
    });
  }
  const interestCondition = {};
  if (values.condition === 'interest') {
    interestCondition.insurance_parameters = values.insurance_parameters;
    interestCondition.interest_condition = parseFloat(values.interest_condition.replace(',', '.'), 10);
  }

  const residualMandatory = Number(values.contract_type) === CONTRACT_TYPE_TA;

  let retailerArray = values.retailers ? values.retailers.replace(' ', '').split(',') : [];
  retailerArray = retailerArray.map((retailer) => Number(retailer.trim()))
    .filter((retailer) => retailer !== 0);

  // warning: due to DynamoDB is not handling Infinity as a value,
  // then we use null value for contract_type, down_payment_to,
  // financing_value_to and purchase_price_to instead

  let parsedData = {
    id: values.id,
    condition: values.condition,
    contract_term_from: parseInt(values.contract_term_from, 10) || 0,
    contract_term_to: values.contract_term_to === 'unendlich' ? null : parseInt(values.contract_term_to, 10),
    contract_type: parseInt(values.contract_type, 10),
    down_payment_from: accounting.unformat(values.down_payment_from, ',') || 0,
    down_payment_to: values.down_payment_to === 'unendlich' ? null : accounting.unformat(values.down_payment_to, ','),
    financing_value_from: accounting.unformat(values.financing_value_from, ',') || 0,
    financing_value_to: values.financing_value_to === 'unendlich' ? null : accounting.unformat(values.financing_value_to, ','),
    ...interestCondition,
    is_deactivated: values.is_deactivated || false,
    product_group: parseInt(values.product_group, 10),
    promotion_name: values.promotion_name,
    promotion_description: values.promotion_description || null,
    promotion_period_from: moment(values.promotion_period_from).startOf('day').toDate(),
    promotion_period_to: moment(values.promotion_period_to).startOf('day').toDate(),
    purchase_price_from: accounting.unformat(values.purchase_price_from, ',') || 0,
    purchase_price_to: values.purchase_price_to === 'unendlich' ? null : accounting.unformat(values.purchase_price_to, ','),
    region_from: values.region_from,
    region_to: values.region_to,
    retailers: retailerArray,
    retailerGroup: parseInt(values.retailer_group, 10),
    ...factorsObj,
    ...insurancesObj,
  };

  if (residualMandatory) {
    parsedData = {
      ...parsedData,
      residual_value_from: (values.residual_value_from
        && parseFloat(values.residual_value_from.replace(',', '.'), 10)) || 0,
      residual_value_to: (values.residual_value_to
        && parseFloat(values.residual_value_to.replace(',', '.'), 10)) || 0,
    };
  }

  return parsedData;
};

export default parseForm;
