import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import * as b from 'react-bootstrap';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import SelectField from '../../SelectField';
import PercentField from '../../PercentField';
import CheckboxField from '../../CheckboxField';
import WizardButtons from './WizardButtons';
import formName from './formName';
import parseForm from './parseForm';
import { normalizePercent, normalizeMoney } from '../../normalizers';
import validate from './validate';
import MoneyField from '../../MoneyField';

const CreateWizardStepDetails = ({
  error,
  formData,
  handleSubmit,
  onCancel,
  onSubmit,
  pristine,
  submitting,
}) => (
  <b.Row>
    <b.Col sm={12}>
      <b.Form>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        <h4 className="pb-3">Versicherung erstellen</h4>
        <div>
          <b.Row>
            <b.Col sm={4}>
              <b.ControlLabel>
                Versicherungsbetrag
                <span title="Pflichtfeld" className="asterisk-required">
                  *
                </span>
              </b.ControlLabel>
            </b.Col>
            <b.Col sm={8}>
              {formData.type === '%' ? (
                <Field
                  name="value"
                  required
                  component={PercentField}
                  type="text"
                  placeholder="(z.B. 1,25)"
                  normalize={normalizePercent}
                />
              ) : (
                <Field
                  name="value"
                  required
                  component={MoneyField}
                  type="text"
                  placeholder="(z.B. 15,50)"
                  normalize={normalizeMoney}
                />
              )}
            </b.Col>
          </b.Row>
          <b.Row>
            <b.Col sm={4}>
              <b.ControlLabel>
                Art
                <span title="Pflichtfeld" className="asterisk-required">
                  *
                </span>
              </b.ControlLabel>
            </b.Col>
            <b.Col sm={8}>
              <Field
                name="type"
                required
                component={SelectField}
                values={[['%', '%'], ['EUR', 'EUR']]}
              />
            </b.Col>
          </b.Row>
          {formData.type === '%' && (
            <b.Row>
              <b.Col sm={4}>
                <b.ControlLabel>
                  Standard Versicherungswert
                  <span title="Pflichtfeld" className="asterisk-required">
                    *
                  </span>
                </b.ControlLabel>
              </b.Col>
              <b.Col sm={8}>
                <Field
                  name="min_value"
                  required
                  component={MoneyField}
                  type="text"
                  placeholder="(z.B. 15,50)"
                  normalize={normalizeMoney}
                />
              </b.Col>
            </b.Row>
          )}
          <b.Row>
            <b.Col sm={4}>
              <b.ControlLabel />
            </b.Col>
            <b.Col sm={8}>
              <Field
                title="Deaktivieren"
                name="is_deactivated"
                type="checkbox"
                component={CheckboxField}
              />
            </b.Col>
          </b.Row>
          <WizardButtons
            handleSubmit={handleSubmit}
            onCancel={onCancel}
            onSubmit={onSubmit}
            pristine={pristine}
            submitting={submitting}
          />
        </div>
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepDetails.propTypes = {
  error: T.string,
  formData: T.any.isRequired,
  handleSubmit: T.func.isRequired,
  onCancel: T.func.isRequired,
  onSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepDetails.defaultProps = {
  error: null,
};

const fetchFormData = (state) => parseForm(formValueSelector(formName)(state,
  'value',
  'type'));

const mapStateToProps = (state) => ({
  formData: fetchFormData(state),
});

export default connect(mapStateToProps)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: false,
  initialValues: {
    type: '%',
    is_deactivated: false,
  },
  validate,
})(CreateWizardStepDetails));
