import _map from 'lodash/map';
import { NAME } from './constants';

export const getContractTypes = (state) => state[NAME].contractTypes || [];
export const getIsComputeRatesPending = (state) => !!state[NAME].isComputeRatesPending;
export const getIsCreateOfferPending = (state) => !!state[NAME].isCreateOfferPending;
export const getIsGetContractTypesPending = (state) => !!state[NAME].isGetContractTypesPending;
export const getIsGetOfferPending = (state) => !!state[NAME].isGetOfferPending;
export const getIsGetLegalFormsPending = (state) => !!state[NAME].isGetLegalFormsPending;
export const getIsGetProductGroupsPending = (state) => !!state[NAME].isGetProductGroupsPending;
export const getIsListOffersPending = (state) => !!state[NAME].isListOffersPending;
export const getOffer = (id) => (state) => state[NAME].offersById[id];
export const getLegalForms = (state) => state[NAME].legalForms || [];
export const getMonthOfCancellation = (state) => state[NAME].monthOfCancellation || 0;
export const getProductGroups = (state) => state[NAME].productGroups || [];
export const getRates = (state) => state[NAME].rates || [];
export const getResidualValue = (state) => state[NAME].residualValue || 0;
export const listOffers = (state) => _map(state[NAME].offers,
  (id) => state[NAME].offerSummariesById[id]);
