/* global axios */

import * as typ from './actionTypes';
import { contractRename } from '../utils/contractRename';

export function createPromotion(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.CREATE_PROMOTION_PENDING });
    return httpClient
      .post('/api_gateway/promotion', { promotion: values })
      .then(({ data }) => {
        dispatch({ type: typ.CREATE_PROMOTION_FULFILLED });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: typ.CREATE_PROMOTION_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function updatePromotion(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.UPDATE_PROMOTION_PENDING });
    return httpClient
      .put('/api_gateway/promotion', { promotion: values })
      .then(({ data }) => {
        dispatch({ type: typ.UPDATE_PROMOTION_FULFILLED });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: typ.UPDATE_PROMOTION_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function getPromotion(id, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.GET_PROMOTION_PENDING });
    return httpClient
      .get('/api_gateway/promotion', { params: { id } })
      .then(({ data }) => {
        dispatch({ type: typ.GET_PROMOTION_FULFILLED, payload: data });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: typ.GET_PROMOTION_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function listPromotions(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.LIST_PROMOTIONS_PENDING });
    return httpClient
      .get('/api_gateway/list_promotions')
      .then((res) => {
        dispatch({
          type: typ.LIST_PROMOTIONS_FULFILLED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: typ.LIST_PROMOTIONS_REJECTED,
          payload: err,
        });
      });
  };
}

export function getContractTypes(userRole, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.GET_CONTRACT_TYPES_PENDING });
    return httpClient
      .get('/api/get_contract_types')
      .then((res) => {
        res.data = contractRename(userRole, res);
        dispatch({
          type: typ.GET_CONTRACT_TYPES_FULFILLED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: typ.GET_CONTRACT_TYPES_REJECTED,
          payload: err,
        });
      });
  };
}

export function getProductGroups(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.GET_PRODUCT_GROUPS_PENDING });
    return httpClient
      .get('/api/get_product_groups')
      .then((res) => dispatch({
        type: typ.GET_PRODUCT_GROUPS_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: typ.GET_PRODUCT_GROUPS_REJECTED,
          payload: err,
        });
      });
  };
}
