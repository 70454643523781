import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _mapValues from 'lodash/mapValues';
import _groupBy from 'lodash/groupBy';
import * as t from './actionTypes';

export const initialState = {
  editIds: [],
  error: null,
  isGetSalesmanPending: false,
  isListSalesmenPending: false,
  isSaveSalesmanPending: false,
  salesmen: [],
  salesmenById: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case t.EDIT_START:
      return {
        ...state,
        editIds: [
          ...state.editIds,
          action.payload,
        ],
      };
    case t.EDIT_STOP:
      return {
        ...state,
        editIds: _filter(state.editIds, (id) => id !== action.payload),
      };
    case t.SAVE_SALESMAN_FULFILLED:
      return {
        ...state,
        error: null,
        isSaveSalesmanPending: false,
      };
    case t.SAVE_SALESMAN_PENDING:
      return {
        ...state,
        isSaveSalesmanPending: true,
      };
    case t.SAVE_SALESMAN_REJECTED:
      return {
        ...state,
        isSaveSalesmanPending: false,
        error: action.payload,
      };
    case t.GET_SALESMAN_FULFILLED:
      return {
        ...state,
        error: null,
        isGetSalesmanPending: false,
        salesmenById: {
          ...state.salesmenById,
          [action.payload.id]: action.payload,
        },
      };
    case t.GET_SALESMAN_PENDING:
      return {
        ...state,
        isGetSalesmanPending: true,
      };
    case t.GET_SALESMAN_REJECTED:
      return {
        ...state,
        isGetSalesmanPending: false,
        error: action.payload,
      };
    case t.LIST_SALESMEN_FULFILLED:
      return {
        ...state,
        error: null,
        isListSalesmenPending: false,
        salesmen: _map(action.payload, (s) => s.id),
        salesmenById: _mapValues(
          _groupBy(action.payload, (s) => s.id),
          (array) => array[0],
        ),
      };
    case t.LIST_SALESMEN_PENDING:
      return {
        ...state,
        isListSalesmenPending: true,
      };
    case t.LIST_SALESMEN_REJECTED:
      return {
        ...state,
        isListSalesmenPending: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
