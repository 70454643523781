import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _isEmpty from 'lodash/isEmpty';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _take from 'lodash/take';
import _slice from 'lodash/slice';
import _reject from 'lodash/reject';
import InputField from '../../InputField';
import SelectField from '../../SelectField';
import formName from './formName';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import validate from './validate';
import { normalizePostCode } from '../../normalizers';
import { MAGIC_NEW_RETAILER_ID } from '../../../changeApplication/constants';
import * as m from '../../../changeApplication/model';

const retailersSelectOptions = (retailers) => {
  const nonEmpty = _reject(retailers, (r) => _isEmpty(_compact([r.name, r.post_code, r.city, r.street, r.phone, r.email])));
  const label = (retailer) => _join(_compact([retailer.name, retailer.post_code, retailer.city, retailer.street, retailer.phone, retailer.email]), ', ');
  const options = _map(nonEmpty, (r) => ([r.id, label(r)]));
  const edekaRetailers = _take(options, 8);
  const restOfRetailers = _slice(options, 8);
  return _concat([['', '']], edekaRetailers, [[MAGIC_NEW_RETAILER_ID, '➜ neuen Lieferanten hinzufügen…']], restOfRetailers);
};

const CreateWizardStepRetailerForm = ({
  error,
  handleSubmit,
  isGetRetailersPending,
  onCancel,
  onStepObjectData,
  pristine,
  retailers,
  submitting,
  wantsNewRetailer,
  checkApplication,
}) => (
  <b.Row>
    <b.Col sm={12} lg={3}>
      <WizardNavigation
        current={2}
        onClickHandlers={[onStepObjectData]}
      />
    </b.Col>
    <b.Col sm={12} lg={9}>
      <b.Form onSubmit={handleSubmit}>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        {checkApplication.length > 0
        && (
        <div>
          <p style={{ color: 'red' }}>
            {' '}
            Sie haben Änderungen in den
            Antragsdaten vorgenommen, die eine erneute Prüfung erfordern:
          </p>
          <ul>{checkApplication.map((i) => <li style={{ color: 'red' }}>{i}</li>)}</ul>
          <hr />
        </div>
        )}
        <fieldset>
          <legend>Lieferant</legend>
          <Field
            name="retailer_id"
            title={`Großhandelszentrum / Lieferant ${isGetRetailersPending ? '…' : ''}`}
            component={SelectField}
            required
            disabled="disabled"
            values={retailersSelectOptions(retailers)}
          />
          {wantsNewRetailer
            && (
            <div>
              <Field
                name="retailer_name"
                title="Name"
                required
                component={InputField}
                type="text"
              />
              <Field
                name="retailer_street"
                title="Straße"
                required
                component={InputField}
                type="text"
              />
              <b.Row>
                <b.Col md={3}>
                  <Field
                    name="retailer_post_code"
                    title="PLZ"
                    required
                    component={InputField}
                    type="text"
                    normalize={normalizePostCode}
                  />
                </b.Col>
                <b.Col md={9}>
                  <Field
                    name="retailer_city"
                    title="Ort"
                    required
                    component={InputField}
                    type="text"
                  />
                </b.Col>
              </b.Row>
              <Field
                name="retailer_phone"
                title="Telefon"
                required
                component={InputField}
                type="text"
              />
              <Field
                name="retailer_email"
                title="E-Mail"
                required
                component={InputField}
                type="text"
              />
            </div>
            )}
        </fieldset>
        <WizardButtons
          onCancel={onCancel}
          onPrevious={onStepObjectData}
          pristine={pristine}
          submitting={submitting}
        />
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepRetailerForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  isGetRetailersPending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onStepObjectData: T.func.isRequired,
  pristine: T.bool.isRequired,
  retailers: T.arrayOf(m.retailerShape).isRequired,
  submitting: T.bool.isRequired,
  wantsNewRetailer: T.bool.isRequired,
  checkApplication: T.arrayOf(String).isRequired,
};

CreateWizardStepRetailerForm.defaultProps = {
  error: null,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepRetailerForm);
