import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import CreateForm from './CreateForm';
import initialValues from './initialValues';

const CreatePage = ({
  isCreatePending,
  onCancelCreate,
  onCreate,
}) => (
  <div>
    <Helmet title="Verkäufer anlegen" />
    <CreateForm
      initialValues={initialValues({
        active: true,
        only_own: false,
      })}
      isPending={isCreatePending}
      onCancel={onCancelCreate}
      onSubmit={onCreate}
    />
  </div>
);

CreatePage.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancelCreate: T.func.isRequired,
  onCreate: T.func.isRequired,
};

export default CreatePage;
