import React from 'react';
import { PropTypes as T } from 'prop-types';
import { Provider } from 'react-redux';
import * as r from 'react-router';
import HttpsRedirect from 'react-https-redirect';

import { apiUrl } from '../axios';
import history from './history';
import Layout from '../components/Layout';

import beforeFilters from './beforeFilters';
import DashboardPageContainer from '../components/dashboard/DashboardPageContainer';
import leasingApplicationRoutes from './leasingApplicationRoutes';
import changeApplicationRoutes from './changeApplicationRoutes';

import offerRoutes from './offerRoutes';
import convertOfferRoutes from './convertOfferRoutes';
import changeOfferRoutes from './changeOfferRoutes';

import promotionModuleRoutes from './promotionModuleRoutes';

import salesmanRoutes from './salesmanRoutes';
import ErrorNotFoundPage from '../components/static/ErrorNotFoundPage';
import ContactPage from '../components/static/ContactPage';
import ImprintPage from '../components/static/ImprintPage';
import PrivacyPage from '../components/static/PrivacyPage';
import TermsPage from '../components/static/TermsPage';

const Routes = ({ store }) => {
  const f = beforeFilters();
  return (
    <Provider store={store}>
      <HttpsRedirect>
        <r.Router history={history}>
          <r.Route
            path="/set_password"
            component={({ location }) => {
              window.location = `${apiUrl}/set_password${location.search}`;
              return null;
            }}
          />
          <r.Route path="/" component={Layout} onEnter={f.requireAuth}>
            <r.IndexRoute component={DashboardPageContainer} />
            <r.Route>
              {leasingApplicationRoutes}
              {changeApplicationRoutes}
              {salesmanRoutes}
              {offerRoutes}
              {convertOfferRoutes}
              {changeOfferRoutes}
              {promotionModuleRoutes}
            </r.Route>
          </r.Route>
          <r.Route component={Layout}>
            <r.Route path="contact" components={ContactPage} />
            <r.Route path="imprint" components={ImprintPage} />
            <r.Route path="privacy" components={PrivacyPage} />
            <r.Route path="terms" components={TermsPage} />
            <r.Route name="404: Not Found" path="*" component={ErrorNotFoundPage} status={404} />
          </r.Route>
        </r.Router>
      </HttpsRedirect>
    </Provider>
  );
};
Routes.propTypes = {
  store: T.shape({
    subscribe: T.func.isRequired,
    dispatch: T.func.isRequired,
    getState: T.func.isRequired,
  }).isRequired,
};

export default Routes;
