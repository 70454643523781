/* global axios */

import * as t from './actionTypes';
import { contractRename } from '../utils/contractRename';

export function clearRates() {
  return { type: t.CLEAR_RATES };
}

export function computeRates(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.COMPUTE_RATES_PENDING });
    return httpClient
      .post('/api_gateway/compute_rates', values)
      .then((res) => dispatch({
        type: t.COMPUTE_RATES_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: t.COMPUTE_RATES_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function createOffer(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.CREATE_OFFER_PENDING });
    return httpClient
      .post('/api/create_offer', values)
      .then(({ data }) => {
        dispatch({ type: t.CREATE_OFFER_FULFILLED });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: t.CREATE_OFFER_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function getContractTypes(userRole, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.GET_CONTRACT_TYPES_PENDING });
    return httpClient
      .get('/api/get_contract_types')
      .then((res) => {
        res.data = contractRename(userRole, res);
        dispatch({
          type: t.GET_CONTRACT_TYPES_FULFILLED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.GET_CONTRACT_TYPES_REJECTED,
          payload: err,
        });
      });
  };
}

export function getOffer(id, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.GET_OFFER_PENDING });
    return httpClient
      .post('/api/get_offer', { id })
      .then((res) => {
        dispatch({
          type: t.GET_OFFER_FULFILLED,
          payload: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        dispatch({
          type: t.GET_OFFER_REJECTED,
          payload: err,
        });
      });
  };
}

export function getLegalForms(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.GET_LEGAL_FORMS_PENDING });
    return httpClient
      .get('/api/get_legal_forms')
      .then((res) => dispatch({
        type: t.GET_LEGAL_FORMS_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: t.GET_LEGAL_FORMS_REJECTED,
          payload: err,
        });
      });
  };
}

export function getProductGroups(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.GET_PRODUCT_GROUPS_PENDING });
    return httpClient
      .get('/api/get_product_groups')
      .then((res) => dispatch({
        type: t.GET_PRODUCT_GROUPS_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: t.GET_PRODUCT_GROUPS_REJECTED,
          payload: err,
        });
      });
  };
}

export function listOffers(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.LIST_OFFERS_PENDING });
    return httpClient
      .post('/api/list_offers')
      .then((res) => dispatch({
        type: t.LIST_OFFERS_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: t.LIST_OFFERS_REJECTED,
          payload: err,
        });
      });
  };
}
