/* global axios */

import * as t from './actionTypes';

export function editStart(id) {
  return {
    type: t.EDIT_START,
    payload: id,
  };
}

export function editStop(id) {
  return {
    type: t.EDIT_STOP,
    payload: id,
  };
}

export function saveSalesman(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.SAVE_SALESMAN_PENDING });
    return httpClient.post('/api/save_salesman', values)
      .then(({ data: { id } }) => {
        dispatch({ type: t.SAVE_SALESMAN_FULFILLED });
        return id;
      })
      .catch((err) => {
        dispatch({
          type: t.SAVE_SALESMAN_REJECTED,
          payload: err,
        });
        throw (err);
      });
  };
}

export function getSalesman(id, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.GET_SALESMAN_PENDING });
    return httpClient.post('/api/get_salesman', { id })
      .then((res) => dispatch({
        type: t.GET_SALESMAN_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: t.GET_SALESMAN_REJECTED,
          payload: err,
        });
      });
  };
}

export function listSalesmen(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.LIST_SALESMEN_PENDING });
    return httpClient.get('/api/list_salesmen')
      .then((res) => dispatch({
        type: t.LIST_SALESMEN_FULFILLED,
        payload: res.data,
      }))
      .catch((err) => {
        dispatch({
          type: t.LIST_SALESMEN_REJECTED,
          payload: err,
        });
      });
  };
}
