import React from 'react';

const albisSalesContact = [
  {
    phone: '+49 (0)40-808 100-656',
    email: 'vid@lgh-leasing.de',
  },
];

const albisSalesContactUL = (
  <ul>
    {albisSalesContact.map((c) => (
      <li key={c.email}>
        <p>
          T
          {' '}
          {c.phone}
          ,
          <br />
          <a href={`mailto:${c.email}`} target="_blank" rel="noopener noreferrer">
            {c.email}
          </a>
        </p>
      </li>
    ))}
  </ul>
);

export default albisSalesContact;
export { albisSalesContactUL };
