import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import Spinner from '../../Spinner';

const WizardButtons = ({
  handleSubmit,
  isPending,
  isSummaryStep,
  onCancel,
  onPrevious,
  onSubmit,
  pristine,
  submitting,
}) => {
  let text = 'Weiter';
  if (isSummaryStep) text = 'Versicherung aktualisieren';

  return (
    <div>
      <b.Button bsSize="sm" onClick={onCancel}>
        Abbrechen
      </b.Button>
      <div style={{ float: 'right' }}>
        {onPrevious && (
          <b.Button bsSize="sm" onClick={onPrevious}>
            <FA name="angle-double-left icon-left" />
            Werte ändern
          </b.Button>
        )}
        {' '}
        <b.Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          bsStyle="primary"
          bsSize="sm"
          disabled={pristine || submitting}
        >
          {isPending && <Spinner />}
          {text}
          <FA name="angle-double-right icon-right" />
        </b.Button>
      </div>
      <p className="hint-required">
        Pflichtfeld
        <span className="asterisk-required">*</span>
      </p>
    </div>
  );
};

WizardButtons.propTypes = {
  handleSubmit: T.func,
  isPending: T.bool,
  isSummaryStep: T.bool,
  onCancel: T.func.isRequired,
  onPrevious: T.func,
  onSubmit: T.func,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

WizardButtons.defaultProps = {
  handleSubmit: null,
  isPending: false,
  isSummaryStep: false,
  onPrevious: null,
  onSubmit: null,
};

export default WizardButtons;
