import * as t from './actionTypes';

export const initialState = {
  lesseeData: {},
  isGetLesseeDataPending: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case t.LESSEE_DATA_PENDING:
      return {
        ...state,
        isGetLesseeDataPending: true,
      };
    case t.LESSEE_DATA_FULFILLED:
      return {
        ...state,
        error: null,
        isGetLesseeDataPending: false,
        lesseeData: action.payload,
      };
    case t.LESSEE_DATA_REJECTED:
      return {
        ...state,
        isGetLesseeDataPending: false,
        error: action.payload,
        lesseeData: {},
      };
    default:
      return state;
  }
}
