import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import * as m from '../../../salesman/model';
import ViewField from '../../ViewField';
import { formatSalutation, formatBoolean } from '../../formatters';

const View = ({
  onEdit,
  salesman,
}) => (
  <div>
    <ViewField title="ID" value={salesman.id} />
    <ViewField
      title="Anrede"
      value={formatSalutation(salesman.salutation)}
    />
    <ViewField
      title="Vorname"
      value={salesman.first_name}
    />
    <ViewField
      title="Nachname"
      value={salesman.last_name}
    />
    <ViewField
      title="Telefon"
      value={salesman.phone}
    />
    <ViewField
      title="E-Mail"
      value={salesman.email}
    />
    <ViewField
      title="Aktiv?"
      value={formatBoolean(salesman.active)}
    />
    <ViewField
      title="Kann nur eigene Anträge/Angebote sehen?"
      value={formatBoolean(salesman.only_own)}
    />
    <b.Button onClick={onEdit}>
      Bearbeiten
    </b.Button>
  </div>
);

View.propTypes = {
  onEdit: T.func.isRequired,
  salesman: m.salesmanShape.isRequired,
};

export default View;
