import accounting from 'accounting';
import moment from 'moment';

export const formatMoney = (amount) => accounting.formatMoney(amount, {
  decimal: ',',
  format: '%v %s',
  precision: 2,
  symbol: 'EUR',
  thousand: '.',
});

export const formatPercent = (number) => `${number.toFixed(2)} %`.replace('.', ',');

export const formatDate = (date) => moment(date).format('DD.MM.YYYY');

export const formatDateTime = (date) => moment(date).format('DD.MM.YYYY HH:mm:ss');

export const formatSalutation = (number) => {
  switch (number) {
    case 1:
      return 'Herr';
    case 2:
      return 'Frau';
    default:
      return undefined;
  }
};

export const formatBoolean = (value) => (value ? 'Ja' : 'Nein');
