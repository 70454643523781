import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { NAME as alertName } from './alert/constants';
import { NAME as leasingApplicationName } from './leasingApplication/constants';
import { NAME as changeApplicationName } from './changeApplication/constants';

import { NAME as offerName } from './offer/constants';
import { NAME as convertOfferName } from './convertOffer/constants';
import { NAME as changeOfferName } from './changeOffer/constants';

import { NAME as promotionName } from './promotion/constants';
import { NAME as insuranceName } from './insurance/constants';

import { NAME as salesmanName } from './salesman/constants';
import { NAME as lesseeName } from './lessee/constants';
import { NAME as userName } from './user/constants';

import alertReducer from './alert/reducer';
import leasingApplicationReducer from './leasingApplication/reducer';
import changeApplicationReducer from './changeApplication/reducer';

import offerReducer from './offer/reducer';
import convertOfferReducer from './convertOffer/reducer';
import changeOfferReducer from './changeOffer/reducer';

import promotion from './promotion/reducer';
import promotionCreateForm from './promotion/reducerCreateForm';
import promotionUpdateForm from './promotion/reducerUpdateForm';
import insurance from './insurance/reducer';

import salesmanReducer from './salesman/reducer';
import lesseeReducer from './lessee/reducer';
import userReducer from './user/reducer';

export default combineReducers({
  [alertName]: alertReducer,
  [leasingApplicationName]: leasingApplicationReducer,
  [changeApplicationName]: changeApplicationReducer,

  [offerName]: offerReducer,
  [convertOfferName]: convertOfferReducer,
  [changeOfferName]: changeOfferReducer,

  [promotionName]: promotion,
  [insuranceName]: insurance,

  [salesmanName]: salesmanReducer,
  [lesseeName]: lesseeReducer,
  [userName]: userReducer,
  form: formReducer.plugin({
    create_promotion: promotionCreateForm,
    update_promotion: promotionUpdateForm,
  }),
});
