import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import FA from 'react-fontawesome';
import { connect } from 'react-redux';
import formName from './formName';
import * as act from '../../../promotion/actionsUpdateForm';
import * as s from '../../../promotion/selectorsUpdateForm';
import * as sel from '../../../insurance/selectors';
import WizardButtons from './WizardButtons';
import SelectField from '../../SelectField';
import PercentField from '../../PercentField';
import InputField from '../../InputField';
import MoneyField from '../../MoneyField';
import validate from './validate';
import {
  normalizePercent,
  normalizeMoney,
  normalizeIntegers,
} from '../../normalizers';

class CreateWizardStepConditionFactor extends Component {
  state = {
    newColumnInput: '',
    newRowInput: '',
  }

  componentDidMount() {
    this.props.onUpdateTable();
  }

  callAddColumn = () => {
    const { columnsArray, onAddColumn } = this.props;
    const colInput = this.state.newColumnInput.replace(',', '.');
    if (colInput
      && !columnsArray.includes(Number(colInput))
    ) {
      onAddColumn(colInput);
    }
  }

  callOnAddRow = () => {
    const { rowsArray, onAddRow } = this.props;
    const rowInput = this.state.newRowInput;
    if (rowInput
      && !rowsArray.includes(rowInput)) {
      onAddRow(rowInput);
    }
  }

  render() {
    const {
      columnsArray,
      error,
      handleSubmit,
      insurances,
      onCancel,
      onDeleteColumn,
      onDeleteRow,
      onSubmit,
      onPrevious,
      pristine,
      rowsArray,
      submitting,
    } = this.props;
    const tableHeader = () => (
      <thead>
        <tr>
          <th key="header-terms/purchasePrice" className="text-center pb-2">
            {`Vertragslaufzeit
            /Kaufpreis`}
          </th>
          {columnsArray.map((col) => (
            <th key={`header-${col}`} className="text-center pb-3">
              {col}
              {' '}
              €
            </th>
          ))}
          <th key="header-deleteButtons" className="text-center" />
        </tr>
      </thead>
    );

    const tableRow = (row) => {
      if (row !== 'last') {
        return (
          <tr key={row} className="list-table-row text-center">
            <td key={`titleRow-${row}`} className="pt-4">{row}</td>
            {columnsArray.map((col) => (
              <td key={`${row} - ${col}`} className="pt-3 pb-3">
                <b.Row>
                  <b.Col sm={6} className="pr-1">
                    <Field
                      name={`factor_value-row${row}-col${col}`}
                      classNames="mb-0"
                      component={PercentField}
                      type="text"
                      placeholder="z.B. 1,25"
                      normalize={normalizePercent}
                      hideAddon
                    />
                  </b.Col>
                  <b.Col sm={6} className="pl-1">
                    <Field
                      name={`insurance_parameters-row${row}-col${col}`}
                      classNames="mb-0"
                      component={SelectField}
                      values={[[null, 'Versicherung'], ...insurances]}
                    />
                  </b.Col>
                </b.Row>
              </td>
            ))}
            <td key={`deleteButtonRow-${row}`} className="pt-4">
              <b.Button
                className="btn btn-link"
                bsSize="xs"
                onClick={() => onDeleteRow(row)}
              >
                <FA name="times-circle" />
              </b.Button>
            </td>
          </tr>
        );
      }
      return (
        <tr key={row} className="list-table-row text-center">
          <td key="titleRow-deleteButtons" />
          {columnsArray.map((col) => (
            <td key={`deleteButtonColumn-${col}`}>
              <b.Button
                className="btn btn-link"
                bsSize="xs"
                onClick={() => onDeleteColumn(col)}
              >
                <FA name="times-circle" />
              </b.Button>
            </td>
          ))}
          <td key="titleRow-empty" />
        </tr>
      );
    };

    return (
      <b.Row>
        <b.Col sm={12}>
          <b.Form>
            {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
            <h4 className="pb-2">Konditionsstaffel</h4>
            <b.Table striped bordered condensed>
              {tableHeader()}
              <tbody>{rowsArray.map((row) => tableRow(row))}</tbody>
            </b.Table>
            <div className="pb-4">
              <b.Row>
                <b.Col sm={3}>
                  <Field
                    name="new_row"
                    component={InputField}
                    type="text"
                    placeholder="z.B. 12"
                    normalize={normalizeIntegers}
                    onKeyUp={(e) => this.setState({
                      newRowInput: e.target.value,
                    })}
                  />
                </b.Col>
                <b.Col sm={2}>
                  <b.Button
                    bsStyle="primary"
                    bsSize="sm"
                    className="mr-2"
                    style={{ width: '180px', height: '34px' }}
                    onClick={this.callOnAddRow}
                  >
                    <FA name="plus-circle icon-left" />
                    Vertragslaufzeit hinzufügen
                  </b.Button>
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={3}>
                  <Field
                    name="new_column"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    placeholder="z.B. 1000,00"
                    normalize={normalizeMoney}
                    onChange={(e) => this.setState({
                      newColumnInput: e.target.value,
                    })}
                  />
                </b.Col>
                <b.Col sm={2}>
                  <b.Button
                    bsStyle="primary"
                    bsSize="sm"
                    style={{ width: '180px', height: '34px' }}
                    onClick={this.callAddColumn}
                  >
                    <FA name="plus-circle icon-left" />
                    Kaufpreis hinzufügen
                  </b.Button>
                </b.Col>
              </b.Row>
            </div>
            <WizardButtons
              handleSubmit={handleSubmit}
              isConditionStep
              onCancel={onCancel}
              onSubmit={onSubmit}
              onPrevious={onPrevious}
              pristine={pristine}
              submitting={submitting}
            />
          </b.Form>
        </b.Col>
      </b.Row>
    );
  }
}

CreateWizardStepConditionFactor.propTypes = {
  columnsArray: T.arrayOf(T.number).isRequired,
  error: T.string,
  handleSubmit: T.func.isRequired,
  insurances: T.arrayOf(T.arrayOf(T.string)).isRequired,
  onAddColumn: T.func.isRequired,
  onAddRow: T.func.isRequired,
  onCancel: T.func.isRequired,
  onDeleteColumn: T.func.isRequired,
  onDeleteRow: T.func.isRequired,
  onPrevious: T.func.isRequired,
  onSubmit: T.func.isRequired,
  onUpdateTable: T.func.isRequired,
  pristine: T.bool.isRequired,
  rowsArray: T.arrayOf(T.string).isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepConditionFactor.defaultProps = {
  error: null,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onDeleteColumn(col) {
    dispatch(act.deleteColumn(col));
    dispatch(act.updateTable());
  },
  onDeleteRow(row) {
    dispatch(act.deleteRow(row));
    dispatch(act.updateTable());
  },
  onAddColumn(colInput) {
    dispatch(act.addColumn(colInput));
    dispatch(act.updateTable());
  },
  onAddRow(rowInput) {
    dispatch(act.addRow(rowInput));
    dispatch(act.updateTable());
  },
  onUpdateTable() {
    dispatch(act.updateTable());
  },
});

const mapStateToProps = (state) => ({
  columnsArray: s.getColumnsArray(state),
  insurances: sel.listInsurancesForSelectField(state),
  rowsArray: s.getRowsArray(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepConditionFactor));
