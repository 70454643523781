import { PropTypes as T } from 'prop-types';

export const productGroupPropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const productGroupShape = T.shape(productGroupPropTypes);

export const contractTypePropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const contractTypeShape = T.shape(contractTypePropTypes);

export const promotionSummaryPropTypes = {
  id: T.string.isRequired,
  promotionName: T.string.isRequired,
  promotionDescription: T.string,
  promotionPeriod: T.shape({
    from: T.string.isRequired,
    to: T.string.isRequired,
  }),
  contractType: T.number,
  contractTerm: T.shape({ from: T.number, to: T.number }),
  productGroup: T.number,
  downPayment: T.shape({ from: T.number, to: T.number }),
  purchasePrice: T.shape({ from: T.number, to: T.number }),
  financingValue: T.shape({ from: T.number, to: T.number }),
  retailers: T.arrayOf(T.number),
  region: T.shape({ from: T.string, to: T.string }),
  condition: T.oneOfType([
    T.shape({
      interestValue: T.number,
      insurance: T.shape({ value: T.number, type: T.string, min_value: T.number }),
    }),
    T.shape({
      elems: T.arrayOf(
        T.shape({
          purchasePrice: T.number,
          contractTerms: T.Object,
        }),
      ),
    }),
  ]),
};

export const promotionSummaryShape = T.shape(promotionSummaryPropTypes);
