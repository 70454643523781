import { NAME } from './constants';

export const GET_PROMOTION_PENDING = `${NAME}/GET_PROMOTION_PENDING`;
export const GET_PROMOTION_FULFILLED = `${NAME}/GET_PROMOTION_FULFILLED`;
export const GET_PROMOTION_REJECTED = `${NAME}/GET_PROMOTION_REJECTED`;
export const LIST_PROMOTIONS_FULFILLED = `${NAME}/LIST_PROMOTIONS_FULFILLED`;
export const LIST_PROMOTIONS_PENDING = `${NAME}/LIST_PROMOTIONS_PENDING`;
export const LIST_PROMOTIONS_REJECTED = `${NAME}/LIST_PROMOTIONS_REJECTED`;
export const CREATE_PROMOTION_PENDING = `${NAME}/CREATE_PROMOTION_PENDING`;
export const CREATE_PROMOTION_FULFILLED = `${NAME}/CREATE_PROMOTION_FULFILLED`;
export const CREATE_PROMOTION_REJECTED = `${NAME}/CREATE_PROMOTION_REJECTED`;
export const UPDATE_PROMOTION_PENDING = `${NAME}/UPDATE_PROMOTION_PENDING`;
export const UPDATE_PROMOTION_FULFILLED = `${NAME}/UPDATE_PROMOTION_FULFILLED`;
export const UPDATE_PROMOTION_REJECTED = `${NAME}/UPDATE_PROMOTION_REJECTED`;
export const GET_CONTRACT_TYPES_FULFILLED = `${NAME}/GET_CONTRACT_TYPES_FULFILLED`;
export const GET_CONTRACT_TYPES_PENDING = `${NAME}/GET_CONTRACT_TYPES_PENDING`;
export const GET_CONTRACT_TYPES_REJECTED = `${NAME}/GET_CONTRACT_TYPES_REJECTED`;
export const GET_PRODUCT_GROUPS_FULFILLED = `${NAME}/GET_PRODUCT_GROUPS_FULFILLED`;
export const GET_PRODUCT_GROUPS_PENDING = `${NAME}/GET_PRODUCT_GROUPS_PENDING`;
export const GET_PRODUCT_GROUPS_REJECTED = `${NAME}/GET_PRODUCT_GROUPS_REJECTED`;
