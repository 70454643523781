import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as b from 'react-bootstrap';
import * as a from '../../../leasingApplication/actions';
import * as u from '../../../user/selectors';

class ApplicationsFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: '',
      startDate: '',
      endDate: '',
      startPrice: '',
      endPrice: '',
      lesseeName: '',
      reference: '',
    };
    this.filterOptions = this.filterOptions.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
    this.onChangeValues = this.onChangeValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const name = event.target.id;
    this.setState({ [name]: event.target.value });
  }

  onChangeOption(values) {
    this.setState({
      selectedOption: values.value || null,
    });
  }

  onChangeValues(values) {
    this.setState({
      selectedValues: values.value || null,
    });
  }

  filterOptions() {
    const filters = {
      start_date: this.state.startDate || null,
      end_date: this.state.endDate || null,
      lessee_name: this.state.lesseeName || null,
      start_price: parseInt(this.state.startPrice, 10) || null,
      end_price: parseInt(this.state.endPrice, 10) || null,
      reference: this.state.reference || null,
      selected_status: this.state.selectedValues || null,
    };

    this.props.dispatch(a.listLeasingApplications(filters));
  }

  clearFilter() {
    this.props.dispatch(a.listLeasingApplications());
  }

  render() {
    const { retailerReferenceObj } = this.props;
    const options = [
      { value: ' ', label: ' ' },
      { value: 'status', label: 'Status' },
      { value: 'date', label: 'Zeitraum' },
      { value: 'purchase_price', label: 'Objektwert' },
      { value: 'lessee_name', label: 'Leasingnehmer' },
    ];
    if (retailerReferenceObj.isReferenceFieldRequired) {
      options.push({ value: 'reference', label: retailerReferenceObj.referenceDescription });
    }
    return (
      <div>
        <Select
          name="Filter Option"
          options={options}
          onChange={this.onChangeOption}
          value={this.state.selectedOption}
          className="basic-multi-select"
          classNamePrefix="Statuses"
          style={{ width: 200, marginBottom: '25px' }}
          placeholder="Filter"
        />
        {this.state.selectedOption === 'date' && (
          <div>
            <input
              type="date"
              id="startDate"
              placeholder="von"
              style={{ width: 150, display: 'flex', marginBottom: '25px' }}
              onChange={this.handleChange}
            />
            <input
              type="date"
              id="endDate"
              placeholder="bis"
              style={{ width: 150, display: 'flex', marginBottom: '25px' }}
              onChange={this.handleChange}
            />
          </div>
        )}
        {this.state.selectedOption === 'status' && (
          <div>
            <Select
              name="Statuses"
              options={this.props.filterStatuses}
              onChange={this.onChangeValues}
              value={this.state.selectedValues}
              className="basic-multi-select"
              classNamePrefix="Statuses"
              style={{ width: 200, marginBottom: '25px' }}
              placeholder="Status wählen"
            />
          </div>
        )}
        {this.state.selectedOption === 'purchase_price' && (
          <div>
            <input
              type="number"
              id="startPrice"
              placeholder="von"
              min="1"
              style={{ width: 150, display: 'flex', marginBottom: '25px' }}
              onChange={this.handleChange}
            />
            <input
              type="number"
              id="endPrice"
              placeholder="bis"
              min="1"
              style={{ width: 150, display: 'flex', marginBottom: '25px' }}
              onChange={this.handleChange}
            />
          </div>
        )}
        {this.state.selectedOption === 'reference' && (
          <div>
            <input
              type="text"
              id="reference"
              placeholder={retailerReferenceObj.referenceDescription}
              value={this.state.reference}
              onChange={this.handleChange}
              style={{
                width: 200,
                height: 35,
                display: 'flex',
                marginBottom: '25px',
              }}
            />
          </div>
        )}
        {this.state.selectedOption === 'lessee_name' && (
          <div>
            <input
              type="text"
              id="lesseeName"
              placeholder="Firmenname"
              value={this.state.lesseeName}
              onChange={this.handleChange}
              style={{
                width: 200,
                height: 35,
                display: 'flex',
                marginBottom: '25px',
              }}
            />
          </div>
        )}
        <b.Button bsStyle="success" onClick={this.filterOptions}>
          Filter
        </b.Button>
        <b.Button
          bsStyle="danger"
          style={{ marginLeft: '10px' }}
          onClick={this.clearFilter}
        >
          Filter löschen
        </b.Button>
      </div>
    );
  }
}

ApplicationsFilters.propTypes = {
  dispatch: T.func.isRequired,
  filterStatuses: T.arrayOf(String).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  retailerReferenceObj: u.getRetailerReferenceObj(state),
});
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicationsFilters);
