import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import Spinner from '../../Spinner';

const WizardButtons = ({
  isLastStep,
  isPending,
  noRates,
  onCancel,
  onPrevious,
  value,
  submitting,
  userRole,
}) => {
  let text = 'Weiter';
  if (isLastStep) text = 'Antrag senden';
  if (userRole === 'lessee') text = 'Verbindlichen Leasingantrag einreichen';
  return (
    <div>
      <b.Button bsSize="sm" onClick={onCancel}>
        Abbrechen
      </b.Button>
      <div style={{ float: 'right' }}>
        {onPrevious && (
          <b.Button bsSize="sm" onClick={onPrevious}>
            <FA name="angle-double-left icon-left" />
            Werte ändern
          </b.Button>
        )}
        {' '}
        <b.Button
          type="submit"
          bsStyle="primary"
          bsSize="sm"
          disabled={value || submitting || noRates}
        >
          {isPending && <Spinner />}
          {text}
          <FA name="angle-double-right icon-right" />
        </b.Button>
      </div>
      <p className="hint-required">
        Pflichtfeld
        <span className="asterisk-required">*</span>
      </p>
    </div>
  );
};

WizardButtons.propTypes = {
  isLastStep: T.bool,
  isPending: T.bool,
  noRates: T.bool,
  onCancel: T.func.isRequired,
  onPrevious: T.func,
  value: T.bool,
  submitting: T.bool.isRequired,
  userRole: T.string,
};

WizardButtons.defaultProps = {
  isLastStep: false,
  isPending: false,
  noRates: false,
  onPrevious: null,
  value: null,
  userRole: null,
};

export default WizardButtons;
