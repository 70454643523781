import { NAME } from './constants';

export const GET_INSURANCE_PENDING = `${NAME}/GET_INSURANCE_PENDING`;
export const GET_INSURANCE_FULFILLED = `${NAME}/GET_INSURANCE_FULFILLED`;
export const GET_INSURANCE_REJECTED = `${NAME}/GET_INSURANCE_REJECTED`;
export const LIST_INSURANCES_FULFILLED = `${NAME}/LIST_INSURANCES_FULFILLED`;
export const LIST_INSURANCES_PENDING = `${NAME}/LIST_INSURANCES_PENDING`;
export const LIST_INSURANCES_REJECTED = `${NAME}/LIST_INSURANCES_REJECTED`;
export const CREATE_INSURANCE_PENDING = `${NAME}/CREATE_INSURANCE_PENDING`;
export const CREATE_INSURANCE_FULFILLED = `${NAME}/CREATE_INSURANCE_FULFILLED`;
export const CREATE_INSURANCE_REJECTED = `${NAME}/CREATE_INSURANCE_REJECTED`;
export const UPDATE_INSURANCE_PENDING = `${NAME}/UPDATE_INSURANCE_PENDING`;
export const UPDATE_INSURANCE_FULFILLED = `${NAME}/UPDATE_INSURANCE_FULFILLED`;
export const UPDATE_INSURANCE_REJECTED = `${NAME}/UPDATE_INSURANCE_REJECTED`;
