import * as typ from './actionTypesUpdateForm';

export function changeMoneyField() {
  return (dispatch) => {
    dispatch({ type: typ.CHANGE_MONEY_FIELD });
  };
}

export function addColumn(colInput) {
  return (dispatch) => {
    dispatch({
      type: typ.ADD_COLUMN,
      payload: colInput,
    });
  };
}

export function addRow(row) {
  return (dispatch) => {
    dispatch({
      type: typ.ADD_ROW,
      payload: row,
    });
  };
}

export function deleteColumn(col) {
  return (dispatch) => {
    dispatch({
      type: typ.DELETE_COLUMN,
      payload: col,
    });
  };
}

export function deleteRow(row) {
  return (dispatch) => {
    dispatch({
      type: typ.DELETE_ROW,
      payload: row,
    });
  };
}

export function continueForCondition(condition) {
  return (dispatch) => {
    dispatch({
      type: typ.CONTINUE_FOR_CONDITION,
      payload: condition,
    });
  };
}

export function updateTable() {
  return (dispatch) => {
    dispatch({
      type: typ.UPDATE_TABLE,
    });
  };
}
