import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FA from 'react-fontawesome';
import * as m from '../../../leasingApplication/model';
import { formatMoney } from '../../formatters';
import {
  APPROVED_APPLICATION,
  APPROVED_APPLICATION_BY_FINANCIAL_DECISION,
  CANCELLED_DECISION,
} from '../../../leasingApplication/constants';

const labelAndValue = (label, value) => (
  <b.Row>
    <b.Col xs={3} sm={2} md={2}>
      {label}
      :
    </b.Col>
    <b.Col xs={9} sm={10} md={10}>
      {value}
    </b.Col>
  </b.Row>
);

const LeasingApplication = ({ app, retailerReferenceObj }) => (
  <b.Row>
    <b.Col xs={12}>
      <div className="leasing-application">
        <b.Row>
          <b.Col xs={12} sm={9}>
            <div className="label-value-wrapper">
              {labelAndValue(
                'Status',
                <b.Label bsStyle="info">{app.status_text}</b.Label>,
              )}
              {labelAndValue('Antragsnr', app.id)}
              {labelAndValue('Name', app.lessee_name)}
              {labelAndValue('Objekt', app.object_name)}
              {labelAndValue('Wert', formatMoney(app.purchase_price))}
              {labelAndValue('VerkäuferIn', app.salesman_name)}
              {
                retailerReferenceObj.isReferenceFieldRequired && (
                  labelAndValue(retailerReferenceObj.referenceDescription, app.reference)
                )
              }
            </div>
          </b.Col>
          <b.Col xs={12} sm={3}>
            <div
              className="label-value-btn-position"
              style={{ textAlign: 'right' }}
            >
              <LinkContainer to={`/applications/${app.id}`}>
                <b.Button bsStyle="default" style={{ marginBottom: '10px' }}>
                  <FA name="eye icon-left" />
                  Antrag ansehen
                </b.Button>
              </LinkContainer>
              {((app.status > APPROVED_APPLICATION
                && app.status < APPROVED_APPLICATION_BY_FINANCIAL_DECISION)
                || app.status === CANCELLED_DECISION) && (
                <LinkContainer to={`/change_application/edit/${app.id}`}>
                  <b.Button bsStyle="primary">
                    <FA name="plus-circle icon-left" />
                    Antrag ändern
                  </b.Button>
                </LinkContainer>
              )}
            </div>
          </b.Col>
        </b.Row>
      </div>
    </b.Col>
  </b.Row>
);

LeasingApplication.propTypes = {
  app: m.leasingApplicationSummaryShape.isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
};

export default LeasingApplication;
