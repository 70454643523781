import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import BigSpinner from '../../BigSpinner';
import * as sel from '../../../promotion/selectors';
import * as mod from '../../../promotion/model';

const ListPagePromotions = ({
  contractTypes,
  isPending,
  productGroups,
  promotions,
  promotionEditActiveId,
  onShowPromotionUpdateForm,
}) => {
  if (isPending) {
    return <BigSpinner />;
  }

  const lookupProductGroup = (id) => !_isEmpty(productGroups)
    && id
    && _find(productGroups, (v) => v.id === id)
    && _find(productGroups, (v) => v.id === id).description;

  const lookupContractType = (id) => !_isEmpty(contractTypes)
    && id
    && _find(contractTypes, (v) => v.id === id)
    && _find(contractTypes, (v) => v.id === id).description;

  const tableHeader = () => (
    <thead>
      <tr>
        <th>Angebotsname</th>
        <th>Laufzeit</th>
        <th>Vertragsart</th>
        <th>Produktgruppe</th>
        <th>Händler</th>
        <th>Händlergruppe</th>
      </tr>
    </thead>
  );

  const tableRow = (promotion) => {
    const {
      id,
      isDeactivated,
      promotionName,
      promotionPeriod,
      contractType,
      productGroup,
      retailers,
      retailerGroup,
    } = promotion;
    let classNamesLinks = (new Date(promotionPeriod.from) <= new Date()
      && new Date(promotionPeriod.to) >= new Date()) && !isDeactivated
      ? 'list-table-cell-link'
      : 'list-table-cell-link-inactive';
    if (id === promotionEditActiveId) classNamesLinks = `${classNamesLinks} list-table-active-element`;
    let classNamesCells = 'list-table-cell';
    if (id === promotionEditActiveId) classNamesCells = `${classNamesCells} list-table-active-element`;
    return (
      <tr key={id} className="list-table-row">
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowPromotionUpdateForm(id)}
          >
            <div>{promotionName}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowPromotionUpdateForm(id)}
          >
            <div>
              {`${moment(promotionPeriod.from).format('YYYY-MM-DD')} - 
            ${moment(promotionPeriod.to).format('YYYY-MM-DD')}`}
            </div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowPromotionUpdateForm(id)}
          >
            <div>{lookupContractType(contractType)}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowPromotionUpdateForm(id)}
          >
            <div>{lookupProductGroup(productGroup)}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowPromotionUpdateForm(id)}
          >
            <div>{retailers.join(', ')}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowPromotionUpdateForm(id)}
          >
            <div>{retailerGroup}</div>
          </a>
        </td>
      </tr>
    );
  };

  return (
    <div className="list-table">
      <Table responsive striped condensed>
        {tableHeader()}
        <tbody>{promotions.map((promotion) => tableRow(promotion))}</tbody>
      </Table>
    </div>
  );
};

ListPagePromotions.propTypes = {
  contractTypes: T.arrayOf(mod.contractTypeShape).isRequired,
  isPending: T.bool.isRequired,
  productGroups: T.arrayOf(mod.productGroupShape).isRequired,
  promotions: T.arrayOf(mod.promotionSummaryShape).isRequired,
  promotionEditActiveId: T.string.isRequired,
  onShowPromotionUpdateForm: T.func.isRequired,
};

ListPagePromotions.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  contractTypes: sel.getContractTypes(state),
  isPending: sel.getIsListPromotionsPending(state),
  productGroups: sel.getProductGroups(state),
  promotions: sel.listPromotions(state),
});

export default connect(
  mapStateToProps,
)(ListPagePromotions);
