import React from 'react';
import Helmet from 'react-helmet';
import FA from 'react-fontawesome';

const ContactPage = () => (
  <div>
    <Helmet title="Kontakt" />
    <h1>Kontakt</h1>
    <p>
      Haben Sie Anregungen oder Fragen, nutzen Sie den direkten Kontakt zu uns.
      Telefonisch erreichen Sie uns unter
    </p>
    <p style={{ fontWeight: 'bold' }}>
      <FA name="phone" />
      {' '}
      +49 (0) 40-808 100-656
    </p>
  </div>
);

export default ContactPage;
