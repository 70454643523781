import _find from 'lodash/find';

function isInteger(value) {
  return (typeof value === 'number' && isFinite(value) && Math.floor(value) === value);
}

export function contractTypeMapping(contractsOrg) {
  if (!contractsOrg) return 0;
  if (isInteger(contractsOrg)) return contractsOrg;
  return parseInt(contractsOrg.substring(0, contractsOrg.indexOf('---')), 10);
}

export function contractTypeMappingRevert(application, contractTypes) {
  if (application) {
    const contractNr = application.contract_type;
    if (application.sale_and_lease_back) {
      const contract = _find(contractTypes, (ct) => ct.id === contractNr && ct.description.includes('Sale-And-Lease-Back'));
      if (contract) return `${contract.id}---${contract.description}`;
      return '';
    }
    const contract = _find(contractTypes, (ct) => ct.id === contractNr && !ct.description.includes('Sale-And-Lease-Back'));
    if (contract) return `${contract.id}---${contract.description}`;
    return '';
  }
  return '';
}
