import _map from 'lodash/map';
import { NAME } from './constants';

export const getContractTypes = (state) => state[NAME].contractTypes || [];
export const getIsComputeRatesPending = (state) => !!state[NAME].isComputeRatesPending;
export const getIsCreateLeasingApplicationPending = (state) => !!state[NAME].isCreateLeasingApplicationPending;
export const getIsGetContractTypesPending = (state) => !!state[NAME].isGetContractTypesPending;
export const getIsGetLeasingApplicationPending = (state) => !!state[NAME].isGetLeasingApplicationPending;
export const getIsGetLegalFormsPending = (state) => !!state[NAME].isGetLegalFormsPending;
export const getIsGetProductGroupsPending = (state) => !!state[NAME].isGetProductGroupsPending;
export const getIsGetRetailerBranchesPending = (state) => !!state[NAME].isGetRetailerBranchesPending;
export const getIsGetRetailersPending = (state) => !!state[NAME].isGetRetailersPending;
export const getIsGetFilterStatusesPending = (state) => !!state[NAME].isGetFilterStatusesPending;
export const getIsListLeasingApplicationsPending = (state) => !!state[NAME].isListLeasingApplicationsPending;
export const getLeasingApplication = (id) => (state) => state[NAME].leasingApplicationsById[id];
export const getLegalForms = (state) => state[NAME].legalForms || [];
export const getMonthOfCancellation = (state) => state[NAME].monthOfCancellation || 0;
export const getProductGroups = (state) => state[NAME].productGroups || [];
export const getRates = (state) => state[NAME].rates || [];
export const getResidualValue = (state) => state[NAME].residualValue || 0;
export const getRetailerBranches = (state) => state[NAME].retailerBranches || [];
export const getRetailers = (state) => state[NAME].retailers || [];
export const getRetailer = (state) => state[NAME].retailer;
export const getFilterStatuses = (state) => state[NAME].statuses || [];
export const listLeasingApplications = (state) => _map(state[NAME].leasingApplications,
  (id) => state[NAME].leasingApplicationSummariesById[id]);
