import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import * as m from '../../../leasingApplication/model';
import ViewField from '../../ViewField';
import {
  formatBoolean, formatMoney, formatDate, formatDateTime,
} from '../../formatters';
import { apiUrl } from '../../../axios';
import imageWeb from '../../../assets/images/infographic-web.svg';
import imageTablet from '../../../assets/images/infographic-tablet.svg';
import imageMobile from '../../../assets/images/infographic-mobile.svg';

const DetailsPage = ({
  contractTypes,
  leasingApplication,
  productGroups,
  retailerReferenceObj,
  userRole,
}) => {
  const lookupContractType = (id) => {
    if (!_isEmpty(contractTypes)) {
      const contractType = _find(contractTypes, (v) => v.id === id);
      if (contractType) return contractType.description;
      return '';
    }
    return '';
  };
  const lookupProductGroup = (id) => {
    if (!_isEmpty(productGroups)) {
      const prodGr = _find(productGroups, (v) => v.id === id);
      if (prodGr) return prodGr.description;
      return '';
    }
    return '';
  };
  return (
    <div>
      <Helmet title="Antrag" />
      {userRole === 'lessee' && (
        <div>
          <img src={imageWeb} className="visible-md visible-lg" alt="infograpic" />
          <img src={imageTablet} className="visible-sm" alt="infograpic" />
          <img src={imageMobile} className="visible-xs" alt="infograpic" />
        </div>
      )}
      <div className="text-align-right">
        <b.Button
          href={`${apiUrl}/api/download_contract_pdf/${leasingApplication.id}`}
          target="_blank"
          disabled={!(leasingApplication.status_id >= 970 && leasingApplication.status_id <= 996)}
        >
          <FA name="print icon-left" />
          Vertrag drucken
        </b.Button>
      </div>
      <ViewField title="Antragsnr" value={leasingApplication.id} />
      <ViewField
        title="Kaufpreis"
        value={formatMoney(leasingApplication.purchase_price)}
      />
      <ViewField
        title="Kaufpreis genehmigt"
        value={formatMoney(leasingApplication.purchase_price_approved)}
      />
      <ViewField
        title="Mietsonderzahlung"
        value={formatMoney(leasingApplication.down_payment)}
      />
      <ViewField
        title="Leasingobjekt (Klasse)"
        value={lookupProductGroup(leasingApplication.product_group)}
      />
      <ViewField
        title="Leasingobjekt (Details)"
        value={leasingApplication.object_name}
      />
      <ViewField
        title="Vertragsart"
        value={lookupContractType(leasingApplication.contract_type)}
      />
      <ViewField
        title="Sale and lease back"
        value={formatBoolean(leasingApplication.sale_and_lease_back)}
      />
      <ViewField
        title="Laufzeit"
        value={`${leasingApplication.lease_term} Monate`}
      />
      <ViewField
        title="Rate"
        value={formatMoney(leasingApplication.rate)}
      />
      <ViewField
        title="Restwert"
        value={formatMoney(leasingApplication.residual_value)}
      />
      {retailerReferenceObj.isReferenceFieldRequired && (
      <ViewField
        title={retailerReferenceObj.referenceDescription}
        value={leasingApplication.reference}
      />
      )}
      <ViewField
        title="Abschlusszahlung bei Laufzeitverkürzung"
        value={formatMoney(leasingApplication.final_payment)}
      />

      <h2>Leasingnehmer</h2>
      <ViewField
        title="Firmenname"
        value={leasingApplication.lessee_name}
      />
      <ViewField
        title="Straße"
        value={leasingApplication.lessee_street}
      />
      <ViewField
        title="PLZ"
        value={leasingApplication.lessee_post_code}
      />
      <ViewField
        title="Ort"
        value={leasingApplication.lessee_city}
      />
      <ViewField
        title="Telefonnummer"
        value={leasingApplication.lessee_phone}
      />
      <ViewField
        title="E-Mail"
        value={leasingApplication.lessee_email}
      />
      <ViewField
        title="IBAN zur Abbuchung der Leasingraten"
        value={leasingApplication.lessee_iban}
      />

      <h2>Geschäftsführer / Inhaber</h2>
      <ViewField
        title="Name"
        value={leasingApplication.manager_name}
      />
      <ViewField
        title="Straße"
        value={leasingApplication.manager_street}
      />
      <ViewField
        title="PLZ"
        value={leasingApplication.manager_post_code}
      />
      <ViewField
        title="Ort"
        value={leasingApplication.manager_city}
      />
      <ViewField
        title="Geburtsdatum"
        value={formatDate(leasingApplication.manager_birth_date)}
      />
      <ViewField
        title="Bemerkungen (z.B. abweichender Objektstandort)"
        value={leasingApplication.manager_notes}
      />

      <h2>Lieferant</h2>

      <div>
        <ViewField
          title="Name"
          value={leasingApplication.retailer_name}
        />
        <ViewField
          title="Straße"
          value={leasingApplication.retailer_street}
        />
        <ViewField
          title="PLZ"
          value={leasingApplication.retailer_post_code}
        />
        <ViewField
          title="Ort"
          value={leasingApplication.retailer_city}
        />
        <ViewField
          title="Telefon"
          value={leasingApplication.retailer_phone}
        />
      </div>

      <h2>Status</h2>
      <ViewField
        title="Eingegangen"
        value={formatDateTime(leasingApplication.status_received_date)}
      />
      <ViewField
        title="Status"
        value={leasingApplication.status_text}
      />
      <ViewField
        title="Entscheidungsunterlagen"
        value={(
          <ul>
            {leasingApplication.missing_decision_documents.map((d) => <li key={d}>{d}</li>)}
          </ul>
        )}
      />
      <ViewField
        title="Vertragsunterlagen"
        value={(
          <ul>
            {leasingApplication.missing_contract_documents.map((d) => <li key={d}>{d}</li>)}
          </ul>
        )}
      />
    </div>
  );
};

DetailsPage.propTypes = {
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  leasingApplication: m.leasingApplicationShape.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  userRole: T.string,
};

DetailsPage.defaultProps = {
  userRole: false,
};

export default DetailsPage;
