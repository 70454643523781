import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import CreateWizard from './CreateWizard';

const CreatePage = ({
  id,
  isUpdatePending,
  onCancelUpdateInsurance,
  onUpdateInsurance,
}) => (
  <div>
    <Helmet title="Insurance update" />
    <CreateWizard
      isUpdatePending={isUpdatePending}
      onCancel={onCancelUpdateInsurance}
      onSubmit={onUpdateInsurance}
      id={id}
    />
  </div>
);

CreatePage.propTypes = {
  id: T.string.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancelUpdateInsurance: T.func.isRequired,
  onUpdateInsurance: T.func.isRequired,
};

export default CreatePage;
