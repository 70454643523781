import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import { formatMoney } from '../../formatters';
import validationState from '../../validationState';
import * as m from '../../../leasingApplication/model';

const colGroup = (showFinalPayment, showResidualValue) => {
  if (showFinalPayment && showResidualValue) {
    return (
      <colgroup>
        <col width="30" />
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
      </colgroup>
    );
  }
  if (showFinalPayment || showResidualValue) {
    return (
      <colgroup>
        <col width="30" />
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
      </colgroup>
    );
  }
  return (
    <colgroup>
      <col width="30" />
      <col width="50%" />
      <col width="50%" />
    </colgroup>
  );
};

const tableHeader = (showFinalPayment, showResidualValue, monthOfCancellation) => (
  <thead>
    <tr>
      <td>&nbsp;</td>
      <td>gewünschte Vertragslaufzeit</td>
      <td>monatliche Rate</td>
      {showFinalPayment
        && (
        <td>
          Abschlusszahlung bei Laufzeitverkürzung auf
          {monthOfCancellation}
          {' '}
          Monate
        </td>
        )}
      {showResidualValue
        && <td>Restwert</td>}
    </tr>
  </thead>
);

const tableRow = (showFinalPayment, showResidualValue, residualValue, rate, input) => {
  const leaseTermAndRate = `${rate.lease_term}|${rate.rate}|${rate.promotion_id}`;
  return (
    <tr key={leaseTermAndRate}>
      <td>
        <b.Radio
          {...input}
          checked={input.value === leaseTermAndRate}
          value={leaseTermAndRate}
        />
      </td>
      <td>
        {rate.lease_term}
        {' '}
        Monate
      </td>
      <td className="money">{formatMoney(rate.rate)}</td>
      {showFinalPayment
        && <td className="money">{rate.final_payment ? formatMoney(rate.final_payment) : ''}</td>}
      {showResidualValue
        && <td className="money">{formatMoney(residualValue)}</td>}
    </tr>
  );
};

const LeaseTermsAndRatesField = ({
  input,
  meta,
  monthOfCancellation,
  rates,
  required,
  residualValue,
  showFinalPayment,
  showResidualValue,
  title,
}) => {
  if (rates.length !== 0) {
    return (
      <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
        <b.ControlLabel>
          {title}
          {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
        </b.ControlLabel>
        <b.Table striped bordered hover responsive>
          {colGroup(showFinalPayment, showResidualValue)}
          {tableHeader(showFinalPayment, showResidualValue, monthOfCancellation)}
          <tbody>
            {rates.map((rate) => tableRow(showFinalPayment, showResidualValue, residualValue, rate, input))}
          </tbody>
        </b.Table>
        <p>
          Angegebene Werte enthalten noch keine Auf&shy;schlag&shy;sätze des jeweiligen
          Groß&shy;handels&shy;zentrums. Bitte beachten Sie, dass sich Leasing-Raten
          dem&shy;ent&shy;spre&shy;chend noch ändern können.
        </p>
        {meta.touched && meta.error
          && <span className="help-block">{meta.error}</span>}
      </b.FormGroup>
    );
  }
  return (
    <div className="alert alert-info">
      Für die eingegebenen Werte sind keine Leasing-Raten möglich. Bitte ändern Sie Ihre Eingabe
    </div>
  );
};

LeaseTermsAndRatesField.defaultProps = {
  custom: {},
};

LeaseTermsAndRatesField.propTypes = {
  ...fieldPropTypes,
  monthOfCancellation: T.number.isRequired,
  rates: T.arrayOf(m.rateShape).isRequired,
  residualValue: T.number.isRequired,
  showFinalPayment: T.bool.isRequired,
  showResidualValue: T.bool.isRequired,
};

export default LeaseTermsAndRatesField;
