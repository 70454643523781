import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import albisSalesContact from '../../albisSalesContact';
import * as u from '../../user/selectors';
import edekaBankImageWeb from '../../assets/images/Banner_Leasinportal.jpg';
import edekaBankImageMobile from '../../assets/images/Banner_Leasinportal_Mobile.jpg';

const DashboardPage = ({ userRole }) => (
  <div>
    <div className="content">
      <Helmet title="LGH Portal" />
      <b.Row>
        <b.Col sm={10}>
          <b.Col sm={4} style={{ paddingLeft: '0px', paddingRight: '0px', marginRight: '10px' }}>
            <LinkContainer to="/applications/create">
              <b.Button bsStyle="primary" style={{ display: 'block' }}>
                <FA name="plus-circle icon-left" />
                {userRole === 'guest' ? 'Ratenrechner' : 'Neuen Antrag stellen'}
              </b.Button>
            </LinkContainer>
          </b.Col>
        &nbsp;
          <b.Col sm={4} style={{ paddingLeft: '0px', paddingRight: '0px', marginRight: '10px' }}>
            <LinkContainer to="/offers/create">
              <b.Button bsStyle="primary" style={{ display: 'block' }}>
                <FA name="plus-circle icon-left" />
                Neues Angebot erstellen
              </b.Button>
            </LinkContainer>
          </b.Col>
        </b.Col>
      </b.Row>
      <b.Row>
        <br />
      </b.Row>
      <b.Row>
        <b.Col sm={12}>
          <h1>Ansprechpartner Vertriebsinnendienst:</h1>
          {albisSalesContact.map((c) => (
            <b.Col sm={6} md={4} style={{ paddingLeft: '0px' }} key={c.email}>
              <div className="thumbnail">
                <p>
                  <FA name="phone" />
                  {c.phone}
                  <br />
                  <FA name="envelope-o" />
                  <a
                    href={`mailto:${c.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {c.email}
                  </a>
                </p>
              </div>
            </b.Col>
          ))}
        </b.Col>
      </b.Row>
    </div>
    <div className="banner" style={{ textAlign: 'center' }}>
      <a href="https://www.edekabank.de/privatkunden/nachhaltigkeit.html" target="_blank" rel="noopener noreferrer"><img src={edekaBankImageWeb} style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }} alt="wwf banner" className="visible-md visible-lg" /></a>
      <a href="https://www.edekabank.de/privatkunden/nachhaltigkeit.html" target="_blank" rel="noopener noreferrer"><img src={edekaBankImageWeb} style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }} alt="wwf banner" className="visible-sm" /></a>
      <a href="https://www.edekabank.de/privatkunden/nachhaltigkeit.html" target="_blank" rel="noopener noreferrer"><img src={edekaBankImageMobile} style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }} alt="wwf banner" className="visible-xs" /></a>
    </div>
  </div>

);

DashboardPage.propTypes = {
  userRole: T.string,
};

DashboardPage.defaultProps = {
  userRole: false,
};

const mapStateToProps = (state) => ({
  userRole: u.getUserRole(state),
});

export default connect(mapStateToProps)(DashboardPage);
