import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import {
  Tabs, Tab, Button, Row, Col,
} from 'react-bootstrap';
import FA from 'react-fontawesome';
import ListPagePromotions from '../promotion/list/ListPagePromotions';
import ListPageInsurances from '../insurance/list/ListPageInsurances';

class PMDashboardPage extends Component {
  state = {
    tab: 'Sonderangebote',
  };

  handleSelect = (tab) => {
    this.setState({
      tab,
    });
  };

  render() {
    const { tab } = this.state;
    const {
      insuranceEditActiveId,
      promotionEditActiveId,
      showPromotionsAndInsurancesLists,
      onShowInsuranceCreateForm,
      onShowPromotionCreateForm,
      onShowInsuranceUpdateForm,
      onShowPromotionUpdateForm,
    } = this.props;
    return (
      <div>
        <Helmet title="Sonderangebote" />
        <Row className="pb-2">
          <Col sm={10}>
            <Col sm={4} style={{ paddingLeft: '0px', paddingRight: '0px', marginRight: '10px' }}>
              <Button style={{ width: '100%' }} bsStyle="primary" onClick={onShowPromotionCreateForm}>
                <FA name="plus-circle icon-left" />
                Neues Sonderangebot
              </Button>
            </Col>
            &nbsp;
            <Col sm={4} style={{ paddingLeft: '0px', paddingRight: '0px', marginRight: '10px' }}>
              <Button style={{ width: '100%' }} bsStyle="primary" onClick={onShowInsuranceCreateForm}>
                <FA name="plus-circle icon-left" />
                Neue Versicherung
              </Button>
            </Col>
          </Col>
        </Row>
        {showPromotionsAndInsurancesLists && (
        <Tabs
          activeKey={tab}
          onSelect={this.handleSelect}
          id="tabs-promotions-insurances-list"
          className="pt-4"
        >
          <Tab eventKey="Sonderangebote" title="Sonderangebote">
            <ListPagePromotions
              onShowPromotionUpdateForm={onShowPromotionUpdateForm}
              promotionEditActiveId={promotionEditActiveId}
            />
          </Tab>
          <Tab eventKey="Versicherung" title="Versicherung">
            <ListPageInsurances
              onShowInsuranceUpdateForm={onShowInsuranceUpdateForm}
              insuranceEditActiveId={insuranceEditActiveId}
            />
          </Tab>
        </Tabs>
        )}
      </div>
    );
  }
}

PMDashboardPage.defaultProps = {
  insuranceEditActiveId: '',
  promotionEditActiveId: '',
};

PMDashboardPage.propTypes = {
  insuranceEditActiveId: T.string,
  promotionEditActiveId: T.string,
  showPromotionsAndInsurancesLists: T.bool.isRequired,
  onShowInsuranceCreateForm: T.func.isRequired,
  onShowPromotionCreateForm: T.func.isRequired,
  onShowInsuranceUpdateForm: T.func.isRequired,
  onShowPromotionUpdateForm: T.func.isRequired,
};

export default connect()(PMDashboardPage);
