import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _isEmpty from 'lodash/isEmpty';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _sortBy from 'lodash/sortBy';
import _sortedUniqBy from 'lodash/sortedUniqBy';
import InputField from '../../InputField';
import SalutationRadioField from '../../SalutationRadioField';
import SelectField from '../../SelectField';
import TextAreaField from '../../TextAreaField';
import YesNoRadioField from '../../YesNoRadioField';
import formName from './formName';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import validate from './validate';
import {
  normalizeIBAN,
  normalizeDate,
  normalizePostCode,
} from '../../normalizers';
import * as m from '../../../convertOffer/model';
import * as offerModel from '../../../offer/model';
import * as o from '../../../salesman/model';
import { sortSalesmen } from './sortSalesmen';

const addressSelectOptions = (leasingApplications, offers) => {
  const applicationLabel = (app) => _join(
    _compact([
      app.lessee_name,
      app.lessee_street,
      app.lessee_post_code,
      app.lessee_city,
      '(aus Antrag)',
    ]),
    ', ',
  );

  const offerLabel = (app) => _join(
    _compact([
      app.lessee_name,
      app.lessee_street,
      app.lessee_post_code,
      app.lessee_city,
      '(aus Angebot)',
    ]),
    ', ',
  );

  const leasingApplicationsOptions = _map(leasingApplications, (app) => ({
    value: app.id,
    label: applicationLabel(app),
    kind: 'application',
  }));
  const offersOptions = _map(offers, (app) => ({
    value: app.id,
    label: offerLabel(app),
    kind: 'offer',
  }));

  const leasingApplicationsSortedOptions = _sortBy(
    leasingApplicationsOptions,
    (option) => option.label.toLowerCase(),
  );
  const leasingApplicationsUniqueOptions = _sortedUniqBy(
    leasingApplicationsSortedOptions,
    (option) => option.label.toLowerCase(),
  );

  const offersSortedOptions = _sortBy(offersOptions, (option) => option.label.toLowerCase());
  const offersUniqueOptions = _sortedUniqBy(offersSortedOptions, (option) => option.label.toLowerCase());

  const connectedValues = leasingApplicationsUniqueOptions.concat(
    offersUniqueOptions,
  );
  const connectedValuesSortedOptions = _sortBy(connectedValues, (option) => option.label.toLowerCase());
  const connectedValuesUniqueOptions = _sortedUniqBy(
    connectedValuesSortedOptions,
    (option) => option.label.toLowerCase(),
  );

  return connectedValuesUniqueOptions;
};

const salesmanSelectOptions = (listSalesmen) => {
  const nonEmpty = _reject(listSalesmen, (s) => _isEmpty(_compact([s.last_name, s.first_name])));
  const sorted = sortSalesmen(nonEmpty);
  const label = (salesman) => _join(_compact([salesman.last_name, salesman.first_name]), ', ');
  const options = _map(sorted, (r) => [r.id, label(r)]);
  return _concat([['', '']], options);
};

const checkStepsClickHandlers = (
  objectData,
  onStepObjectData,
  onStepRetailer,
  onStepRate,
) => {
  if (objectData.sale_and_lease_back) {
    return [onStepObjectData, null, onStepRate];
  }
  return [onStepObjectData, onStepRetailer, onStepRate];
};

const CreateWizardStepAddressesForm = ({
  error,
  handleSubmit,
  isAdmin,
  isGetLegalFormsPending,
  isPending,
  leasingApplications,
  legalForms,
  listSalesmen,
  objectData,
  onPrevious,
  onCancel,
  offers,
  onSelectAddress,
  onStepObjectData,
  onStepRate,
  onStepRetailer,
  pristine,
  submitting,
  lesseeData,
  userRole,
}) => {
  if (userRole === 'lessee') {
    return (
      <b.Row>
        <b.Col sm={12} lg={3}>
          <WizardNavigation
            current={4}
            onClickHandlers={checkStepsClickHandlers(objectData,
              onStepObjectData, onStepRetailer, onStepRate)}
          />
        </b.Col>
        <b.Col sm={12} lg={9}>
          <b.Form onSubmit={handleSubmit}>
            {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
            <fieldset>
              <legend>Leasingnehmer</legend>
              <div style={{ marginBottom: '15px' }}>
                <b.Row>
                  <b.Col sm={6} lg={3}>
                    <b>Firmenname:</b>
                  </b.Col>
                  <b.Col sm={6} lg={3}>
                    <p>{lesseeData.lessee.ln_name}</p>
                  </b.Col>
                </b.Row>
                <b.Row>
                  <b.Col sm={6} lg={3}>
                    <b>Rechtsform:</b>
                  </b.Col>
                  <b.Col sm={6} lg={3}>
                    <p>{lesseeData.lessee.ln_rechtsform}</p>
                  </b.Col>
                </b.Row>
                <b.Row>
                  <b.Col sm={6} lg={3}>
                    <b>Straße:</b>
                  </b.Col>
                  <b.Col sm={6} lg={3}>
                    <p>{lesseeData.lessee.ln_strasse}</p>
                  </b.Col>
                </b.Row>
                <b.Row>
                  <b.Col sm={6} lg={3}>
                    <b>PLZ / Ort:</b>
                  </b.Col>
                  <b.Col sm={6} lg={3}>
                    <p>
                      {lesseeData.lessee.ln_plz}
                      {' '}
                      /
                      {lesseeData.lessee.ln_ort}
                    </p>
                  </b.Col>
                </b.Row>
                <b.Row>
                  <b.Col sm={6} lg={3}>
                    <b>Telefonnummer:</b>
                  </b.Col>
                  <b.Col sm={6} lg={3}>
                    <p>{lesseeData.lessee.ln_telefon}</p>
                  </b.Col>
                </b.Row>
              </div>
              <Field
                name="lessee_iban"
                title="IBAN zur Abbuchung der Leasingraten"
                required
                component={InputField}
                placeholder="DE..."
                type="text"
                normalize={normalizeIBAN}
              />
              {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                <p>
                  <i>
                    valid IBANs for testing: DE02120300000000202051,
                    DE02500105170137075030, DE02100500000054540402
                  </i>
                </p>
              )}
              <Field
                name="salesman"
                title="Ihr EDEKABANK-Berater"
                required
                component={SelectField}
                values={salesmanSelectOptions(listSalesmen)}
              />
              <Field
                name="manager_notes"
                title="Bemerkungen (z.B. abweichender Objektstandort)"
                component={TextAreaField}
                type="text"
              />
            </fieldset>
            <fieldset>
              <legend>Einwilligungserklärung</legend>
              <Field
                name="accept_terms"
                title={
                  !isAdmin ? (
                    <span>
                      Die
                      {' '}
                      <a href="/privacy">Datenschutzbestimmungen</a>
                      {' '}
                      habe
                      ich zur Kenntnis genommen und bin einverstanden, dass
                      meine Daten an die ALBIS Leasing Gruppe weitergegeben und
                      gemäß der
                      {' '}
                      <a href="/privacy">
                        Datenschutzerklärung der ALBIS Leasing Gruppe
                      </a>
                      {' '}
                      dort verarbeitet werden
                    </span>
                  ) : (
                    <span>
                      Hiermit bestätige ich, dass mir die
                      <a
                        href="/Einwilligung_Nutzungsbedingungen.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Einwilligung
                        {' '}
                      </a>
                      zur Weitergabe der Daten vorliegt.
                    </span>
                  )
                }
                required
                component={YesNoRadioField}
              />
            </fieldset>
            <WizardButtons
              isLastStep
              isPending={isPending}
              onCancel={onCancel}
              onPrevious={onPrevious}
              pristine={pristine}
              submitting={submitting}
              userRole={userRole}
            />
          </b.Form>
        </b.Col>
      </b.Row>
    );
  }
  return (
    <b.Row>
      <b.Col sm={12} lg={3}>
        <WizardNavigation
          current={4}
          onClickHandlers={checkStepsClickHandlers(objectData,
            onStepObjectData, onStepRetailer, onStepRate)}
        />
      </b.Col>
      <b.Col sm={12} lg={9}>
        <b.Form onSubmit={handleSubmit}>
          {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
          <fieldset>
            <legend>Leasingnehmersuche</legend>
            <Select
              className="addresses"
              name="_addresses"
              onChange={onSelectAddress}
              options={addressSelectOptions(leasingApplications, offers)}
              placeholder="Leasingnehmer nach Name, PLZ oder Ort suchen…"
            />
          </fieldset>
          <fieldset>
            <legend>Leasingnehmer</legend>
            <Field
              name="lessee_name"
              title="Firmenname"
              required
              component={InputField}
              type="text"
            />
            <Field
              name="lessee_legal_form"
              title={`Rechtsform ${isGetLegalFormsPending ? '…' : ''}`}
              required
              component={SelectField}
              values={_concat(
                [['', '']],
                _map(legalForms, (l) => [l.id, l.description]),
              )}
            />
            <Field
              name="lessee_street"
              title="Straße"
              required
              component={InputField}
              type="text"
            />
            <b.Row>
              <b.Col md={3}>
                <Field
                  name="lessee_post_code"
                  title="PLZ"
                  required
                  component={InputField}
                  type="text"
                  normalize={normalizePostCode}
                />
              </b.Col>
              <b.Col md={9}>
                <Field
                  name="lessee_city"
                  title="Ort"
                  required
                  component={InputField}
                  type="text"
                />
              </b.Col>
            </b.Row>
            <Field
              name="lessee_phone"
              title="Telefonnummer"
              required
              component={InputField}
              type="text"
            />
            <Field
              name="lessee_email"
              title="E-Mail"
              required
              component={InputField}
              type="text"
            />
            <Field
              name="lessee_iban"
              title="IBAN zur Abbuchung der Leasingraten"
              required
              component={InputField}
              placeholder="DE..."
              type="text"
              normalize={normalizeIBAN}
            />
            {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
              <p>
                <i>
                  valid IBANs for testing: DE02120300000000202051,
                  DE02500105170137075030, DE02100500000054540402
                </i>
              </p>
            )}
          </fieldset>
          <fieldset>
            <legend>Geschäftsführer / Inhaber</legend>
            <Field
              name="manager_salutation"
              title="Anrede"
              required
              component={SalutationRadioField}
            />
            <Field
              name="manager_first_name"
              title="Vorname"
              required
              component={InputField}
              type="text"
            />
            <Field
              name="manager_last_name"
              title="Nachname"
              required
              component={InputField}
              type="text"
            />
            <Field
              name="manager_street"
              title="Straße"
              required
              component={InputField}
              type="text"
            />
            <b.Row>
              <b.Col md={3}>
                <Field
                  name="manager_post_code"
                  title="PLZ"
                  required
                  component={InputField}
                  type="text"
                  normalize={normalizePostCode}
                />
              </b.Col>
              <b.Col md={9}>
                <Field
                  name="manager_city"
                  title="Ort"
                  required
                  component={InputField}
                  type="text"
                />
              </b.Col>
            </b.Row>
            <Field
              name="manager_birth_date"
              title="Geburtsdatum"
              required
              placeholder="TT.MM.JJJJ"
              component={InputField}
              type="text"
              normalize={normalizeDate}
            />

            {isAdmin && (
              <Field
                name="salesman"
                title="EDEKABANK-Berater"
                required
                component={SelectField}
                values={salesmanSelectOptions(listSalesmen)}
              />
            )}

            <Field
              name="manager_notes"
              title="Bemerkungen (z.B. abweichender Objektstandort)"
              component={TextAreaField}
              type="text"
            />
          </fieldset>
          <fieldset>
            <legend>Einwilligungserklärung</legend>
            <Field
              name="accept_terms"
              title={
                !isAdmin ? (
                  <span>
                    Die
                    {' '}
                    <a href="/privacy">Datenschutzbestimmungen</a>
                    {' '}
                    habe ich
                    zur Kenntnis genommen und bin einverstanden, dass meine
                    Daten an die ALBIS Leasing Gruppe weitergegeben und gemäß
                    der
                    {' '}
                    <a href="/privacy">
                      Datenschutzerklärung der ALBIS Leasing Gruppe
                    </a>
                    {' '}
                    dort verarbeitet werden
                  </span>
                ) : (
                  <span>
                    Hiermit bestätige ich, dass mir die
                    <a
                      href="/Einwilligung_Nutzungsbedingungen.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Einwilligung
                      {' '}
                    </a>
                    zur Weitergabe der Daten vorliegt.
                  </span>
                )
              }
              required
              component={YesNoRadioField}
            />
          </fieldset>
          <WizardButtons
            isLastStep
            isPending={isPending}
            onCancel={onCancel}
            onPrevious={onPrevious}
            pristine={pristine}
            submitting={submitting}
          />
        </b.Form>
      </b.Col>
    </b.Row>
  );
};

CreateWizardStepAddressesForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  isAdmin: T.bool.isRequired,
  isGetLegalFormsPending: T.bool.isRequired,
  isPending: T.bool.isRequired,
  leasingApplications: T.arrayOf(m.leasingApplicationSummaryShape).isRequired,
  offers: T.arrayOf(offerModel.offerSummaryShape).isRequired,
  legalForms: T.arrayOf(m.legalFormShape).isRequired,
  listSalesmen: T.arrayOf(o.salesmanShape).isRequired,
  objectData: T.shape({
    sale_and_lease_back: T.bool,
  }).isRequired,
  onPrevious: T.func.isRequired,
  onCancel: T.func.isRequired,
  onSelectAddress: T.func.isRequired,
  onStepObjectData: T.func.isRequired,
  onStepRate: T.func.isRequired,
  onStepRetailer: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
  lesseeData: T.any,
  userRole: T.string.isRequired,
};

CreateWizardStepAddressesForm.defaultProps = {
  error: null,
  lesseeData: {},
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepAddressesForm);
