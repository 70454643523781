import { SubmissionError } from 'redux-form';

const isValidationError = (err) => (
  err.response
  && err.response.status === 422
  && err.response.data
  && err.response.data.errors
);

const handleValidationErrors = (err, msg) => {
  if (isValidationError(err)) {
    throw new SubmissionError({
      _error: msg,
      ...err.response.data.errors,
    });
  }
};

export default handleValidationErrors;
