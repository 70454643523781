import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import * as m from '../../../salesman/model';
import EditForm from './EditForm';
import View from './View';
import initialValues from '../create/initialValues';

const DetailsPage = ({
  isEditing,
  isUpdatePending,
  onCancelEdit,
  onEdit,
  onUpdate,
  salesman,
}) => (
  <div>
    <Helmet title="Verkäufer" />
    {isEditing
      ? (
        <EditForm
          isPending={isUpdatePending}
          onCancel={onCancelEdit}
          onSubmit={onUpdate}
          initialValues={initialValues(salesman)}
        />
      )
      : (
        <View
          onEdit={onEdit}
          salesman={salesman}
        />
      )}
  </div>
);

DetailsPage.propTypes = {
  isEditing: T.bool.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancelEdit: T.func.isRequired,
  onEdit: T.func.isRequired,
  onUpdate: T.func.isRequired,
  salesman: m.salesmanShape.isRequired,
};

export default DetailsPage;
