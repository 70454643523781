import React from 'react';
import * as r from 'react-router';
import CreatePageContainer from '../components/offer/create/CreatePageContainer';
import OfferSummaryView from '../components/offer/create/OfferSummaryView';
import ListPageContainer from '../components/offer/list/ListPageContainer';

const offerRoutes = (
  <r.Route path="offers">
    <r.IndexRoute components={ListPageContainer} />
    <r.Route path="create" components={CreatePageContainer} />
    <r.Route path=":id" components={OfferSummaryView} />
  </r.Route>
);

export default offerRoutes;
