import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import ListPage from './ListPage';
import BigSpinner from '../../BigSpinner';
import * as a from '../../../salesman/actions';
import * as s from '../../../salesman/selectors';
import * as m from '../../../salesman/model';
import * as su from '../../../user/selectors';

class ListPageContainer extends Component {
  componentWillMount() {
    this.props.dispatch(a.listSalesmen());
  }

  render() {
    const { isPending, salesmen, isAdmin } = this.props;
    if (isPending) {
      return <BigSpinner />;
    }
    if (!isAdmin) {
      return null;
    }
    return <ListPage salesmen={salesmen} />;
  }
}

ListPageContainer.propTypes = {
  dispatch: T.func.isRequired,
  isPending: T.bool.isRequired,
  salesmen: T.arrayOf(m.salesmanShape).isRequired,
  isAdmin: T.bool.isRequired,
};

ListPageContainer.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  isPending: s.getIsListSalesmenPending(state),
  salesmen: s.listSalesmen(state),
  isAdmin: su.getIsAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPageContainer);
