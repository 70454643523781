import _filter from 'lodash/filter';

export const initialState = {
  items: [],
};

export default function reducer(state = initialState, action) {
  if (action.type) {
    if (action.type.endsWith('_FULFILLED')) {
      return {
        ...state,
        items: _filter(state.items, (i) => i.id !== 'server_error'),
      };
    } if (action.type.endsWith('_REJECTED')) {
      if (action.payload.response && action.payload.response.status >= 500) {
        return {
          ...state,
          items: [
            ..._filter(state.items, (i) => i.id !== 'server_error'),
            {
              id: 'server_error',
              message: 'Ooops. The server is unreachable.',
            },
          ],
        };
      }
    }
  }
  return state;
}
