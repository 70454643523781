import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _isEmpty from 'lodash/isEmpty';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _sortBy from 'lodash/sortBy';
import _sortedUniqBy from 'lodash/sortedUniqBy';
import InputField from '../../InputField';
import SalutationRadioField from '../../SalutationRadioField';
import SelectField from '../../SelectField';
import formName from './formName';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import validate from './validate';
import { normalizePostCode } from '../../normalizers';
import * as m from '../../../offer/model';
import * as leasingApplicationModel from '../../../leasingApplication/model';
import * as o from '../../../salesman/model';
import { sortSalesmen } from './sortSalesmen';
import AcceptTermsField from '../../../common/components/AcceptTermsField';

const addressSelectOptions = (offers, leasingApplications) => {
  const offerLabel = (app) => _join(
    _compact([
      app.lessee_name,
      app.lessee_street,
      app.lessee_post_code,
      app.lessee_city,
      '(aus Angebot)',
    ]),
    ', ',
  );

  const applicationLabel = (app) => _join(
    _compact([
      app.lessee_name,
      app.lessee_street,
      app.lessee_post_code,
      app.lessee_city,
      '(aus Antrag)',
    ]),
    ', ',
  );

  const offersOptions = _map(offers, (app) => ({
    value: app.id,
    label: offerLabel(app),
    kind: 'offer',
  }));
  const leasingApplicationsOptions = _map(leasingApplications, (app) => ({
    value: app.id,
    label: applicationLabel(app),
    kind: 'application',
  }));

  const offersSortedOptions = _sortBy(offersOptions, (option) => option.label.toLowerCase());
  const offersUniqueOptions = _sortedUniqBy(offersSortedOptions, (option) => option.label.toLowerCase());

  const leasingApplicationsSortedOptions = _sortBy(
    leasingApplicationsOptions,
    (option) => option.label.toLowerCase(),
  );
  const leasingApplicationsUniqueOptions = _sortedUniqBy(
    leasingApplicationsSortedOptions,
    (option) => option.label.toLowerCase(),
  );

  const connectedValues = offersUniqueOptions.concat(
    leasingApplicationsUniqueOptions,
  );
  const connectedValuesSortedOptions = _sortBy(connectedValues, (option) => option.label.toLowerCase());
  const connectedValuesUniqueOptions = _sortedUniqBy(
    connectedValuesSortedOptions,
    (option) => option.label.toLowerCase(),
  );

  return connectedValuesUniqueOptions;
};

const salesmanSelectOptions = (listSalesmen) => {
  const nonEmpty = _reject(listSalesmen, (s) => _isEmpty(_compact([s.last_name, s.first_name])));
  const sorted = sortSalesmen(nonEmpty);
  const label = (salesman) => _join(_compact([salesman.last_name, salesman.first_name]), ', ');
  const options = _map(sorted, (r) => [r.id, label(r)]);
  return _concat([['', '']], options);
};

const CreateWizardStepAddressesForm = ({
  error,
  handleSubmit,
  userRole,
  isAdmin,
  isGuest,
  isPending,
  offers,
  listSalesmen,
  leasingApplications,
  onCancel,
  onSelectAddress,
  onStepObjectData,
  onStepRate,
  pristine,
  submitting,
}) => (
  <b.Row>
    <b.Col sm={12} lg={3}>
      <WizardNavigation
        current={4}
        onClickHandlers={[onStepObjectData, onStepRate]}
      />
    </b.Col>
    <b.Col sm={12} lg={9}>
      <b.Form onSubmit={handleSubmit}>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        {!isGuest && (
          <fieldset>
            <legend>Leasingnehmersuche</legend>
            <Select
              className="addresses"
              name="_addresses"
              onChange={onSelectAddress}
              options={addressSelectOptions(offers, leasingApplications)}
              placeholder="Leasingnehmer nach Name, PLZ oder Ort suchen…"
            />
          </fieldset>
        )}
        <fieldset>
          <legend>Leasingnehmer</legend>
          <Field
            name="lessee_name"
            title="Firmenname"
            required
            component={InputField}
            type="text"
          />
          <Field
            name="lessee_street"
            title="Straße"
            required
            component={InputField}
            type="text"
          />
          <b.Row>
            <b.Col md={3}>
              <Field
                name="lessee_post_code"
                title="PLZ"
                required
                component={InputField}
                type="text"
                normalize={normalizePostCode}
              />
            </b.Col>
            <b.Col md={9}>
              <Field
                name="lessee_city"
                title="Ort"
                required
                component={InputField}
                type="text"
              />
            </b.Col>
          </b.Row>
        </fieldset>
        <fieldset>
          <legend>Ansprechpartner</legend>
          <Field
            name="manager_salutation"
            title="Anrede"
            required
            component={SalutationRadioField}
          />
          <Field
            name="manager_first_name"
            title="Vorname"
            required
            component={InputField}
            type="text"
          />
          <Field
            name="manager_last_name"
            title="Nachname"
            required
            component={InputField}
            type="text"
          />

          {isAdmin && (
            <Field
              name="salesman"
              title="EDEKABANK-Berater"
              required
              component={SelectField}
              values={salesmanSelectOptions(listSalesmen)}
            />
          )}
        </fieldset>
        <fieldset>
          <legend>Einwilligungserklärung</legend>
          <AcceptTermsField userRole={userRole} />
        </fieldset>
        <WizardButtons
          isLastStep
          isPending={isPending}
          onCancel={onCancel}
          onPrevious={onStepRate}
          pristine={pristine}
          submitting={submitting}
        />
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepAddressesForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  isAdmin: T.bool.isRequired,
  userRole: T.string.isRequired,
  isPending: T.bool.isRequired,
  isGuest: T.bool.isRequired,
  offers: T.arrayOf(m.offerSummaryShape).isRequired,
  listSalesmen: T.arrayOf(o.salesmanShape).isRequired,
  leasingApplications: T.arrayOf(
    leasingApplicationModel.leasingApplicationSummaryShape,
  ).isRequired,
  onCancel: T.func.isRequired,
  onSelectAddress: T.func.isRequired,
  onStepObjectData: T.func.isRequired,
  onStepRate: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepAddressesForm.defaultProps = {
  error: null,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepAddressesForm);
