import iban from 'iban';

export const normalizeDate = (value) => {
  if (!value) {
    return value;
  }
  const onlyAllowedCharts = value
    .replace(/[^\d.]/g, '')
    .replace(/[.]+/g, '.')
    .replace(/^(\d{1,2})\.(\d{1,2})\.(\d{1,4}).*$/, '$1.$2.$3');
  const parts = onlyAllowedCharts.split('.');
  if (parts[0] && parts[0].length > 2) {
    parts[0] = parts[0].slice(0, 2);
  }
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].slice(0, 2);
  }
  if (parts[2] && parts[2].length > 4) {
    parts[2] = parts[2].slice(0, 4);
  }
  return parts.join('.');
};

export const normalizeIBAN = (value) => {
  if (!value) {
    return value;
  }
  return iban.printFormat(value, ' ');
};

export const normalizeMoney = (value) => {
  if (!value) {
    return value;
  }
  return value
    .replace(/[^\d,.]/g, '')
    .replace(/(,\d{2}).*$/, '$1');
};

export const normalizeIntegers = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '').replace(/^0+/, '');
};

export const normalizeZipCodes = (value) => {
  if (!value) {
    return value;
  }
  if (value.includes('.') || value.includes(',')) {
    return value.replace('.', '').replace(',', '');
  }
  return value;
};

export const normalizePercent = (value) => {
  if (!value) {
    return value;
  }
  const digits = value.replace(/[^\d,.]/g, '');
  const digitsNumber = Number(digits.replace(',', '.'));
  if (digits.startsWith('100')) {
    return '100';
  }
  if (Number(digitsNumber) > 100) {
    return '100';
  }
  if (Number(digitsNumber) < 10) {
    return digits.slice(0, 4);
  }
  return digits.slice(0, 5);
};

export const normalizePostCode = (value) => {
  if (!value) {
    return value;
  }
  return value
    .replace(/[^\d]/g, '')
    .replace(/(\d{5}).*$/, '$1');
};
