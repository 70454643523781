import React from 'react';
import * as b from 'react-bootstrap';

const ErrorNotFoundPage = () => (
  <div className="">
    <b.Grid>
      <b.Row>
        <b.Col>
          <h1>Not Found</h1>
          <p>
            The page you were looking for does not exist.
          </p>
        </b.Col>
      </b.Row>
    </b.Grid>
  </div>
);

export default ErrorNotFoundPage;
