import _map from 'lodash/map';
import _includes from 'lodash/includes';
import { NAME } from './constants';

export const getIsSaveSalesmanPending = (state) => !!state[NAME].isSaveSalesmanPending;
export const getIsEditing = (id) => (state) => _includes(state[NAME].editIds, id);
export const getIsGetSalesmanPending = (state) => !!state[NAME].isGetSalesmanPending;
export const getIsListSalesmenPending = (state) => !!state[NAME].isListSalesmenPending;
export const getSalesman = (id) => (state) => state[NAME].salesmenById[id];
export const listSalesmen = (state) => _map(state[NAME].salesmen,
  (id) => state[NAME].salesmenById[id]);
