import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import formName from '../promotion/edit/formName';
import PMDashboardPage from './PMDashboardPage';
import {
  listPromotions, getPromotion, getContractTypes, getProductGroups,
} from '../../promotion/actions';
import { listInsurances, getInsurance } from '../../insurance/actions';
import * as sel from '../../user/selectors';
import { emailsWhitelist } from '../../emailsWhitelist';
import PromotionCreatePageContainer from '../promotion/create/CreatePageContainer';
import PromotionUpdatePageContainer from '../promotion/edit/CreatePageContainer';
import InsuranceCreatePageContainer from '../insurance/create/CreatePageContainer';
import InsuranceUpdatePageContainer from '../insurance/edit/CreatePageContainer';

class PMDashboardPageContainer extends Component {
  state = {
    showInsuranceCreateForm: false,
    showPromotionCreateForm: false,
    showInsuranceUpdateFormForInsuranceId: '',
    showPromotionUpdateFormForPromotionId: '',
    showPromotionsAndInsurancesLists: true,
    insuranceEditActiveId: '',
    promotionEditActiveId: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getProductGroups());
    dispatch(getContractTypes());
    dispatch(listPromotions());
    dispatch(listInsurances());
  }

  onDestroyForm = () => {
    const { dispatch } = this.props;
    this.setState({
      showPromotionsAndInsurancesLists: true,
      showInsuranceCreateForm: false,
      showPromotionCreateForm: false,
      showInsuranceUpdateFormForInsuranceId: '',
      insuranceEditActiveId: '',
      showPromotionUpdateFormForPromotionId: '',
      promotionEditActiveId: '',
    });
    dispatch(listPromotions());
    dispatch(listInsurances());
  }

  onShowInsuranceCreateForm = () => {
    this.setState({
      showInsuranceCreateForm: true,
      showPromotionCreateForm: false,
      showInsuranceUpdateFormForInsuranceId: '',
      insuranceEditActiveId: '',
      showPromotionUpdateFormForPromotionId: '',
      promotionEditActiveId: '',
      showPromotionsAndInsurancesLists: false,
    });
  };

  onShowPromotionCreateForm = () => {
    this.setState({
      showPromotionCreateForm: true,
      showInsuranceCreateForm: false,
      showPromotionUpdateFormForPromotionId: '',
      promotionEditActiveId: '',
      showInsuranceUpdateFormForInsuranceId: '',
      insuranceEditActiveId: '',
      showPromotionsAndInsurancesLists: false,
    });
  };

  onShowPromotionUpdateForm = (id) => {
    const { dispatch } = this.props;
    if (id !== this.state.showPromotionUpdateFormForPromotionId) {
      this.setState({
        showPromotionUpdateFormForPromotionId: id,
        promotionEditActiveId: id,
        showInsuranceUpdateFormForInsuranceId: '',
        insuranceEditActiveId: '',
        showPromotionCreateForm: false,
        showInsuranceCreateForm: false,
      });
      dispatch(destroy(formName));
    }
    dispatch(getPromotion(id));
  };

  onShowInsuranceUpdateForm = (id) => {
    const { dispatch } = this.props;
    if (id !== this.state.showInsuranceUpdateFormForInsuranceId) {
      dispatch(destroy(formName));
      this.setState({
        showInsuranceUpdateFormForInsuranceId: id,
        insuranceEditActiveId: id,
        showPromotionUpdateFormForPromotionId: '',
        promotionEditActiveId: '',
        showPromotionCreateForm: false,
        showInsuranceCreateForm: false,
      });
    }
    dispatch(getInsurance(id));
  };

  render() {
    const { isAdmin, userEmail } = this.props;
    if (isAdmin || emailsWhitelist.indexOf(userEmail) !== -1) {
      return (
        <div>
          <PMDashboardPage
            onShowInsuranceCreateForm={this.onShowInsuranceCreateForm}
            onShowPromotionCreateForm={this.onShowPromotionCreateForm}
            onShowInsuranceUpdateForm={this.onShowInsuranceUpdateForm}
            onShowPromotionUpdateForm={this.onShowPromotionUpdateForm}
            insuranceEditActiveId={this.state.insuranceEditActiveId}
            promotionEditActiveId={this.state.promotionEditActiveId}
            showPromotionsAndInsurancesLists={this.state.showPromotionsAndInsurancesLists}
          />
          { this.state.showPromotionUpdateFormForPromotionId
            && (
            <PromotionUpdatePageContainer
              onDestroyForm={this.onDestroyForm}
              onShowPromotionUpdateForm={this.onShowPromotionUpdateForm}
              showPromotionUpdateFormForPromotionId={
                this.state.showPromotionUpdateFormForPromotionId
              }
            />
            ) }
          { this.state.showInsuranceUpdateFormForInsuranceId
            && (
            <InsuranceUpdatePageContainer
              onDestroyForm={this.onDestroyForm}
              onShowInsuranceUpdateForm={this.onShowInsuranceUpdateForm}
              showInsuranceUpdateFormForInsuranceId={
                this.state.showInsuranceUpdateFormForInsuranceId
              }
            />
            ) }
          { this.state.showPromotionCreateForm
            && (
            <PromotionCreatePageContainer
              onDestroyForm={this.onDestroyForm}
            />
            ) }
          { this.state.showInsuranceCreateForm
            && (
            <InsuranceCreatePageContainer
              onDestroyForm={this.onDestroyForm}
            />
            ) }
        </div>
      );
    }
    return null;
  }
}

PMDashboardPageContainer.propTypes = {
  isAdmin: T.bool.isRequired,
  dispatch: T.func.isRequired,
  userEmail: T.string.isRequired,
};

PMDashboardPageContainer.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  isAdmin: sel.getIsAdmin(state),
  userEmail: sel.getCurrentUserEmail(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PMDashboardPageContainer);
