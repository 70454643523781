export const NAME = 'leasingApplication';

export const CONTRACT_TYPE_MK = 2;
export const CONTRACT_TYPE_TA = 11;
export const CONTRACT_TYPE_VA = 1;
export const MAGIC_NEW_RETAILER_ID = 'NEW_RETAILER';
export const APPROVED_APPLICATION = 960;
export const APPROVED_APPLICATION_BY_FINANCIAL_DECISION = 980;
export const CANCELLED_DECISION = 997;
export const PRODUCT_GROUP_KFZ = '1100025';
export const PRODUCT_GROUP_KASSENTISCHE = '1100023';
