const validate = (values) => {
  const errors = {};
  if (!values.value) {
    errors.value = 'Bitte tragen Sie einen Versicherungsbetrag ein';
  }

  if (!values.type) {
    errors.type = 'Bitte wählen Sie eine Art aus';
  }

  let valueNumber = values.value || '0';
  if (values.type === '%') valueNumber = Number(valueNumber.replace(',', '.'));
  if (values.type === 'EUR') valueNumber = Number(valueNumber.replace('.', '').replace(',', '.'));

  if (values.type === '%' && valueNumber > 100) {
    errors.value = 'Der Versicherungsbetrag kann nicht größer 100 sein';
  }

  if (values.type === '%' && !values.min_value) {
    errors.min_value = 'Bitte wählen Sie eine Minimum insurance value aus';
  }

  return errors;
};

export default validate;
