import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import * as t from './actionTypes';

export const initialState = {
  contractTypes: null,
  error: null,
  isComputeRatesPending: false,
  isCreateOfferPending: false,
  isGetContractTypesPending: false,
  isGetOfferPending: false,
  isGetLegalFormsPending: false,
  isGetProductGroupsPending: false,
  isListOffersPending: false,
  offers: [],
  offersById: {},
  offerSummariesById: {},
  legalForms: null,
  monthOfCancellation: null,
  productGroups: null,
  rates: null,
  residualValue: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case t.CLEAR_RATES:
      return {
        ...state,
        monthOfCancellation: null,
        rates: null,
      };
    case t.COMPUTE_RATES_FULFILLED:
      return {
        ...state,
        error: null,
        isComputeRatesPending: false,
        monthOfCancellation: action.payload.month_of_cancellation,
        rates: action.payload.rates,
        residualValue: action.payload.residual_value,
      };
    case t.COMPUTE_RATES_PENDING:
      return {
        ...state,
        isComputeRatesPending: true,
      };
    case t.COMPUTE_RATES_REJECTED:
      return {
        ...state,
        isComputeRatesPending: false,
        error: action.payload,
      };
    case t.CREATE_OFFER_FULFILLED:
      return {
        ...state,
        error: null,
        isCreateOfferPending: false,
      };
    case t.CREATE_OFFER_PENDING:
      return {
        ...state,
        isCreateOfferPending: true,
      };
    case t.CREATE_OFFER_REJECTED:
      return {
        ...state,
        isCreateOfferPending: false,
        error: action.payload,
      };
    case t.GET_CONTRACT_TYPES_FULFILLED:
      return {
        ...state,
        error: null,
        isGetContractTypesPending: false,
        contractTypes: action.payload,
      };
    case t.GET_CONTRACT_TYPES_PENDING:
      return {
        ...state,
        isGetContractTypesPending: true,
      };
    case t.GET_CONTRACT_TYPES_REJECTED:
      return {
        ...state,
        isGetContractTypesPending: false,
        error: action.payload,
      };
    case t.GET_OFFER_FULFILLED:
      return {
        ...state,
        error: null,
        isGetOfferPending: false,
        offersById: {
          ...state.offersById,
          [action.payload.id]: action.payload,
        },
      };
    case t.GET_OFFER_PENDING:
      return {
        ...state,
        isGetOfferPending: true,
      };
    case t.GET_OFFER_REJECTED:
      return {
        ...state,
        isGetOfferPending: false,
        error: action.payload,
      };
    case t.GET_LEGAL_FORMS_FULFILLED:
      return {
        ...state,
        error: null,
        isGetLegalFormsPending: false,
        legalForms: action.payload,
      };
    case t.GET_LEGAL_FORMS_PENDING:
      return {
        ...state,
        isGetLegalFormsPending: true,
      };
    case t.GET_LEGAL_FORMS_REJECTED:
      return {
        ...state,
        isGetLegalFormsPending: false,
        error: action.payload,
      };
    case t.GET_PRODUCT_GROUPS_FULFILLED:
      return {
        ...state,
        error: null,
        isGetProductGroupsPending: false,
        productGroups: action.payload,
      };
    case t.GET_PRODUCT_GROUPS_PENDING:
      return {
        ...state,
        isGetProductGroupsPending: true,
      };
    case t.GET_PRODUCT_GROUPS_REJECTED:
      return {
        ...state,
        isGetProductGroupsPending: false,
        error: action.payload,
      };
    case t.LIST_OFFERS_FULFILLED:
      return {
        ...state,
        error: null,
        isListOffersPending: false,
        offers: _map(action.payload, (app) => app.id),
        offerSummariesById: _mapValues(
          _groupBy(action.payload, (app) => app.id),
          (array) => array[0],
        ),
      };
    case t.LIST_OFFERS_PENDING:
      return {
        ...state,
        isListOffersPending: true,
      };
    case t.LIST_OFFERS_REJECTED:
      return {
        ...state,
        isListOffersPending: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
