import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  change, formValueSelector, reduxForm, Field,
} from 'redux-form';
import SelectField from '../../SelectField';
import PercentField from '../../PercentField';
import MoneyField from '../../MoneyField';
import CheckboxField from '../../CheckboxField';
import WizardButtons from './WizardButtons';
import formName from './formName';
import parseForm from './parseForm';
import { normalizePercent, normalizeMoney } from '../../normalizers';
import validate from './validate';
import * as s from '../../../insurance/selectors';
import * as sel from '../../../promotion/selectors';

class CreateWizardStepDetails extends Component {
  onAcceptDeactivation = () => {
    this.props.dispatch(change(formName, 'is_deactivation_accepted', true));
  };

  onDenyDeactivation = () => {
    this.props.dispatch(change(formName, 'is_deactivation_accepted', false));
    this.props.dispatch(change(formName, 'is_deactivated', false));
  };

  render() {
    const {
      error,
      formData,
      handleSubmit,
      initialValues,
      onCancel,
      onSubmit,
      pristine,
      submitting,
    } = this.props;
    return (
      <b.Row>
        <b.Col sm={12}>
          <b.Form>
            {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
            <h4 className="pb-3">Versicherung aktualisieren</h4>
            <div>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>
                    Versicherungsbetrag
                    <span title="Pflichtfeld" className="asterisk-required">
                      *
                    </span>
                  </b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  {formData.type === '%' ? (
                    <Field
                      name="value"
                      required
                      component={PercentField}
                      type="text"
                      placeholder="(z.B. 1,25)"
                      normalize={normalizePercent}
                    />
                  ) : (
                    <Field
                      name="value"
                      required
                      component={MoneyField}
                      type="text"
                      placeholder="(z.B. 15,50)"
                      normalize={normalizeMoney}
                    />
                  )}
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>
                    Art
                    <span title="Pflichtfeld" className="asterisk-required">
                      *
                    </span>
                  </b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="type"
                    required
                    component={SelectField}
                    values={[
                      ['%', '%'],
                      ['EUR', 'EUR'],
                    ]}
                  />
                </b.Col>
              </b.Row>
              {formData.type === '%' && (
                <b.Row>
                  <b.Col sm={4}>
                    <b.ControlLabel>
                      Standard Versicherungswert
                      <span title="Pflichtfeld" className="asterisk-required">
                        *
                      </span>
                    </b.ControlLabel>
                  </b.Col>
                  <b.Col sm={8}>
                    <Field
                      name="min_value"
                      required
                      component={MoneyField}
                      type="text"
                      placeholder="(z.B. 15,50)"
                      normalize={normalizeMoney}
                    />
                  </b.Col>
                </b.Row>
              )}
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel />
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    title="Deaktivieren"
                    name="is_deactivated"
                    type="checkbox"
                    component={CheckboxField}
                  />
                </b.Col>
              </b.Row>
              {formData.isDeactivated
                && !formData.isDeactivationAccepted
                  && !formData.isInitialDeactivated
                    && initialValues.promotions_assigned.length !== 0
                && (
                <div className="alert alert-danger">
                  <div>
                    Möchten Sie die Versicherung wirklich deaktivieren?
                    Wenn die Versicherung deaktiviert wird,
                    werden ebenfalls alle Aktionen deaktiviert,
                    in denen diese Versicherung verwendet wird.
                    In den folgenden Aktionen wird diese Versicherung verwendet:
                  </div>
                  <ul>
                    {initialValues.promotions_assigned.map((promo) => (
                      <li key={promo.promotionId}>
                        {promo.promotionName}
                        {' '}
                        (
                        {promo.promotionId.substr(0, 5)}
                        )
                      </li>
                    ))}
                  </ul>
                  <div className="pt-2 text-center">
                    <b.Button bsSize="sm" bsStyle="danger" onClick={this.onDenyDeactivation} className="mr-2">
                      Nein, nicht deaktivieren
                    </b.Button>
                    <b.Button bsSize="sm" bsStyle="success" onClick={this.onAcceptDeactivation}>
                      Ja, Versicherung deaktivieren
                    </b.Button>
                  </div>
                </div>
                )}
              <WizardButtons
                handleSubmit={handleSubmit}
                onCancel={onCancel}
                onSubmit={onSubmit}
                pristine={pristine}
                submitting={submitting}
              />
            </div>
          </b.Form>
        </b.Col>
      </b.Row>
    );
  }
}

CreateWizardStepDetails.propTypes = {
  dispatch: T.func.isRequired,
  error: T.string,
  formData: T.any.isRequired,
  handleSubmit: T.func.isRequired,
  initialValues: T.any.isRequired,
  onCancel: T.func.isRequired,
  onSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepDetails.defaultProps = {
  error: null,
};

const fetchFormData = (state) => parseForm(formValueSelector(formName)(state, 'value', 'type',
  'is_deactivated', 'is_deactivation_accepted', 'is_initial_deactivated'));

const mapStateToProps = (state, ownProps) => {
  const initialValues = s.getInitialValuesForUpdateForm(ownProps.id)(state);
  return {
    initialValues: {
      ...initialValues,
      promotions_assigned: sel.getPromotionsAssigned(ownProps.id)(state),
    },
    formData: fetchFormData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    enableReinitialize: false,
    validate,
  })(CreateWizardStepDetails),
);
