import { omit } from 'lodash';
import * as typ from './actionTypesCreateForm';

const calcDownPaymentFrom = (
  purchasePriceFrom,
  purchasePriceTo,
  financingValueFrom,
  financingValueTo,
) => {
  const purchasePriceFromNumber = Number(purchasePriceFrom.replace('.', '').replace(',', '.')) || 0;
  const purchasePriceToNumber = Number(purchasePriceTo.replace('.', '').replace(',', '.').replace('unendlich', 'Infinity')) || Infinity;
  const financingValueFromNumber = Number(financingValueFrom.replace('.', '').replace(',', '.')) || 0;
  const financingValueToNumber = Number(financingValueTo.replace('.', '').replace(',', '.').replace('unendlich', 'Infinity')) || Infinity;

  const fromDifference = purchasePriceFromNumber - financingValueFromNumber;
  const toDifference = purchasePriceToNumber - financingValueToNumber;
  const downPaymentFrom = fromDifference < toDifference ? fromDifference : toDifference;

  if (downPaymentFrom < 0 || isNaN(downPaymentFrom)) {
    return '0';
  }
  return String(downPaymentFrom).replace('.', ',');
};

const calcDownPaymentTo = (
  purchasePriceFrom,
  purchasePriceTo,
  financingValueFrom,
  financingValueTo,
) => {
  const purchasePriceFromNumber = Number(purchasePriceFrom.replace('.', '').replace(',', '.')) || 0;
  const purchasePriceToNumber = Number(purchasePriceTo.replace('.', '').replace(',', '.').replace('unendlich', 'Infinity')) || Infinity;
  const financingValueFromNumber = Number(financingValueFrom.replace('.', '').replace(',', '.')) || 0;
  const financingValueToNumber = Number(financingValueTo.replace('.', '').replace(',', '.').replace('unendlich', 'Infinity')) || Infinity;

  const fromDifference = purchasePriceFromNumber - financingValueFromNumber;
  let toDifference = purchasePriceToNumber - financingValueToNumber;
  if (isNaN(toDifference)) toDifference = Infinity;
  const downpaymentTo = fromDifference > toDifference ? fromDifference : toDifference;

  if (downpaymentTo < 0) {
    return '0';
  }
  if (isNaN(downpaymentTo)) {
    return 'unendlich';
  }
  return String(downpaymentTo).replace('.', ',').replace('Infinity', 'unendlich');
};

export default function reducer(state, action) {
  switch (action.type) {
    case typ.CHANGE_MONEY_FIELD:
      return {
        ...state,
        values: {
          ...state.values,
          down_payment_from: calcDownPaymentFrom(
            state.values.purchase_price_from,
            state.values.purchase_price_to,
            state.values.financing_value_from,
            state.values.financing_value_to,
          ),
          down_payment_to: calcDownPaymentTo(
            state.values.purchase_price_from,
            state.values.purchase_price_to,
            state.values.financing_value_from,
            state.values.financing_value_to,
          ),
        },
      };
    case typ.ADD_COLUMN:
      return {
        ...state,
        values: {
          ...state.values,
          elems: [
            ...state.values.elems,
            {
              purchasePrice: Number(action.payload),
              contractTerms: {},
            },
          ],
        },
      };
    case typ.ADD_ROW:
      return {
        ...state,
        values: {
          ...state.values,
          elems: state.values.elems.map((el) => ({
            ...el,
            contractTerms: {
              ...el.contractTerms,
              [action.payload]: {
                factorValue: null,
                insurance: {
                  value: null,
                  type: null,
                },
              },
            },
          })),
        },
      };
    case typ.DELETE_COLUMN:
      return {
        ...state,
        values: {
          ...omit(
            state.values,
            Object.keys(state.values).filter((key) => key.endsWith(`col${action.payload}`)),
          ),
          elems: state.values.elems.filter(
            (el) => el.purchasePrice !== action.payload,
          ),
        },
      };
    case typ.DELETE_ROW:
      return {
        ...state,
        values: {
          ...omit(
            state.values,
            Object.keys(state.values).filter((key) => key.includes(`row${action.payload}`)),
          ),
          elems: state.values.elems.map((el) => ({
            ...el,
            contractTerms: Object.keys(el.contractTerms)
              .filter((term) => term !== action.payload)
              .reduce((obj, key) => {
                obj[key] = el.contractTerms[key]; // eslint-disable-line no-param-reassign
                return obj;
              }, {}),
          })),
        },
      };
    case typ.CONTINUE_FOR_CONDITION:
      if (action.payload === 'factor') {
        return {
          ...state,
          values: {
            ...omit(state.values, [
              'interest_condition',
              'insurance_parameters',
            ]),
          },
        };
      }
      return {
        ...state,
        values: {
          ...omit(
            state.values,
            Object.keys(state.values).filter(
              (key) => key.startsWith('factor_value-')
                || key.startsWith('insurance_parameters-'),
            ),
          ),
        },
      };
    case typ.UPDATE_TABLE:
      return {
        ...state,
        values: {
          ...state.values,
          columnsArray:
            (state.values.elems.length !== 0
              && state.values.elems
                .map((el) => el.purchasePrice)
                .sort((a1, a2) => a1 - a2))
            || [],
          rowsArray: [
            ...Array.from(
              new Set(
                [].concat(
                  ...((state.values.elems.length !== 0
                    && state.values.elems.map((el) => Object.keys(el.contractTerms)))
                    || []),
                ),
              ),
            ).sort((a1, a2) => Number(a1) - Number(a2)),
            'last',
          ],
        },
      };
    default:
      return state;
  }
}
