import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change, untouch } from 'redux-form';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _some from 'lodash/some';
import CreateWizardStepAddressesForm from './CreateWizardStepAddressesForm';
import CreateWizardStepObjectDataForm from './CreateWizardStepObjectDataForm';
import CreateWizardStepRateForm from './CreateWizardStepRateForm';
import CreateWizardStepRetailerForm from './CreateWizardStepRetailerForm';
import * as offerAction from '../../../offer/actions';
import * as a from '../../../convertOffer/actions';
import * as m from '../../../convertOffer/model';
import * as offerModel from '../../../offer/model';
import * as o from '../../../salesman/model';
import * as s from '../../../convertOffer/selectors';
import * as offerSelector from '../../../offer/selectors';
import * as u from '../../../user/selectors';
import * as salesmanSelector from '../../../salesman/selectors';
import * as les from '../../../lessee/selectors';
import * as l from '../../../lessee/actions';
import {
  CONTRACT_TYPE_TA,
  CONTRACT_TYPE_VA,
  MAGIC_NEW_RETAILER_ID,
} from '../../../convertOffer/constants';
import formName from './formName';
import parseForm from './parseForm';
import BigSpinner from '../../BigSpinner';
import { formatDate } from '../../formatters';
import * as usr from '../../../user/actions';
import CreateWizardStepRegisterForm from './CreateWizardStepRegisterForm';

const STEP_REGISTER = 'REGISTER';
const STEP_ADRESSES = 'ADRESSES';
const STEP_OBJECT = 'OBJECT';
const STEP_RATE = 'RATE';
const STEP_RETAILER = 'RETAILER';

class CreateWizard extends Component {
  constructor(props) {
    super(props);
    this.state = { step: STEP_OBJECT };
  }

  componentDidUpdate(prevProps) {
    const { retailerId, dispatch } = this.props;
    if (retailerId !== prevProps.retailerId) {
      dispatch(a.getRetailer(Number(retailerId)));
    }
  }

  render() {
    const {
      isAdmin,
      registerError,
      userEmail,
      isGuest,
      lesseeData,
      userRole,
      offer,
      contractTypes,
      dispatch,
      isComputeRatesPending,
      isCreatePending,
      isRegisterPending,
      isGetContractTypesPending,
      isGetLegalFormsPending,
      isGetProductGroupsPending,
      isGetRetailersPending,
      isSaleAndLeaseBack,
      leasingApplications,
      listSalesmen,
      legalForms,
      monthOfCancellation,
      objectData,
      offers,
      onCancel,
      onSubmit,
      productGroups,
      rates,
      registerData,
      residualValue,
      retailer,
      retailers,
      retailerId,
      retailerGroupsLGH,
      retailerReferenceObj,
      paramsId,
    } = this.props;

    const lookupContractType = (id) => _find(contractTypes, (v) => v.id === id).description;
    const lookupProductGroup = (id) => {
      const prodGr = _find(productGroups, (v) => v.id === id);
      if (prodGr) return prodGr.description;
      return '';
    };
    const onStepObjectData = () => {
      dispatch(a.getOffer(paramsId));
      dispatch(change(formName, 'lease_term_and_rate', ''));
      dispatch(untouch(formName, 'lease_term_and_rate'));
      this.setState({ step: STEP_OBJECT });
    };

    const onStepRate = () => {
      dispatch(change(formName, 'sale_and_lease_back', undefined));
      if (isSaleAndLeaseBack) dispatch(change(formName, 'sale_and_lease_back', true));
      if (!objectData.object_name) {
        const desc = lookupProductGroup(objectData.product_group);
        dispatch(change(formName, 'object_name', desc));
      }
      dispatch(a.clearRates());
      const rateProps = {
        ..._pick(objectData, [
          'contract_type',
          'down_payment',
          'product_group',
          'purchase_price',
          'residual_value_percent',
        ]),
        retailer_groups: retailerGroupsLGH,
      };
      if (userRole === 'lessee') {
        rateProps.region = registerData.lessee_post_code;
      }
      if (!isSaleAndLeaseBack && retailer) {
        rateProps.region = retailer.post_code;
        rateProps.retailer = retailer.id;
        rateProps.retailer_groups = retailer.retailer_groups.concat(retailerGroupsLGH);
      }
      if (retailerId === MAGIC_NEW_RETAILER_ID) {
        rateProps.region = objectData.retailer_post_code;
      }

      dispatch(a.computeRates(rateProps));

      this.setState({ step: STEP_RATE });
    };
    const onStepRetailer = () => {
      if (!objectData.object_name) {
        const desc = lookupProductGroup(objectData.product_group);
        dispatch(change(formName, 'object_name', desc));
      }
      dispatch(change(formName, 'sale_and_lease_back', undefined));
      if (isSaleAndLeaseBack) dispatch(change(formName, 'sale_and_lease_back', true));
      this.setState({ step: STEP_RETAILER });
    };
    const onStepRegister = () => {
      this.setState({ step: STEP_REGISTER });
    };
    const onStepAddresses = () => {
      this.setState({ step: STEP_ADRESSES });
    };
    const onSelectAddress = ({ value, kind }) => {
      switch (kind) {
        case 'application':
          dispatch(a.getLeasingApplication(value)).then((app) => {
            [
              'lessee_name',
              'lessee_legal_form',
              'lessee_street',
              'lessee_post_code',
              'lessee_city',
              'lessee_phone',
              'lessee_email',
              'lessee_iban',
              'manager_salutation',
              'manager_first_name',
              'manager_last_name',
              'manager_street',
              'manager_post_code',
              'manager_city',
            ].forEach((fieldName) => {
              dispatch(change(formName, fieldName, app[fieldName]));
            });

            if (app.manager_birth_date) {
              dispatch(
                change(
                  formName,
                  'manager_birth_date',
                  formatDate(app.manager_birth_date),
                ),
              );
            }
            if (app.manager_salutation) {
              dispatch(
                change(
                  formName,
                  'manager_salutation',
                  app.manager_salutation.toString(),
                ),
              );
            }
          });
          break;
        case 'offer':
          dispatch(offerAction.getOffer(value)).then((app) => {
            [
              'lessee_name',
              'lessee_legal_form',
              'lessee_street',
              'lessee_post_code',
              'lessee_city',
              'lessee_phone',
              'lessee_email',
              'lessee_iban',
            ].forEach((fieldName) => {
              dispatch(change(formName, fieldName, app[fieldName]));
            });
            dispatch(change(formName, 'manager_salutation', ''));
            dispatch(change(formName, 'manager_first_name', ''));
            dispatch(change(formName, 'manager_last_name', ''));
            dispatch(change(formName, 'manager_street', ''));
            dispatch(change(formName, 'manager_city', ''));
            dispatch(change(formName, 'manager_post_code', ''));
            dispatch(change(formName, 'manager_birth_date', ''));
          });
          break;
        default:
          break;
      }
    };

    const showFinalPayment = objectData.contract_type === CONTRACT_TYPE_VA
      && _some(rates, 'final_payment');
    const showResidualValue = objectData.contract_type === CONTRACT_TYPE_TA;
    const wantsNewRetailer = retailerId === MAGIC_NEW_RETAILER_ID;
    const residualValuePercent = 10;

    const checkStepAfterRegister = () => {
      dispatch(usr.register(registerData)).then(() => {
        dispatch(l.getLesseeData()).then(() => {
          if (!this.props.userRole === 'lessee') {
            // do nothing, stay in place
          } else {
            onStepAddresses();
          }
        });
      });
    };

    const checkStepBack = () => {
      if (isSaleAndLeaseBack) {
        onStepObjectData();
      } else {
        onStepRetailer();
      }
    };
    const checkStepBackForAddressStep = () => {
      if (isGuest) {
        onStepRegister();
      } else {
        onStepRate();
      }
    };

    const checkStepAfterObjectData = () => {
      if (isSaleAndLeaseBack) {
        onStepRate();
      } else {
        onStepRetailer();
      }
    };

    const checkStepForward = () => {
      if (isGuest) {
        onStepRegister();
      } else {
        onStepAddresses();
      }
    };
    let lesseeEmail = lesseeData.lessee && lesseeData.lessee.ln_email;
    if (userRole === 'guest') lesseeEmail = userEmail;
    const contractTypesBasics = (contractTypes.length && contractTypes.filter((contact) => !contact.description.endsWith('(Sale-And-Lease-Back)'))) || [];
    const contractDescription = (contractTypesBasics.length
    && contractTypesBasics.filter((contract) => contract.id === offer.contract_type)) || '';
    const contractChosen = contractDescription.length && `${contractDescription[0].id}---${contractDescription[0].description}`;

    const initialValues = {
      ...offer,
      contract_type: contractChosen,
      lessee_name: lesseeData.lessee && lesseeData.lessee.ln_name,
      lessee_email: lesseeEmail,
      lessee_city: lesseeData.lessee && lesseeData.lessee.ln_ort,
      lessee_street: lesseeData.lessee && lesseeData.lessee.ln_strasse,
      lessee_post_code: lesseeData.lessee && lesseeData.lessee.ln_plz,
      lessee_phone: lesseeData.lessee && lesseeData.lessee.ln_telefon,
      user_role: userRole,
      reference_obj: {
        is_reference_field_required: retailerReferenceObj.isReferenceFieldRequired,
        is_reference_value_required: retailerReferenceObj.isReferenceValueRequired,
        reference_description: retailerReferenceObj.referenceDescription,
      },
    };
    switch (this.state.step) {
      case STEP_OBJECT:
        if (!offer) {
          return <BigSpinner />;
        }
        return (
          <CreateWizardStepObjectDataForm
            residualValuePercent={residualValuePercent}
            initialValues={initialValues}
            contractTypes={contractTypes}
            isGetContractTypesPending={isGetContractTypesPending}
            isGetProductGroupsPending={isGetProductGroupsPending}
            objectData={objectData}
            onCancel={onCancel}
            onSubmit={checkStepAfterObjectData}
            retailerReferenceObj={retailerReferenceObj}
            productGroups={productGroups}
            showResidualValue={showResidualValue}
            userRole={userRole}
          />
        );
      case STEP_RETAILER:
        return (
          <CreateWizardStepRetailerForm
            isGuest={isGuest}
            isGetRetailersPending={isGetRetailersPending}
            isPending={isCreatePending}
            objectData={objectData}
            onCancel={onCancel}
            onStepObjectData={onStepObjectData}
            onStepRate={onStepRate}
            onSubmit={onStepRate}
            retailers={retailers}
            wantsNewRetailer={wantsNewRetailer}
          />
        );
      case STEP_RATE:
        return (
          <CreateWizardStepRateForm
            isComputeRatesPending={isComputeRatesPending}
            lookupContractType={lookupContractType}
            lookupProductGroup={lookupProductGroup}
            monthOfCancellation={monthOfCancellation}
            objectData={objectData}
            onCancel={onCancel}
            onPrevious={checkStepBack}
            onStepObjectData={onStepObjectData}
            onStepRetailer={onStepRetailer}
            onSubmit={checkStepForward}
            rates={rates}
            residualValue={residualValue}
            retailerReferenceObj={retailerReferenceObj}
            showFinalPayment={showFinalPayment}
            showResidualValue={showResidualValue}
          />
        );
      case STEP_REGISTER:
        return (
          <CreateWizardStepRegisterForm
            isGetLegalFormsPending={isGetLegalFormsPending}
            isPending={isRegisterPending}
            leasingApplications={leasingApplications}
            legalForms={legalForms}
            objectData={objectData}
            onCancel={onCancel}
            offers={offers}
            onPrevious={onStepRate}
            onSelectAddress={onSelectAddress}
            onStepObjectData={onStepObjectData}
            onStepRate={onStepRate}
            onStepRetailer={onStepRetailer}
            onSubmit={checkStepAfterRegister}
            registerError={registerError}
            userEmail={userEmail}
          />
        );
      case STEP_ADRESSES:
        return (
          <CreateWizardStepAddressesForm
            isAdmin={isAdmin}
            isGetLegalFormsPending={isGetLegalFormsPending}
            isPending={isCreatePending}
            leasingApplications={leasingApplications}
            legalForms={legalForms}
            lesseeData={lesseeData}
            listSalesmen={listSalesmen}
            objectData={objectData}
            onCancel={onCancel}
            onPrevious={checkStepBackForAddressStep}
            offers={offers}
            onSelectAddress={onSelectAddress}
            onStepObjectData={onStepObjectData}
            onStepRate={onStepRate}
            onStepRetailer={onStepRetailer}
            onSubmit={onSubmit}
            userRole={userRole}
          />
        );
      default:
        return null;
    }
  }
}

CreateWizard.propTypes = {
  isAdmin: T.bool.isRequired,
  isGuest: T.bool.isRequired,
  userEmail: T.string.isRequired,
  isRegisterPending: T.bool.isRequired,
  lesseeData: T.any,
  registerData: T.shape({
    lessee_name: T.string,
    lessee_legal_form: T.number,
    lessee_street: T.string,
    lessee_post_code: T.string,
    lessee_city: T.string,
    password: T.string,
    lessee_phone: T.string.isRequired,
    manager_first_name: T.string.isRequired,
    manager_last_name: T.string.isRequired,
    manager_salutation: T.number.isRequired,
    manager_street: T.string.isRequired,
    manager_post_code: T.string.isRequired,
    manager_city: T.string.isRequired,
    manager_birth_date: T.string.isRequired,
  }).isRequired,
  userRole: T.string.isRequired,
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  dispatch: T.func.isRequired,
  isComputeRatesPending: T.bool.isRequired,
  isCreatePending: T.bool.isRequired,
  isGetContractTypesPending: T.bool.isRequired,
  isGetLegalFormsPending: T.bool.isRequired,
  isGetProductGroupsPending: T.bool.isRequired,
  isGetRetailersPending: T.bool.isRequired,
  isSaleAndLeaseBack: T.bool,
  leasingApplications: T.arrayOf(m.leasingApplicationSummaryShape).isRequired,
  listSalesmen: T.arrayOf(o.salesmanShape).isRequired,
  legalForms: T.arrayOf(m.legalFormShape).isRequired,
  monthOfCancellation: T.number.isRequired,
  objectData: T.shape({
    contract_type: T.number.isRequired,
    down_payment: T.number.isRequired,
    reference: T.string,
    object_name: T.string.isRequired,
    lessee_post_code: T.string,
    product_group: T.number.isRequired,
    purchase_price: T.number.isRequired,
    residual_value_percent: T.number.isRequired,
    retailer_id: T.number,
    retailer_post_code: T.string,
    sale_and_lease_back: T.bool,
  }),
  onCancel: T.func.isRequired,
  offer: m.offerShape,
  offers: T.arrayOf(offerModel.offerSummaryShape).isRequired,
  onSubmit: T.func.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
  rates: T.arrayOf(m.rateShape).isRequired,
  residualValue: T.number.isRequired,
  retailer: T.any,
  retailers: T.arrayOf(m.retailerShape).isRequired,
  retailerId: T.string,
  retailerGroupsLGH: T.arrayOf(T.number).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  registerError: T.any,
  paramsId: T.number.isRequired,
};

CreateWizard.defaultProps = {
  isSaleAndLeaseBack: false,
  objectData: {},
  offer: { id: 0 },
  retailerId: null,
  registerError: null,
  userData: {},
  lesseeData: {},
  retailer: null,
  retailer_post_code: null,
};

const fetchObjectData = (state) => parseForm(
  formValueSelector(formName)(
    state,
    'contract_type',
    'down_payment',
    'reference',
    'object_name',
    'product_group',
    'purchase_price',
    'residual_value_percent',
    'retailer_post_code',
    'sale_and_lease_back',
  ),
);
const fetchRegisterData = (state) => _pick(
  parseForm(
    formValueSelector(formName)(
      state,
      'lessee_name',
      'lessee_legal_form',
      'lessee_street',
      'lessee_post_code',
      'lessee_city',
      'password',
      'lessee_phone',
      'manager_first_name',
      'manager_last_name',
      'manager_salutation',
      'manager_street',
      'manager_post_code',
      'manager_city',
      'manager_birth_date',
    ),
  ),
  [
    'lessee_name',
    'lessee_legal_form',
    'lessee_street',
    'lessee_post_code',
    'lessee_city',
    'password',
    'lessee_phone',
    'manager_first_name',
    'manager_last_name',
    'manager_salutation',
    'manager_street',
    'manager_post_code',
    'manager_city',
    'manager_birth_date',
  ],
);

const checkIsSaleAndLeaseBack = (state) => {
  const contract = formValueSelector(formName)(
    state,
    'contract_type',
  );
  if (contract === '1---Leasing (Sale-And-Lease-Back)'
  || contract === '11---Leasing mit Restwert (Sale-And-Lease-Back)') return true;
  return undefined;
};

const mapStateToProps = (state, ownProps) => ({
  isAdmin: u.getIsAdmin(state),
  userRole: u.getUserRole(state),
  isGuest: u.getIsGuest(state),
  lesseeData: les.getLesseeData(state),
  registerData: fetchRegisterData(state),
  isRegisterPending: u.getIsRegisterPending(state),
  contractTypes: s.getContractTypes(state),
  offer: s.getOffer(ownProps.paramsId)(state),
  isComputeRatesPending: s.getIsComputeRatesPending(state),
  isGetContractTypesPending: s.getIsGetContractTypesPending(state),
  isGetOfferPending: s.getIsGetOfferPending(state),
  isGetLegalFormsPending: s.getIsGetLegalFormsPending(state),
  isGetProductGroupsPending: s.getIsGetProductGroupsPending(state),
  isGetRetailersPending: s.getIsGetRetailersPending(state),
  isSaleAndLeaseBack: checkIsSaleAndLeaseBack(state),
  leasingApplications: s.listLeasingApplications(state),
  listSalesmen: salesmanSelector.listSalesmen(state),
  legalForms: s.getLegalForms(state),
  monthOfCancellation: s.getMonthOfCancellation(state),
  objectData: fetchObjectData(state),
  offers: offerSelector.listOffers(state),
  productGroups: s.getProductGroups(state),
  rates: s.getRates(state),
  residualValue: s.getResidualValue(state),
  retailer: s.getRetailer(state),
  registerError: u.getError(state),
  retailers: s.getRetailers(state),
  retailerId: formValueSelector(formName)(state, 'retailer_id'),
  retailerGroupsLGH: u.getRetailerGroupsLGH(state),
  retailerReferenceObj: u.getRetailerReferenceObj(state),
  userEmail: u.getCurrentUserEmail(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateWizard);
