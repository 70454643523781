/* global axios */

import * as typ from './actionTypes';

export function createInsurance(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.CREATE_INSURANCE_PENDING });
    return httpClient
      .post('/api_gateway/insurance', { insurance: values })
      .then(({ data }) => {
        dispatch({ type: typ.CREATE_INSURANCE_FULFILLED });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: typ.CREATE_INSURANCE_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function updateInsurance(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.CREATE_INSURANCE_PENDING });
    return httpClient
      .put('/api_gateway/insurance', { insurance: values })
      .then(({ data }) => {
        dispatch({ type: typ.CREATE_INSURANCE_FULFILLED });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: typ.CREATE_INSURANCE_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function getInsurance(id, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.GET_INSURANCE_PENDING });
    return httpClient
      .get('/api_gateway/insurance', { params: { id } })
      .then(({ data }) => {
        dispatch({ type: typ.GET_INSURANCE_FULFILLED, payload: data });
        return data;
      })
      .catch((err) => {
        dispatch({
          type: typ.GET_INSURANCE_REJECTED,
          payload: err,
        });
        throw err;
      });
  };
}

export function listInsurances(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: typ.LIST_INSURANCES_PENDING });
    return httpClient
      .get('/api_gateway/list_insurances')
      .then((res) => {
        dispatch({
          type: typ.LIST_INSURANCES_FULFILLED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: typ.LIST_INSURANCES_REJECTED,
          payload: err,
        });
      });
  };
}
