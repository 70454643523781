import { NAME } from './constants';

export const CHECK_SESSION_FULFILLED = `${NAME}/CHECK_SESSION_FULFILLED`;
export const CHECK_SESSION_PENDING = `${NAME}/CHECK_SESSION_PENDING`;
export const CHECK_SESSION_REJECTED = `${NAME}/CHECK_SESSION_REJECTED`;
export const LOGOUT_FULFILLED = `${NAME}/LOGOUT_FULFILLED`;
export const LOGOUT_PENDING = `${NAME}/LOGOUT_PENDING`;
export const LOGOUT_REJECTED = `${NAME}/LOGOUT_REJECTED`;
export const REGISTER_PENDING = `${NAME}/REGISTER_PENDING`;
export const REGISTER_FULLFILLED = `${NAME}/REGISTER_FULLFILLED`;
export const REGISTER_REJECTED = `${NAME}/REGISTER_REJECTED`;
export const UNREGISTER = `${NAME}/UNREGISTER`;
