import React from 'react';

const ContractsExplenation = () => (
  <div>
    <p>Erklärungen zu den Vertragsarten:</p>
    <ul style={{ paddingLeft: '20px' }}>
      <li>
        <strong>Leasing: </strong>
        Beim Leasing ohne Restwert wird die
        Leasinggesellschaft wirtschaftlicher Eigentümer des Leasingobjekts und
        bilanziert dieses. Sie nutzen den steuerlichen Vorteil die Leasingraten
        absetzen zu können. Das Leasingobjekt wird Ihnen für die vertraglich
        vereinbarte Dauer zur Nutzung überlassen. Sie haben kein Recht das
        Leasingobjekt zu erwerben.
      </li>
      <li>
        <strong>Leasing mit Restwert: </strong>
        Beim Leasing mit einem Restwert
        zahlen Sie über die Laufzeit hinweg niedrigere Raten, sodass am Ende der
        Vertragslaufzeit eine höhere Schlussrate übrig bleibt. Nach Ablauf des
        Vertrages haben Sie die Pflicht das Objekt zum vereinbarten Restwert zu
        übernehmen („Andienungsrecht“). Erst dann werden Sie zum
        wirtschaftlichen Eigentümer und bilanzieren das Objekt selbst.
      </li>
      <li>
        <strong>Mietkauf: </strong>
        Beim Mietkauf werden Sie wirtschaftlicher
        Eigentümer des Objekts und bilanzieren es selbst. Sie zahlen die Raten
        über die vertraglich vereinbarte Laufzeit. Die Steuer auf alle
        zukünftigen Raten wird vor Beginn des Vertrages fällig. Sie zahlen
        danach nur noch Netto-Raten.
      </li>
    </ul>
  </div>
);

export default ContractsExplenation;
