import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _sortedUniqBy from 'lodash/sortedUniqBy';
import InputField from '../../InputField';
import SelectField from '../../SelectField';
import YesNoRadioField from '../../YesNoRadioField';
import formName from './formName';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import validate from './validate';
import { normalizeIBAN, normalizePostCode } from '../../normalizers';
import * as m from '../../../changeApplication/model';

const addressSelectOptions = (leasingApplications) => {
  const label = (app) => _join(
    _compact([
      app.lessee_name,
      app.lessee_street,
      app.lessee_post_code,
      app.lessee_city,
    ]),
    ', ',
  );
  const options = _map(leasingApplications, (app) => ({
    value: app.id,
    label: label(app),
  }));
  const sortedOptions = _sortBy(options, (option) => option.label.toLowerCase());
  const uniqueOptions = _sortedUniqBy(sortedOptions, (option) => option.label.toLowerCase());
  return uniqueOptions;
};

const checkStepsClickHandlers = (
  objectData,
  onStepObjectData,
  onStepRetailer,
  onStepRate,
) => {
  if (objectData.sale_and_lease_back) {
    return [onStepObjectData, null, onStepRate];
  }
  return [onStepObjectData, onStepRetailer, onStepRate];
};

const CreateWizardStepAddressesForm = ({
  error,
  handleSubmit,
  isGetLegalFormsPending,
  isPending,
  leasingApplications,
  legalForms,
  objectData,
  onCancel,
  onStepObjectData,
  onStepRate,
  onStepRetailer,
  pristine,
  submitting,
  userRole,
}) => (
  <b.Row>
    <b.Col sm={12} lg={3}>
      <WizardNavigation
        current={4}
        onClickHandlers={checkStepsClickHandlers(objectData,
          onStepObjectData, onStepRetailer, onStepRate)}
      />
    </b.Col>
    <b.Col sm={12} lg={9}>
      <b.Form onSubmit={handleSubmit}>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        <fieldset>
          <legend>Leasingnehmersuche</legend>
          <Select
            disabled
            className="addresses"
            name="_addresses"
            options={addressSelectOptions(leasingApplications)}
            placeholder="Leasingnehmer nach Name, PLZ oder Ort suchen…"
          />
        </fieldset>
        <fieldset>
          <legend>Leasingnehmer</legend>
          <Field
            name="lessee_name"
            title="Firmenname"
            required
            component={InputField}
            type="text"
            disabled
          />
          <Field
            name="lessee_legal_form"
            title={`Rechtsform ${isGetLegalFormsPending ? '…' : ''}`}
            required
            component={SelectField}
            values={_concat(
              [['', '']],
              _map(legalForms, (l) => [l.id, l.description]),
            )}
            disabled="disabled"
          />
          <Field
            name="lessee_street"
            title="Straße"
            required
            component={InputField}
            type="text"
            disabled
          />
          <b.Row>
            <b.Col md={3}>
              <Field
                name="lessee_post_code"
                title="PLZ"
                required
                component={InputField}
                type="text"
                normalize={normalizePostCode}
                disabled
              />
            </b.Col>
            <b.Col md={9}>
              <Field
                name="lessee_city"
                title="Ort"
                required
                component={InputField}
                type="text"
                disabled
              />
            </b.Col>
          </b.Row>
          <Field
            name="lessee_phone"
            required
            title="Telefonnummer"
            component={InputField}
            type="text"
          />
          <Field
            name="lessee_email"
            title="E-Mail"
            component={InputField}
            type="text"
          />
          <Field
            name="lessee_iban"
            title="IBAN zur Abbuchung der Leasingraten"
            component={InputField}
            placeholder="DE..."
            type="text"
            normalize={normalizeIBAN}
          />

          {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <p>
              <i>
                valid IBANs for testing: DE02120300000000202051,
                DE02500105170137075030, DE02100500000054540402
              </i>
            </p>
          )}
        </fieldset>
        <fieldset>
          <legend>Einwilligungserklärung</legend>
          <Field
            name="accept_terms"
            title={(
              <span>
                Hiermit bestätige ich, dass mir die
                <a
                  href="/Einwilligung_Nutzungsbedingungen.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Einwilligung
                  {' '}
                </a>
                zur Weitergabe der Daten vorliegt.
              </span>
            )}
            required
            component={YesNoRadioField}
          />
        </fieldset>
        <WizardButtons
          isLastStep
          isPending={isPending}
          onCancel={onCancel}
          onPrevious={onStepRate}
          pristine={pristine}
          submitting={submitting}
          userRole={userRole}
        />
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepAddressesForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  isGetLegalFormsPending: T.bool.isRequired,
  isPending: T.bool.isRequired,
  leasingApplications: T.arrayOf(m.leasingApplicationSummaryShape).isRequired,
  legalForms: T.arrayOf(m.legalFormShape).isRequired,
  objectData: T.shape({ sale_and_lease_back: T.bool }).isRequired,
  onCancel: T.func.isRequired,
  onStepObjectData: T.func.isRequired,
  onStepRate: T.func.isRequired,
  onStepRetailer: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
  userRole: T.string,
};

CreateWizardStepAddressesForm.defaultProps = {
  error: null,
  userRole: null,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepAddressesForm);
