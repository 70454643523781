import React from 'react';
import { PropTypes as T } from 'prop-types';
import { Field } from 'redux-form';
import YesNoRadioField from '../../components/YesNoRadioField';

const AcceptTermsField = (props) => {
  const { userRole } = props;
  const title = userRole === 'guest' || userRole === 'lessee' ? (
    <span>
      Die
      {' '}
      <a href="/privacy" rel="noopener noreferrer" target="_blank">
        Datenschutzbestimmungen
      </a>
      {' '}
      habe ich zur Kenntnis genommen und bin einverstanden, dass meine Daten
      an die ALBIS Leasing Gruppe weitergegeben und gemäß der
      {' '}
      <a href="/privacy" rel="noopener noreferrer" target="_blank">
        Datenschutzerklärung der ALBIS Leasing Gruppe
      </a>
      {' '}
      dort verarbeitet werden
    </span>
  ) : (
    <span>
      Hiermit bestätige ich, dass mir die
      <a
        href="/Einwilligung_Nutzungsbedingungen.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        Einwilligung
        {' '}
      </a>
      zur Weitergabe der Daten vorliegt.
    </span>
  );

  return (
    <Field
      name="accept_terms"
      title={title}
      required
      component={YesNoRadioField}
    />
  );
};

AcceptTermsField.propTypes = {
  userRole: T.string.isRequired,
};

export default AcceptTermsField;
