/* eslint-disable no-param-reassign */

import axios from 'axios';

export const apiUrl = `${process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000'}`;

export default function configureAxios() {
  const instance = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });

  instance.interceptors.response.use(undefined, (err) => {
    if (err.response.status === 401) {
      window.location = `${apiUrl}/login`;
    }
    return Promise.reject(err);
  });

  return instance;
}
