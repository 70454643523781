/* global axios */

import * as t from './actionTypes';

export function checkSession(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.CHECK_SESSION_PENDING });
    return httpClient.get('/api/session')
      .then((res) => dispatch({ type: t.CHECK_SESSION_FULFILLED, payload: res.data }))
      .catch((err) => {
        dispatch({ type: t.CHECK_SESSION_REJECTED, payload: err });
      });
  };
}

export function register(values, httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.REGISTER_PENDING });
    return httpClient.post('/api/lessee', values)
      .then((res) => {
        dispatch({ type: t.REGISTER_FULLFILLED, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: t.REGISTER_REJECTED, payload: err });
      });
  };
}

export function logout(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.LOGOUT_PENDING });
    return httpClient.delete('/api/session')
      .then(() => {
        dispatch({ type: t.LOGOUT_FULFILLED });
        dispatch({ type: t.UNREGISTER });
      })
      .catch((err) => {
        dispatch({ type: t.LOGOUT_REJECTED, payload: err });
      });
  };
}
