import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import validationState from './validationState';

const PercentField = ({
  classNames,
  disabled,
  input,
  meta,
  placeholder,
  required,
  title,
  type,
  hideAddon,
}) => (
  <b.FormGroup
    controlId={input.name}
    validationState={validationState(meta)}
    className={classNames}
  >
    {title
      && (
      <b.ControlLabel>
        {title}
        {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
      </b.ControlLabel>
      )}
    <b.InputGroup>
      <b.FormControl
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        type={type}
        {...input}
      />
      {!hideAddon && <b.InputGroup.Addon>%</b.InputGroup.Addon>}
    </b.InputGroup>
    {meta.touched && meta.error
      && <b.HelpBlock>{meta.error}</b.HelpBlock>}
  </b.FormGroup>
);

PercentField.propTypes = {
  ...fieldPropTypes,
  hideAddon: T.bool,
};

PercentField.defaultProps = {
  custom: {},
  hideAddon: false,
};

export default PercentField;
