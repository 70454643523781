import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import * as sel from '../../../insurance/selectors';
import formName from './formName';
import WizardButtons from './WizardButtons';
import SelectField from '../../SelectField';
import PercentField from '../../PercentField';
import validate from './validate';
import { normalizePercent } from '../../normalizers';

const CreateWizardStepConditionInterest = ({
  error,
  handleSubmit,
  insurances,
  onCancel,
  onPrevious,
  onSubmit,
  pristine,
  submitting,
}) => (
  <b.Row>
    <b.Col sm={12}>
      <b.Form>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        <h4 className="pb-2">Einzelkondition - für alle Laufzeiten und Kaufpreise</h4>
        <b.Row>
          <b.Col sm={4}>
            <b.ControlLabel>
              Einzelkondition
              <span title="Pflichtfeld" className="asterisk-required">
                *
              </span>
            </b.ControlLabel>
          </b.Col>
          <b.Col sm={4}>
            <Field
              name="interest_condition"
              component={PercentField}
              type="text"
              placeholder="z.B. 1,25"
              normalize={normalizePercent}
            />
          </b.Col>
          <b.Col sm={4}>
            <Field
              name="insurance_parameters"
              component={SelectField}
              values={[[null, 'Versicherung'], ...insurances]}
            />
          </b.Col>
        </b.Row>
        <WizardButtons
          handleSubmit={handleSubmit}
          isConditionStep
          onCancel={onCancel}
          onPrevious={onPrevious}
          onSubmit={onSubmit}
          pristine={pristine}
          submitting={submitting}
        />
      </b.Form>
    </b.Col>
  </b.Row>
);

const mapStateToProps = (state) => ({
  insurances: sel.listInsurancesForSelectField(state),
});

CreateWizardStepConditionInterest.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  insurances: T.arrayOf(T.arrayOf(T.string)).isRequired,
  onCancel: T.func.isRequired,
  onPrevious: T.func.isRequired,
  onSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepConditionInterest.defaultProps = {
  error: null,
};

export default connect(
  mapStateToProps,
  undefined,
)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepConditionInterest));
