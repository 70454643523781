import React from 'react';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import validationState from './validationState';

const CheckboxField = ({
  classNames,
  disabled,
  input,
  itemValue,
  meta,
  title,
}) => {
  if (input) {
    return (
      <b.FormGroup
        controlId={input.name}
        validationState={validationState(meta)}
        className={classNames}
      >
        <input
          type="checkbox"
          {...input}
          value={itemValue}
          disabled={disabled}
          checked={input.value}
        />
        <label className="ml-2" htmlFor={input.name}>{title}</label>
      </b.FormGroup>
    );
  }
  return null;
};

CheckboxField.defaultProps = {
  custom: {},
};

CheckboxField.propTypes = {
  ...fieldPropTypes,
};

export default CheckboxField;
