import { PropTypes as T } from 'prop-types';

export const salesmanPropTypes = {
  active: T.bool,
  email: T.string,
  first_name: T.string,
  id: T.number.isRequired,
  last_name: T.string,
  only_own: T.bool,
  phone: T.string,
  salutation: T.number.isRequired,
};

export const salesmanShape = T.shape(salesmanPropTypes);
