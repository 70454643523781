import { CONTRACT_TYPE_TA, PRODUCT_GROUP_KFZ } from '../../../promotion/constants';

const validate = (values) => {
  const errors = {};
  if (!values.promotion_period_from) {
    errors.promotion_period_from = 'Bitte tragen Sie eine Laufzeit ein';
  }

  if (!values.promotion_period_to) {
    errors.promotion_period_to = 'Bitte tragen Sie eine Laufzeit ein';
  }

  if (values.promotion_period_from && values.promotion_period_to) {
    if (new Date(values.promotion_period_from) > new Date(values.promotion_period_to)) {
      errors.promotion_period_from = 'Das Start-Datum muss vor dem End-Datum liegen';
      errors.promotion_period_to = 'Das End-Datum muss nach dem Start-Datum liegen';
    }
  }

  if (!values.promotion_name) {
    errors.promotion_name = 'Bitte vergeben Sie einen Namen für die Aktion';
  }

  if (values.contract_term_from && values.contract_term_to) {
    if (Number(values.contract_term_from) > Number(values.contract_term_to)) {
      errors.contract_term_from = 'Die "Vertragslaufzeit von" muss kleiner als die "Vertragslaufzeit bis" sein';
      errors.contract_term_to = 'Die "Vertragslaufzeit von" muss kleiner als die "Vertragslaufzeit bis" sein';
    }
  }

  const purchasePriceFromNumber = values.purchase_price_from
    ? Number(values.purchase_price_from.replace('.', '').replace(',', '.')) : 0;
  const purchasePriceToNumber = values.purchase_price_to
    ? Number(values.purchase_price_to.replace('.', '').replace(',', '.').replace('unendlich', 'Infinity')) : Infinity;
  const financingValueFromNumber = values.financing_value_from
    ? Number(values.financing_value_from.replace('.', '').replace(',', '.')) : 0;
  const financingValueToNumber = values.financing_value_to
    ? Number(values.financing_value_to.replace('.', '').replace(',', '.').replace('unendlich', 'Infinity')) : Infinity;

  if (values.purchase_price_from && values.purchase_price_to) {
    if (purchasePriceFromNumber > purchasePriceToNumber) {
      errors.purchase_price_from = 'Der "Kaufpreis von" muss kleiner als der "Kaufpreis bis" sein';
      errors.purchase_price_to = 'Der "Kaufpreis von" muss kleiner als der "Kaufpreis bis" sein';
    }
  }

  if (values.financing_value_from && values.financing_value_to) {
    if (Number(values.financing_value_from) > Number(values.financing_value_to)) {
      errors.financing_value_from = 'Der "Finanzierungswert von" muss kleiner als der "Finanzierungswert bis" sein';
      errors.financing_value_to = 'Der "Finanzierungswert von" muss kleiner als der "Finanzierungswert bis" sein';
    }
  }

  if (purchasePriceFromNumber - financingValueFromNumber < 0) {
    errors.financing_value_from = 'Der "Finanzierungswert von" muss kleiner als der "Kaufpreis von" sein';
  }

  if (purchasePriceToNumber - financingValueToNumber < 0) {
    errors.financing_value_to = 'Der "Finanzierungswert von" muss kleiner als der "Kaufpreis von" sein';
  }

  if (financingValueFromNumber < 500) {
    errors.financing_value_from = 'Der "Finanzierungswert von" darf nicht kleiner 500 sein';
  }

  if (financingValueToNumber < 500) {
    errors.financing_value_to = 'Der "Finanzierungswert bis" darf nicht kleiner 500 sein';
  }

  const residualMandatory = Number(values.contract_type) === CONTRACT_TYPE_TA;
  const residualValueFrom = Number((values.residual_value_from && values.residual_value_from.replace(',', '.')) || 0);
  const residualValueTo = Number((values.residual_value_to && values.residual_value_to.replace(',', '.')) || 0);

  if (values.residual_value_from && values.residual_value_to) {
    if (residualValueFrom > residualValueTo) {
      errors.residual_value_from = 'Der "Restwert von" muss kleiner als der "Restwert bis" sein';
      errors.residual_value_to = 'Der "Restwert von" muss kleiner als der "Restwert bis" sein';
    }
  }

  if (residualMandatory && !residualValueFrom) {
    errors.residual_value_from = 'Bitte geben Sie einen Wert an.';
  }
  if (residualValueFrom && values.product_group === PRODUCT_GROUP_KFZ
    && (residualValueFrom < 10 || residualValueFrom > 70)) {
    errors.residual_value_from = 'Bitte geben Sie einen Restwert zwischen 10,00% und 70,00% ein.';
  }
  if (residualValueFrom && values.product_group !== PRODUCT_GROUP_KFZ
    && (residualValueFrom < 10 || residualValueFrom > 30)) {
    errors.residual_value_from = 'Bitte geben Sie einen Restwert zwischen 10,00% und 30,00% ein.';
  }
  if (isNaN(residualValueFrom)) {
    errors.residual_value_from = 'Bitte geben Sie nur numerische Werte.';
  }

  if (residualMandatory && !residualValueTo) {
    errors.residual_value_to = 'Bitte geben Sie einen Wert an.';
  }
  if (residualValueTo && values.product_group === PRODUCT_GROUP_KFZ
    && (residualValueTo < 10 || residualValueTo > 70)) {
    errors.residual_value_to = 'Bitte geben Sie einen Restwert zwischen 10,00% und 70,00% ein.';
  }
  if (residualValueTo && values.product_group !== PRODUCT_GROUP_KFZ
    && (residualValueTo < 10 || residualValueTo > 30)) {
    errors.residual_value_to = 'Bitte geben Sie einen Restwert zwischen 10,00% und 30,00% ein.';
  }
  if (isNaN(residualValueTo)) {
    errors.residual_value_to = 'Bitte geben Sie nur numerische Werte.';
  }

  if (values.region_from) {
    if (values.region_from.length !== 5 || isNaN(Number(values.region_from, 10))) {
      errors.region_from = 'Die PLZ muss 5 Zeichen lang sein';
    }
  }

  if (values.region_to) {
    if (values.region_to.length !== 5 || isNaN(Number(values.region_to, 10))) {
      errors.region_to = 'Die PLZ muss 5 Zeichen lang sein';
    }
  }

  if (values.region_from && values.region_to) {
    if (parseInt(values.region_from, 10) > parseInt(values.region_to, 10)) {
      errors.region_from = 'Die "Region von" muss kleiner als die "Region bis" sein';
      errors.region_to = 'Die "Region von" muss kleiner als die "Region bis" sein';
    }
  }

  const retailerArray = values.retailers ? values.retailers.split(',') : [];

  retailerArray.forEach((retailer) => {
    if (isNaN(Number(retailer.trim()))) errors.retailers = 'Die Händlernummer ist keine Zahl';
  });

  if (values.retailer_group) {
    if (values.retailer_group.length > 4) {
      errors.retailer_group = 'Die Händlergruppennummer darf maximal 4 Zeichen lang sein';
    }
    if (isNaN(Number(values.retailer_group))) {
      errors.retailer_group = 'Die Händlergruppennummer darf maximal 4 Zeichen lang sein';
    }
  }

  if (!values.interest_condition) {
    errors.interest_condition = 'Bitte tragen Sie einen Zins ein';
  }

  const insuranceParametersFields = Object.keys(values).filter((field) => field.startsWith('insurance_parameters'));
  let fieldEnding = '';
  insuranceParametersFields.forEach((field) => {
    fieldEnding = field.replace('insurance_parameters', '');
    if (!values[`factor_value${fieldEnding}`] && values[field] !== 'Versicherung') {
      errors[`factor_value${fieldEnding}`] = 'Bitte tragen Sie einen Zins ein';
    }
  });

  return errors;
};

export default validate;
