import { NAME } from './constants';

export const getError = (state) => state[NAME].error;
export const getIsCheckSessionPending = (state) => state[NAME].isCheckSessionPending;
export const getIsLoggedIn = (state) => state[NAME].isLoggedIn;
export const getIsLogoutPending = (state) => state[NAME].isLogoutPending;
export const getIsAdmin = (state) => state[NAME].role === 'admin';
export const getIsGuest = (state) => state[NAME].role === 'guest';
export const getCurrentUserEmail = (state) => state[NAME].email;
export const getUserRole = (state) => state[NAME].role;
export const getIsRegisterPending = (state) => state[NAME].isRegisterPending;
export const getRetailerGroupsLGH = (state) => state[NAME].retailerGroups
  .map((retGr) => retGr.gruppe);
export const getRetailerReferenceObj = (state) => state[NAME].retailerReferenceObj || {};
