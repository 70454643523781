import accounting from 'accounting';

const parseForm = (values) => {
  const parsedValues = {
    accept_terms: values.accept_terms === 'yes',
    contract_type: parseInt(values.contract_type, 10) || 0,
    down_payment: accounting.unformat(values.down_payment, ',') || 0,
    lease_term: values.lease_term || [],
    lessee_city: values.lessee_city || '',
    lessee_legal_form: parseInt(values.lessee_legal_form, 10) || 0,
    lessee_name: values.lessee_name || '',
    lessee_post_code: values.lessee_post_code || '',
    lessee_street: values.lessee_street || '',
    manager_first_name: values.manager_first_name,
    manager_last_name: values.manager_last_name,
    manager_salutation: parseInt(values.manager_salutation, 10),
    object_name: values.object_name || '',
    product_group: parseInt(values.product_group, 10) || 0,
    purchase_price: accounting.unformat(values.purchase_price, ',') || 0,
    residual_value_percent: (values.residual_value_percent
      && parseFloat(String(values.residual_value_percent).replace(',', '.'), 10)) || 0,
    salesman: parseInt(values.salesman, 10),
  };
  if (values.reference) parsedValues.reference = values.reference;
  return parsedValues;
};

export default parseForm;
