import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import Spinner from '../../Spinner';
import * as u from '../../../user/selectors';

function textCheck(isLastStep, isGuest) {
  let text = 'Weiter';
  if (isLastStep) {
    text = 'Erstellen und als neues Angebot speichern';
  }
  if (isGuest && isLastStep) {
    text = 'Angebot erstellen';
  }
  return text;
}

const WizardButtons = ({
  isLastStep,
  isPending,
  noRates,
  onCancel,
  onPrevious,
  pristine,
  submitting,
  isGuest,
}) => (
  <div>
    <b.Button bsSize="sm" onClick={onCancel}>Abbrechen</b.Button>
    <div style={{ float: 'right' }}>
      {onPrevious
        && (
        <b.Button bsSize="sm" onClick={onPrevious}>
          <FA name="angle-double-left icon-left" />
          Werte ändern
        </b.Button>
        )}
      {' '}
      <b.Button type="submit" bsStyle="primary" bsSize="sm" disabled={pristine || submitting || noRates}>
        {isPending && <Spinner />}
        {textCheck(isLastStep, isGuest)}
        <FA name="angle-double-right icon-right" />
      </b.Button>
    </div>
    <p className="hint-required">
      Pflichtfeld
      <span className="asterisk-required">*</span>
    </p>
  </div>
);

WizardButtons.propTypes = {
  isGuest: T.bool,
  isLastStep: T.bool,
  isPending: T.bool,
  noRates: T.bool,
  onCancel: T.func.isRequired,
  onPrevious: T.func,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

WizardButtons.defaultProps = {
  isGuest: false,
  isLastStep: false,
  isPending: false,
  noRates: false,
  onPrevious: null,
};

const mapStateToProps = (state) => ({
  isGuest: u.getIsGuest(state),
});

export default connect(mapStateToProps)(WizardButtons);
