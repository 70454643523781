import React, { Component } from 'react';
import Helmet from 'react-helmet';

class PrivacyPage extends Component {
  componentDidMount() {
    this.iframe.onload = () => {
      this.iframe.height = `${this.iframe.contentWindow.document.body.scrollHeight}px`;
    };
  }

  render() {
    return (
      <div>
        <Helmet title="Datenschutz" />
        <iframe
          title="Datenschutz"
          className="document"
          src="/privacy.html"
          sandbox="allow-scripts allow-same-origin allow-top-navigation"
          scrolling="no"
          ref={(f) => { this.iframe = f; }}
        />
      </div>
    );
  }
}

export default PrivacyPage;
