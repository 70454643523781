import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import BigSpinner from '../../BigSpinner';
import * as sel from '../../../insurance/selectors';
import * as mod from '../../../insurance/model';

const ListPageInsurances = ({
  isPending,
  insurances,
  insuranceEditActiveId,
  onShowInsuranceUpdateForm,
}) => {
  if (isPending) {
    return <BigSpinner />;
  }

  const tableHeader = () => (
    <thead>
      <tr>
        <th>ID</th>
        <th>Wert</th>
        <th>Art</th>
        <th>Standard Versicherungswert</th>
      </tr>
    </thead>
  );

  const tableRow = (insurance) => {
    const {
      id, isDeactivated, value, type, minValue,
    } = insurance;
    let classNamesLinks = isDeactivated
      ? 'list-table-cell-link-inactive'
      : 'list-table-cell-link';
    if (id === insuranceEditActiveId) classNamesLinks = `${classNamesLinks} list-table-active-element`;
    let classNamesCells = 'list-table-cell';
    if (id === insuranceEditActiveId) classNamesCells = `${classNamesCells} list-table-active-element`;

    return (
      <tr key={id} className="list-table-row">
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowInsuranceUpdateForm(id)}
          >
            <div>{`${id.slice(0, 5)}...${id.slice(31)}`}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowInsuranceUpdateForm(id)}
          >
            <div>{value}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowInsuranceUpdateForm(id)}
          >
            <div>{type}</div>
          </a>
        </td>
        <td className={classNamesCells}>
          <a
            className={classNamesLinks}
            tabIndex={-42}
            onClick={() => onShowInsuranceUpdateForm(id)}
          >
            <div>{minValue}</div>
          </a>
        </td>
      </tr>
    );
  };

  return (
    <div className="list-table">
      <Table responsive striped condensed>
        {tableHeader()}
        <tbody>{insurances.map((insurance) => tableRow(insurance))}</tbody>
      </Table>
    </div>
  );
};

ListPageInsurances.propTypes = {
  isPending: T.bool.isRequired,
  insurances: T.arrayOf(mod.insuranceSummaryShape).isRequired,
  insuranceEditActiveId: T.string.isRequired,
  onShowInsuranceUpdateForm: T.func.isRequired,
};

ListPageInsurances.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  isPending: sel.getIsListInsurancesPending(state),
  insurances: sel.listInsurances(state),
});

export default connect(mapStateToProps)(ListPageInsurances);
