import React from 'react';
import * as r from 'react-router';
import CreatePageContainer from '../components/convertOffer/create/CreatePageContainer';

const convertOfferRoutes = (
  <r.Route path="convert_offer">
    <r.Route path="create/:id" components={CreatePageContainer} />
  </r.Route>
);

export default convertOfferRoutes;
