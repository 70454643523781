export function contractRename(userRole, res) {
  if (userRole === 'lessee' || userRole === 'guest') {
    return res.data.map((contract) => {
      if (contract.description === 'VA-Leasingvertrag') return { ...contract, description: 'Leasing' };
      if (contract.description === 'TA-Vertrag') return { ...contract, description: 'Leasing mit Restwert' };
      return contract;
    });
  }
  return res.data;
}
