import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import CreateWizard from './CreateWizard';

const CreatePage = ({
  isCreatePending,
  onCancelCreateOffer,
  onCreateOffer,
}) => (
  <div>
    <Helmet title="Angebot stellen" />
    <CreateWizard
      isCreatePending={isCreatePending}
      onCancel={onCancelCreateOffer}
      onSubmit={onCreateOffer}
    />
  </div>
);

CreatePage.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancelCreateOffer: T.func.isRequired,
  onCreateOffer: T.func.isRequired,
};

export default CreatePage;
