import * as t from './actionTypes';

export const initialState = {
  error: null,
  isCheckSessionPending: false,
  isLoggedIn: false,
  isLogoutPending: false,
  role: null,
  email: null,
  isRegisterPending: false,
  retailerGroups: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case t.CHECK_SESSION_PENDING:
      return {
        ...state,
        isCheckSessionPending: true,
      };
    case t.CHECK_SESSION_FULFILLED:
      return {
        ...state,
        error: null,
        isCheckSessionPending: false,
        isLoggedIn: true,
        role: action.payload.role,
        email: action.payload.email,
        retailerGroups: action.payload.retailer_groups,
        retailerReferenceObj: {
          isReferenceFieldRequired: action.payload.retailer_reference.is_reference_field_required,
          isReferenceValueRequired: action.payload.retailer_reference.is_reference_value_required,
          referenceDescription: action.payload.retailer_reference.reference_description,
        },
      };
    case t.CHECK_SESSION_REJECTED:
      return {
        ...state,
        isCheckSessionPending: false,
        isLoggedIn: false,
        error: action.payload,
      };
    case t.LOGOUT_PENDING:
      return {
        ...state,
        isLogoutPending: true,
      };
    case t.LOGOUT_FULFILLED:
      return {
        ...state,
        error: null,
        isLoggedIn: false,
        isLogoutPending: false,
      };
    case t.LOGOUT_REJECTED:
      return {
        ...state,
        isLogoutPending: false,
        error: action.payload,
      };
    case t.REGISTER_PENDING:
      return {
        ...state,
        isRegisterPending: true,
      };
    case t.REGISTER_FULLFILLED:
      return {
        ...state,
        isRegisterPending: false,
        role: 'lessee',
        error: null,
      };
    case t.REGISTER_REJECTED:
      return {
        ...state,
        isRegisterPending: false,
        role: 'guest',
        error: action.payload,
      };
    case t.UNREGISTER:
      return {
        ...state,
        role: 'guest',
        error: null,
      };
    default:
      return state;
  }
}
