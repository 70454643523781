import * as userActions from './user/actions';
import * as userSelectors from './user/selectors';

export class AuthService {
  constructor(store) {
    this.store = store;
  }

  checkAuth() {
    return new Promise((resolve, reject) => {
      if (userSelectors.getIsLoggedIn(this.store.getState())) {
        resolve();
        return;
      }

      // if the user reloaded the window he still has a valid session but not
      // getIsLoggedIn in the store because the store is empty after reload.
      this.store.dispatch(userActions.checkSession())
        .then(() => {
          if (userSelectors.getIsLoggedIn(this.store.getState())) {
            resolve();
            return;
          }
          reject(Error('not authenticated'));
        });
    });
  }
}

export default function configureAuthService(store) {
  return new AuthService(store);
}
