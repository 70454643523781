import accounting from 'accounting';
import iban from 'iban';
import { contractTypeMapping } from '../../../utils/contractTypeMapping';

const parseForm = (values) => {
  const parsedValues = {
    accept_terms: values.accept_terms === 'yes',
    contract_type: contractTypeMapping(values.contract_type),
    down_payment: accounting.unformat(values.down_payment, ',') || 0,
    lease_term: parseInt((values.lease_term_and_rate || '').split('|')[0], 10) || 0,
    lessee_city: values.lessee_city || '',
    lessee_email: values.lessee_email || '',
    lessee_iban: values.lessee_iban ? iban.electronicFormat(values.lessee_iban) : '',
    lessee_phone: values.lessee_phone || '',
    lessee_street: values.lessee_street || '',
    object_name: values.object_name || '',
    product_group: parseInt(values.product_group, 10) || 0,
    purchase_price: accounting.unformat(values.purchase_price, ',') || 0,
    rate: parseFloat((values.lease_term_and_rate || '').split('|')[1]) || 0,
    residual_value_percent: (values.residual_value_percent
      && parseFloat(values.residual_value_percent.replace(',', '.'), 10)) || 0,
    sale_and_lease_back: values.sale_and_lease_back,
    id: values.id || '',
  };
  if (values.reference) parsedValues.reference = values.reference;
  const promotionId = (values.lease_term_and_rate || '').split('|')[2];
  if (promotionId !== 'null') parsedValues.promotion_id = promotionId;
  return parsedValues;
};

export default parseForm;
