import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import axios from 'axios';
import { LinkContainer } from 'react-router-bootstrap';
import * as m from '../../../offer/model';
import Offer from './Offer';
import { apiUrl } from '../../../axios';
import { albisSalesContactUL } from '../../../albisSalesContact';

const arrayOfIds = [];

function fetchAllCheckboxes() {
  const checkboxItems = document.querySelectorAll('#checked_ids');
  for (let i = 0; i < checkboxItems.length; i += 1) {
    if (checkboxItems[i].checked) {
      arrayOfIds.push(checkboxItems[i].dataset.id);
    }
  }
}

function asyncDeleteAlert() {
  setTimeout(() => {
    const alertElement = document.querySelector('.alert-danger');
    alertElement.parentNode.removeChild(alertElement);
  }, 3000);
}

function errorMessage() {
  const spanElement = document.createElement('div');
  spanElement.classList.add('alert-danger');
  spanElement.classList.add('alert');
  spanElement.innerHTML = '<h5>Bitte zuerst Einträge auswählen.</h5>';
  return spanElement;
}

function deleteOffers() {
  axios({
    method: 'post',
    withCredentials: true,
    url: `${apiUrl}/api/delete_offer/`,
    data: {
      array_of_ids: arrayOfIds,
    },
  }).then(() => {
    window.location = '/offers';
  });
}

function handleClick(event) {
  fetchAllCheckboxes();
  if (arrayOfIds.length !== 0) {
    deleteOffers(arrayOfIds);
  } else {
    event.preventDefault();
    const alertElement = document.querySelector('.alert-danger');
    if (alertElement == null) {
      event.target.parentNode.prepend(errorMessage());
      asyncDeleteAlert();
    }
  }
}

const ListPage = ({ offers, retailerReferenceObj, userRole }) => {
  if (userRole === 'guest') {
    return (
      <div>
        <Helmet title="Angebote" />
        <div className="alert alert-info">
          <h5>
            Um Ihre Angebote verwalten zu können, müssen Sie sich
            zunächst vollständig registrieren. Sollten Sie Hilfe benötigen,
            kontaktieren Sie bitte den ALBIS Vertrieb:
          </h5>
          {albisSalesContactUL}
        </div>
      </div>
    );
  }
  return (
    <div>
      <Helmet title="Angebote" />
      <LinkContainer to="/offers/create">
        <b.Button bsStyle="primary">
          <FA name="plus-circle icon-left" />
          Neues Angebot erstellen
        </b.Button>
      </LinkContainer>
      <b.Button
        bsStyle="primary"
        style={{ marginLeft: '10px' }}
        onClick={(event) => handleClick(event)}
      >
        <FA name="plus-circle icon-left" />
        Ausgewählte Einträge löschen
      </b.Button>
      {offers.map((app) => (
        <Offer key={app.id} app={app} retailerReferenceObj={retailerReferenceObj} />
      ))}
    </div>
  );
};

ListPage.propTypes = {
  offers: T.arrayOf(m.offerSummaryShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  userRole: T.string,
};

ListPage.defaultProps = {
  userRole: null,
};

export default ListPage;
