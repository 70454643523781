import _map from 'lodash/map';

import { NAME } from './constants';

export const getIsGetInsurancePending = (state) => !!state[NAME].isGetInsurancePending;
export const getIsListInsurancesPending = (state) => !!state[NAME].isListInsurancesPending;
export const getInsuranceParameters = (state) => Object.keys(state[NAME].insuranceSummariesById)
  .map((ins) => [ins,
    `${state[NAME].insuranceSummariesById[ins].value} ${state[NAME].insuranceSummariesById[ins].type}`])
  || [];
export const listInsurances = (state) => _map(state[NAME].insurances,
  (id) => state[NAME].insuranceSummariesById[id]);
export const listInsurancesForSelectField = (state) => _map(state[NAME].insurances,
  (id) => state[NAME].insuranceSummariesById[id])
  .filter((ins) => !ins.isDeactivated)
  .map((ins) => {
    if (ins.type === '%') {
      return [`${ins.id}~${ins.value}~${ins.type}~${ins.minValue}~${ins.isDeactivated}`, `${ins.value} ${ins.type} ${ins.minValue}`];
    }
    return [`${ins.id}~${ins.value}~${ins.type}~${ins.isDeactivated}`, `${ins.value} ${ins.type}`];
  });
export const getIsCreateInsurancePending = (state) => !!state[NAME].isCreateInsurancePending;
export const getInsurance = (id) => (state) => state[NAME].insuranceSummariesById[id];
export const getInitialValuesForUpdateForm = (id) => (state) => {
  const insurance = state[NAME].insuranceSummariesById[id];

  const initialValues = {
    id,
    value: insurance.value.toString(),
    type: insurance.type.toString(),
    is_deactivated: insurance.isDeactivated,
    is_initial_deactivated: insurance.isDeactivated,
    is_deactivation_accepted: false,
  };
  if (insurance.type.toString() === '%') {
    initialValues.min_value = insurance.minValue.toString();
  }
  return initialValues;
};
