import accounting from 'accounting';
import moment from 'moment';
import iban from 'iban';
import { MAGIC_NEW_RETAILER_ID } from '../../../leasingApplication/constants';
import { contractTypeMapping } from '../../../utils/contractTypeMapping';

const parseForm = (values) => {
  const parsedValues = {
    accept_terms: values.accept_terms === 'yes',
    contract_type: contractTypeMapping(values.contract_type),
    down_payment: accounting.unformat(values.down_payment, ',') || 0,
    lease_term: parseInt((values.lease_term_and_rate || '').split('|')[0], 10) || 0,
    lessee_city: values.lessee_city || '',
    lessee_email: values.lessee_email || '',
    lessee_iban: values.lessee_iban ? iban.electronicFormat(values.lessee_iban) : '',
    lessee_legal_form: parseInt(values.lessee_legal_form, 10) || 0,
    lessee_name: values.lessee_name || '',
    password: values.password || null,
    password_confirm: values.password_confirm || null,
    lessee_phone: values.lessee_phone || '',
    lessee_post_code: values.lessee_post_code || '',
    lessee_street: values.lessee_street || '',
    manager_birth_date: values.manager_birth_date ? moment(values.manager_birth_date, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
    manager_city: values.manager_city,
    manager_first_name: values.manager_first_name,
    manager_last_name: values.manager_last_name,
    manager_notes: values.manager_notes,
    manager_post_code: values.manager_post_code,
    manager_salutation: parseInt(values.manager_salutation, 10),
    manager_street: values.manager_street,
    object_name: values.object_name || '',
    product_group: parseInt(values.product_group, 10) || 0,
    purchase_price: accounting.unformat(values.purchase_price, ',') || 0,
    rate: parseFloat((values.lease_term_and_rate || '').split('|')[1]) || 0,
    residual_value_percent: (values.residual_value_percent
      && parseFloat(values.residual_value_percent.replace(',', '.'), 10)) || 0,
    retailer_id: ((values.retailer_id === MAGIC_NEW_RETAILER_ID || values.sale_and_lease_back)
      ? null : (parseInt(values.retailer_id, 10) || null)),
    retailer_name: (values.sale_and_lease_back ? (values.lessee_name) : (values.retailer_name)),
    retailer_phone: (values.sale_and_lease_back ? (values.lessee_phone) : (values.retailer_phone)),
    retailer_email: (values.sale_and_lease_back ? (values.lessee_email) : (values.retailer_email)),
    retailer_post_code: (values.sale_and_lease_back ? (values.lessee_post_code)
      : (values.retailer_post_code)),
    retailer_street: (values.sale_and_lease_back
      ? (values.lessee_street) : (values.retailer_street)),
    retailer_city: (values.sale_and_lease_back ? (values.lessee_city) : (values.retailer_city)),
    retailer_branch: parseInt(values.retailer_branch, 10),
    salesman: parseInt(values.salesman, 10),
    sale_and_lease_back: values.sale_and_lease_back,
    user_role: values.user_role,
  };
  if (values.reference) parsedValues.reference = values.reference;
  const promotionId = (values.lease_term_and_rate || '').split('|')[2];
  if (promotionId !== 'null') parsedValues.promotion_id = promotionId;
  return parsedValues;
};

export default parseForm;
