import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import LeaseTermsAndRatesField from './LeaseTermsAndRatesField';
import ViewField from '../../ViewField';
import Spinner from '../../Spinner';
import formName from './formName';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import validate from './validate';
import { formatMoney, formatPercent } from '../../formatters';
import * as m from '../../../leasingApplication/model';

const checkStepsClickHandlers = (
  objectData,
  onStepObjectData,
  onStepRetailer,
) => {
  if (objectData.sale_and_lease_back) {
    return [onStepObjectData];
  }
  return [onStepObjectData, onStepRetailer];
};

const CreateWizardStepRateForm = ({
  error,
  handleSubmit,
  isComputeRatesPending,
  lookupContractType,
  lookupProductGroup,
  monthOfCancellation,
  objectData,
  onCancel,
  onPrevious,
  onStepObjectData,
  onStepRetailer,
  pristine,
  rates,
  residualValue,
  retailerReferenceObj,
  showFinalPayment,
  showResidualValue,
  submitting,
}) => (
  <b.Row>
    <b.Col sm={12} lg={3}>
      <WizardNavigation
        current={3}
        onClickHandlers={checkStepsClickHandlers(objectData,
          onStepObjectData, onStepRetailer)}
      />
    </b.Col>
    <b.Col sm={12} lg={9}>
      <b.Form onSubmit={handleSubmit}>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        <ViewField
          title="Kaufpreis"
          value={formatMoney(objectData.purchase_price)}
        />
        <ViewField
          title="Mietsonderzahlung"
          value={formatMoney(objectData.down_payment)}
        />
        <ViewField
          title="Leasingobjekt (Klasse)"
          value={lookupProductGroup(objectData.product_group)}
        />
        <ViewField
          title="Leasingobjekt (Details)"
          value={objectData.object_name}
        />
        <ViewField
          title="Vertragsart"
          value={lookupContractType(objectData.contract_type)}
        />
        {showResidualValue
          && (
          <ViewField
            title="Restwert"
            value={formatPercent(objectData.residual_value_percent)}
          />
          )}
        {retailerReferenceObj.isReferenceFieldRequired
          && (
          <ViewField
            title={retailerReferenceObj.referenceDescription}
            value={objectData.reference}
          />
          )}
        <hr />
        {isComputeRatesPending
          ? <Spinner />
          : (
            <Field
              component={LeaseTermsAndRatesField}
              monthOfCancellation={monthOfCancellation}
              name="lease_term_and_rate"
              rates={rates}
              residualValue={residualValue}
              required
              showFinalPayment={showFinalPayment}
              showResidualValue={showResidualValue}
              title="Laufzeit und Rate (netto)"
            />
          )}
        <WizardButtons
          noRates={rates.length === 0}
          onCancel={onCancel}
          onPrevious={onPrevious}
          pristine={pristine}
          submitting={submitting}
        />
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepRateForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  isComputeRatesPending: T.bool.isRequired,
  lookupContractType: T.func.isRequired,
  lookupProductGroup: T.func.isRequired,
  monthOfCancellation: T.number.isRequired,
  objectData: T.shape({
    contract_type: T.number.isRequired,
    down_payment: T.number.isRequired,
    reference: T.string,
    object_name: T.string.isRequired,
    product_group: T.number.isRequired,
    purchase_price: T.number.isRequired,
    sale_and_lease_back: T.bool,
    residual_value_percent: T.number,
  }).isRequired,
  onCancel: T.func.isRequired,
  onPrevious: T.func.isRequired,
  onStepObjectData: T.func.isRequired,
  onStepRetailer: T.func.isRequired,
  pristine: T.bool.isRequired,
  rates: T.arrayOf(m.rateShape).isRequired,
  residualValue: T.number.isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  showFinalPayment: T.bool.isRequired,
  showResidualValue: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepRateForm.defaultProps = {
  error: null,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepRateForm);
