import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import CreateWizard from './CreateWizard';

const CreatePage = ({
  id,
  isUpdatePending,
  onCancelUpdatePromotion,
  onUpdatePromotion,
}) => (
  <div>
    <Helmet title="Promotion update" />
    <CreateWizard
      isUpdatePending={isUpdatePending}
      onCancel={onCancelUpdatePromotion}
      onSubmit={onUpdatePromotion}
      id={id}
    />
  </div>
);

CreatePage.propTypes = {
  id: T.string.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancelUpdatePromotion: T.func.isRequired,
  onUpdatePromotion: T.func.isRequired,
};

export default CreatePage;
