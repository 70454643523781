import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import redirect from '../../../redirect';
import formName from './formName';
import parseForm from './parseForm';
import CreatePage from './CreatePage';
import * as a from '../../../changeApplication/actions';
import * as s from '../../../changeApplication/selectors';
import * as b from '../../../salesman/actions';
import * as u from '../../../user/selectors';
import handleValidationErrors from '../../../handleValidationErrors';

class CreatePageContainer extends Component {
  componentWillMount() {
    const { dispatch, id, userRole } = this.props;
    dispatch(a.getContractTypes(userRole));
    dispatch(a.getProductGroups());
    dispatch(a.getLegalForms());
    dispatch(a.getRetailers());
    dispatch(a.listLeasingApplications());
    dispatch(b.listSalesmen());
    dispatch(a.getApplication(id));
  }

  render() {
    const {
      isCreatePending,
      onCancelCreateApplication,
      onCreateApplication,
      id,
    } = this.props;
    return (
      <CreatePage
        isCreatePending={isCreatePending}
        onCancelCreateApplication={onCancelCreateApplication}
        onCreateApplication={onCreateApplication}
        params={id}

      />
    );
  }
}

CreatePageContainer.propTypes = {
  dispatch: T.func.isRequired,
  isCreatePending: T.bool.isRequired,
  onCancelCreateApplication: T.func.isRequired,
  onCreateApplication: T.func.isRequired,
  id: T.number.isRequired,
  userRole: T.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.params.id, 10);
  return {
    id,
    isCreatePending: s.getIsCreateLeasingApplicationPending(state),
    userRole: u.getUserRole(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onCancelCreateApplication() {
    dispatch(destroy(formName));
    redirect.toPathname('/applications');
  },
  onCreateApplication(formValues) {
    const parsedValues = parseForm(formValues);
    return dispatch(a.createLeasingApplication(parsedValues))
      .then((id) => {
        dispatch(destroy(formName));
        redirect.toPathname(`/applications/${id}`);
      })
      .catch((err) => handleValidationErrors(err, 'Das Anlegen des Antrags ist fehlgeschlagen'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePageContainer);
