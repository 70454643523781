/* global authService */
import { apiUrl } from '../axios';

function beforeFilters() {
  return {
    requireAuth(nextState, replace, callback) {
      authService
        .checkAuth()
        .then(() => callback())
        .catch(() => {
          window.location = `${apiUrl}/login`;
        });
    },
  };
}

export default beforeFilters;
