import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router';

const Footer = () => (
  <div className="footer">
    <Grid>
      <Row>
        <Col sm={12}>
          <ul>
            {window === window.top && (<li><a href="http://www.lgh-leasing.de" target="_blank" rel="noopener noreferrer">EDEKA Leasing</a></li>)}
            <li><Link to="/contact">Kontakt</Link></li>
            {window === window.top && (<li><Link to="/imprint">Impressum</Link></li>)}
            <li><a href="https://www.albis-leasing.de/datenschutz" target="_blank" rel="noreferrer">Datenschutz</a></li>
            <li><Link to="/terms">Nutzungsbedingungen</Link></li>
          </ul>
        </Col>
      </Row>
    </Grid>

  </div>
);

export default Footer;
