import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _mapValues from 'lodash/mapValues';
import * as t from './actionTypes';

export const initialState = {
  contractTypes: null,
  error: null,
  isComputeRatesPending: false,
  isCreateLeasingApplicationPending: false,
  isGetContractTypesPending: false,
  isGetLeasingApplicationPending: false,
  isGetLegalFormsPending: false,
  isGetProductGroupsPending: false,
  isGetRetailerBranchesPending: false,
  isGetRetailersPending: false,
  isListLeasingApplicationsPending: false,
  leasingApplications: [],
  leasingApplicationsById: {},
  leasingApplicationSummariesById: {},
  legalForms: null,
  monthOfCancellation: null,
  productGroups: null,
  rates: null,
  residualValue: null,
  retailer: null,
  retailerBranches: null,
  retailers: null,
  isGetOfferPending: false,
  offersById: {},
  offerSummariesById: {},

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case t.CLEAR_RATES:
      return {
        ...state,
        monthOfCancellation: null,
        rates: null,
      };
    case t.COMPUTE_RATES_FULFILLED:
      return {
        ...state,
        error: null,
        isComputeRatesPending: false,
        monthOfCancellation: action.payload.month_of_cancellation,
        rates: action.payload.rates,
        residualValue: action.payload.residual_value,
      };
    case t.COMPUTE_RATES_PENDING:
      return {
        ...state,
        isComputeRatesPending: true,
      };
    case t.COMPUTE_RATES_REJECTED:
      return {
        ...state,
        isComputeRatesPending: false,
        error: action.payload,
      };
    case t.CREATE_LEASING_APPLICATION_FULFILLED:
      return {
        ...state,
        error: null,
        isCreateLeasingApplicationPending: false,
      };
    case t.CREATE_LEASING_APPLICATION_PENDING:
      return {
        ...state,
        isCreateLeasingApplicationPending: true,
      };
    case t.CREATE_LEASING_APPLICATION_REJECTED:
      return {
        ...state,
        isCreateLeasingApplicationPending: false,
        error: action.payload,
      };
    case t.GET_CONTRACT_TYPES_FULFILLED:
      return {
        ...state,
        error: null,
        isGetContractTypesPending: false,
        contractTypes: action.payload,
      };
    case t.GET_CONTRACT_TYPES_PENDING:
      return {
        ...state,
        isGetContractTypesPending: true,
      };
    case t.GET_CONTRACT_TYPES_REJECTED:
      return {
        ...state,
        isGetContractTypesPending: false,
        error: action.payload,
      };
    case t.GET_LEASING_APPLICATION_FULFILLED:
      return {
        ...state,
        error: null,
        isGetLeasingApplicationPending: false,
        leasingApplicationsById: {
          ...state.leasingApplicationsById,
          [action.payload.id]: action.payload,
        },
      };
    case t.GET_LEASING_APPLICATION_PENDING:
      return {
        ...state,
        isGetLeasingApplicationPending: true,
      };
    case t.GET_LEASING_APPLICATION_REJECTED:
      return {
        ...state,
        isGetLeasingApplicationPending: false,
        error: action.payload,
      };
    case t.GET_LEGAL_FORMS_FULFILLED:
      return {
        ...state,
        error: null,
        isGetLegalFormsPending: false,
        legalForms: action.payload,
      };
    case t.GET_LEGAL_FORMS_PENDING:
      return {
        ...state,
        isGetLegalFormsPending: true,
      };
    case t.GET_LEGAL_FORMS_REJECTED:
      return {
        ...state,
        isGetLegalFormsPending: false,
        error: action.payload,
      };
    case t.GET_PRODUCT_GROUPS_FULFILLED:
      return {
        ...state,
        error: null,
        isGetProductGroupsPending: false,
        productGroups: action.payload,
      };
    case t.GET_PRODUCT_GROUPS_PENDING:
      return {
        ...state,
        isGetProductGroupsPending: true,
      };
    case t.GET_PRODUCT_GROUPS_REJECTED:
      return {
        ...state,
        isGetProductGroupsPending: false,
        error: action.payload,
      };
    case t.GET_RETAILER:
      return {
        ...state,
        retailer: state.retailers ? _find(state.retailers, { id: action.payload }) : null,
      };
    case t.GET_RETAILER_BRANCHES_FULFILLED:
      return {
        ...state,
        error: null,
        isGetRetailerBranchesPending: false,
        retailerBranches: action.payload,
      };
    case t.GET_RETAILER_BRANCHES_PENDING:
      return {
        ...state,
        isGetRetailerBranchesPending: true,
      };
    case t.GET_RETAILER_BRANCHES_REJECTED:
      return {
        ...state,
        isGetRetailerBranchesPending: false,
        error: action.payload,
      };
    case t.GET_RETAILERS_FULFILLED:
      return {
        ...state,
        error: null,
        isGetRetailersPending: false,
        retailers: action.payload,
      };
    case t.GET_RETAILERS_PENDING:
      return {
        ...state,
        isGetRetailersPending: true,
      };
    case t.GET_RETAILERS_REJECTED:
      return {
        ...state,
        isGetRetailersPending: false,
        error: action.payload,
      };
    case t.LIST_LEASING_APPLICATIONS_FULFILLED:
      return {
        ...state,
        error: null,
        isListLeasingApplicationsPending: false,
        leasingApplications: _map(action.payload, (app) => app.id),
        leasingApplicationSummariesById: _mapValues(
          _groupBy(action.payload, (app) => app.id),
          (array) => array[0],
        ),
      };
    case t.LIST_LEASING_APPLICATIONS_PENDING:
      return {
        ...state,
        isListLeasingApplicationsPending: true,
      };
    case t.LIST_LEASING_APPLICATIONS_REJECTED:
      return {
        ...state,
        isListLeasingApplicationsPending: false,
        error: action.payload,
      };
    case t.GET_OFFER_FULFILLED:
      return {
        ...state,
        error: null,
        isGetOfferPending: false,
        offersById: {
          ...state.offersById,
          [action.payload.id]: action.payload,
        },
      };
    case t.GET_OFFER_PENDING:
      return {
        ...state,
        isGetOfferPending: true,
      };
    case t.GET_OFFER_REJECTED:
      return {
        ...state,
        isGetOfferPending: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
