import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import * as a from '../../../leasingApplication/actions';
import * as s from '../../../leasingApplication/selectors';
import * as m from '../../../leasingApplication/model';
import * as u from '../../../user/selectors';
import BigSpinner from '../../BigSpinner';
import DetailsPage from './DetailsPage';

class DetailsPageContainer extends Component {
  componentWillMount() {
    const { dispatch, id } = this.props;
    dispatch(a.getLeasingApplication(id));
    dispatch(a.getContractTypes());
    dispatch(a.getProductGroups());
    dispatch(a.getRetailers());
  }

  render() {
    const {
      contractTypes,
      isPending,
      leasingApplication,
      productGroups,
      retailers,
      retailerReferenceObj,
      userRole,
    } = this.props;
    if (isPending) {
      return <BigSpinner />;
    }
    if (!leasingApplication) {
      return <div>Leasing-Antrag nicht gefunden</div>;
    }
    return (
      <DetailsPage
        contractTypes={contractTypes}
        leasingApplication={leasingApplication}
        productGroups={productGroups}
        retailers={retailers}
        retailerReferenceObj={retailerReferenceObj}
        userRole={userRole}
      />
    );
  }
}

DetailsPageContainer.propTypes = {
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  dispatch: T.func.isRequired,
  id: T.number.isRequired,
  isPending: T.bool.isRequired,
  leasingApplication: m.leasingApplicationShape,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
  retailers: T.arrayOf(m.retailerShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  userRole: T.string,
};

DetailsPageContainer.defaultProps = {
  leasingApplication: undefined,
  userRole: false,
};

const mapStateToProps = (state, ownProps) => ({
  contractTypes: s.getContractTypes(state),
  id: parseInt(ownProps.params.id, 10),
  isPending: s.getIsGetLeasingApplicationPending(state),
  leasingApplication: s.getLeasingApplication(parseInt(ownProps.params.id, 10))(state),
  productGroups: s.getProductGroups(state),
  retailers: s.getRetailers(state),
  retailerReferenceObj: u.getRetailerReferenceObj(state),
  userRole: u.getUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPageContainer);
