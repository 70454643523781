import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import moment from 'moment';
import * as b from 'react-bootstrap';
import formName from './formName';
import WizardButtons from './WizardButtons';
import validate from './validate';
import * as s from '../../../promotion/selectorsUpdateForm';

const CreateWizardStepSummary = ({
  columnsArray,
  condition,
  error,
  formValues,
  handleSubmit,
  onCancel,
  onPrevious,
  onSubmit,
  pristine,
  rowsArray,
  submitting,
  showResidualValue,
}) => (
  <b.Form>
    {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
    <h4 className="pb-2">Aktion aktualisieren - Zusammenfassung</h4>
    <div className="pb-3">
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Aktionsname:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.promotion_name}</p>
        </b.Col>
      </b.Row>
      {
      // <b.Row>
      //   <b.Col sm={6} lg={3}>
      //     <b>Laufzeit:</b>
      //   </b.Col>
      //   <b.Col sm={3} lg={3}>
      //     <p>von: {moment(formValues.promotion_period_from).format('DD-MM-YYYY')}</p>
      //   </b.Col>
      //   <b.Col sm={3} lg={3}>
      //     <p>bis: {moment(formValues.promotion_period_to).format('DD-MM-YYYY')}</p>
      //   </b.Col>
      // </b.Row>
      }
      <b.Row className={!formValues.promotion_description && 'empty-row'}>
        <b.Col sm={6} lg={3}>
          <b>Aktionsbeschreibung:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.promotion_description}</p>
        </b.Col>
      </b.Row>
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Vertragslaufzeit:</b>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            von:
            {formValues.contract_term_from}
          </p>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            bis:
            {formValues.contract_term_to}
          </p>
        </b.Col>
      </b.Row>
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Kaufpreis:</b>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            von:
            {formValues.purchase_price_from}
          </p>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            bis:
            {formValues.purchase_price_to}
          </p>
        </b.Col>
      </b.Row>
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Finanzierungswert:</b>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            von:
            {formValues.financing_value_from}
          </p>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            bis:
            {formValues.financing_value_to}
          </p>
        </b.Col>
      </b.Row>
      <b.Row className={!formValues.contract_type && 'empty-row'}>
        <b.Col sm={6} lg={3}>
          <b>Vertragsart:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.contract_type}</p>
        </b.Col>
      </b.Row>
      {showResidualValue && (
        <b.Row>
          <b.Col sm={6} lg={3}>
            <b>Restwert:</b>
          </b.Col>
          <b.Col sm={3} lg={3}>
            <p>
              von:
              {formValues.residual_value_from}
            </p>
          </b.Col>
          <b.Col sm={3} lg={3}>
            <p>
              bis:
              {formValues.residual_value_to}
            </p>
          </b.Col>
        </b.Row>
      )}
      <b.Row className={!formValues.product_group && 'empty-row'}>
        <b.Col sm={6} lg={3}>
          <b>Produktgruppe:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.product_group}</p>
        </b.Col>
      </b.Row>
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Region:</b>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            von:
            {formValues.region_from}
          </p>
        </b.Col>
        <b.Col sm={3} lg={3}>
          <p>
            bis:
            {formValues.region_to}
          </p>
        </b.Col>
      </b.Row>
      <b.Row className={!formValues.retailers && 'empty-row'}>
        <b.Col sm={6} lg={3}>
          <b>Händler:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.retailers}</p>
        </b.Col>
      </b.Row>
      <b.Row className={!formValues.retailer_group && 'empty-row'}>
        <b.Col sm={6} lg={3}>
          <b>Händlergruppe:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.retailer_group}</p>
        </b.Col>
      </b.Row>
      <hr />
      <b.Row>
        <b.Col sm={6} lg={3}>
          <h5><b>Kondition:</b></h5>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>
            {condition === 'interest' || (!condition && formValues.insurance_parameters)
              ? 'Einzelkondition' : 'Konditionsstaffel'}
          </p>
        </b.Col>
      </b.Row>
      {condition === 'interest' || (!condition && formValues.insurance_parameters) ? (
        <div>
          <b.Row>
            <b.Col sm={6} lg={3}>
              <b>Einzelkondition:</b>
            </b.Col>
            <b.Col sm={6} lg={3}>
              <p>
                {formValues.interest_condition}
                {' '}
                %
              </p>
            </b.Col>
          </b.Row>
          <b.Row>
            <b.Col sm={6} lg={3}>
              <b>Versicherung:</b>
            </b.Col>
            <b.Col sm={6} lg={3}>
              <p>
                {!formValues.insurance_parameters
                || formValues.insurance_parameters.endsWith('true')
                || formValues.insurance_parameters === 'Versicherung'
                  ? 'Standard'
                  : formValues.insurance_parameters.substring(
                    formValues.insurance_parameters.indexOf('~'),
                  ).replace(/~|true|false/g, ' ')}
              </p>
            </b.Col>
          </b.Row>
        </div>
      ) : (
        <div className="pt-3">
          <b.Table striped bordered condensed>
            <thead>
              <tr>
                <th key="header-terms/purchasePrice" className="text-center pb-2">
                  {`Vertragslaufzeit
                  /Kaufpreis`}
                </th>
                {columnsArray.map((col) => (
                  <th key={`header-${col}`} className="text-center pb-2">
                    {col}
                    {' '}
                    €
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsArray.map(
                (row) => (
                  row !== 'last' && (
                  <tr key={row} className="list-table-row text-center">
                    <td key={`titleRow-${row}`} className="pt-4">{row}</td>
                    {columnsArray.map((col) => (
                      <td key={`${row} - ${col}`} className="pt-3 pb-3">
                        <b.Row>
                          <b.Col sm={6} className="pr-1">
                            <p>
                              Zins:
                              {
                              formValues[`factor_value-row${row}-col${col}`]
                                ? `${formValues[`factor_value-row${row}-col${col}`]} %`
                                : '-'
                            }
                            </p>
                          </b.Col>
                          <b.Col sm={6} className="pl-1">
                            <p>
                              Vers.:
                              {
                              !formValues[`insurance_parameters-row${row}-col${col}`]
                              || formValues[`insurance_parameters-row${row}-col${col}`].endsWith('true')
                              || formValues[`insurance_parameters-row${row}-col${col}`] === 'Versicherung'
                                ? 'Standard'
                                : formValues[`insurance_parameters-row${row}-col${col}`].substring(
                                  formValues[`insurance_parameters-row${row}-col${col}`].indexOf('~'),
                                ).replace(/~|true|false/g, ' ')
                            }
                            </p>
                          </b.Col>
                        </b.Row>
                      </td>
                    ))}
                  </tr>
                  )
                ),
              )}
            </tbody>
          </b.Table>
        </div>
      )}
    </div>
    <WizardButtons
      handleSubmit={handleSubmit}
      onCancel={onCancel}
      onPrevious={onPrevious}
      onSubmit={onSubmit}
      pristine={pristine}
      submitting={submitting}
      isSummaryStep
    />
  </b.Form>
);

const mapStateToProps = (state) => ({
  formValues: s.getValues(state),
  columnsArray: s.getColumnsArray(state),
  rowsArray: s.getRowsArray(state),
});

CreateWizardStepSummary.propTypes = {
  columnsArray: T.arrayOf(T.number).isRequired,
  condition: T.string.isRequired,
  error: T.string,
  formValues: T.any.isRequired,
  handleSubmit: T.func.isRequired,
  onCancel: T.func.isRequired,
  onPrevious: T.func.isRequired,
  onSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  rowsArray: T.arrayOf(T.string).isRequired,
  submitting: T.bool.isRequired,
  showResidualValue: T.bool.isRequired,
};

CreateWizardStepSummary.defaultProps = {
  error: null,
};

export default connect(
  mapStateToProps,
  undefined,
)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepSummary));
