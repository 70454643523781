import { PropTypes as T } from 'prop-types';

export const insuranceSummaryPropTypes = {
  id: T.string.isRequired,
  value: T.number.isRequired,
  type: T.string.isRequired,
  minValue: T.number,
  isDeactivated: T.bool.isRequired,
};

export const insuranceSummaryShape = T.shape(insuranceSummaryPropTypes);

export const insuranceCreateFormPropTypes = {
  value: T.string.isRequired,
  type: T.string.isRequired,
  min_value: T.number,
  is_deactivated: T.bool.isRequired,
};

export const insuranceCreateFormShape = T.shape(insuranceCreateFormPropTypes);
