import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FA from 'react-fontawesome';
import * as m from '../../../offer/model';
import { formatMoney } from '../../formatters';
import { apiUrl } from '../../../axios';

const labelAndValue = (label, value) => (
  <b.Row>
    <b.Col xs={3} sm={2} md={2}>
      {label}
      :
    </b.Col>
    <b.Col xs={9} sm={10} md={10}>
      {value}
    </b.Col>
  </b.Row>
);

const Offer = ({ app, retailerReferenceObj }) => (
  <b.Row>
    <b.Col xs={12}>
      <div className="leasing-application">
        <b.Row>
          <b.Col xs={12} sm={1}>
            <input type="Checkbox" data-id={app.id} id="checked_ids" />
          </b.Col>
          <b.Col xs={12} sm={8}>
            <div className="label-value-wrapper">
              {labelAndValue('Angebot', app.id)}
              {labelAndValue('Datum', app.received_date)}
              {labelAndValue('Firmenname', app.lessee_name)}
              {labelAndValue('Preis', formatMoney(app.purchase_price))}
              {labelAndValue('Vorname', app.manager_first_name)}
              {labelAndValue('Nachname', app.manager_last_name)}
              {labelAndValue('PLZ', app.lessee_postal_code)}
              {labelAndValue('Strasse', app.lessee_street)}
              {labelAndValue('Ort', app.lessee_city)}
              {retailerReferenceObj.isReferenceFieldRequired
                && labelAndValue(retailerReferenceObj.referenceDescription, app.reference)}
            </div>
          </b.Col>
          <b.Col xs={12} sm={3}>
            <div className="label-value-btn-position">
              <b.Button
                bsStyle="default"
                href={`${apiUrl}/api/download_offer_pdf/${app.id}`}
                target="_blank"
                style={{ marginBottom: '10px' }}
              >
                <FA name="print icon-left" />
                Angebot drucken
              </b.Button>

              <LinkContainer to={`/change_offer/edit/${app.id}`}>
                <b.Button bsStyle="primary" style={{ marginBottom: '10px' }}>
                  <FA name="plus-circle icon-left" />
                  Angebot ändern
                </b.Button>
              </LinkContainer>

              <LinkContainer to={`/convert_offer/create/${app.id}`}>
                <b.Button bsStyle="primary">
                  <FA name="plus-circle icon-left" />
                  Antrag erstellen
                </b.Button>
              </LinkContainer>
            </div>
          </b.Col>
        </b.Row>
      </div>
    </b.Col>
  </b.Row>
);

Offer.propTypes = {
  app: m.offerSummaryShape.isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
};

export default Offer;
