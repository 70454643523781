import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import formName from './formName';
import BigSpinner from '../../BigSpinner';
import parseForm from './parseForm';
import * as actIns from '../../../insurance/actions';
import * as actPromo from '../../../promotion/actions';
import * as s from '../../../insurance/selectors';
import CreatePage from './CreatePage';

import handleValidationErrors from '../../../handleValidationErrors';

const CreatePageContainer = ({
  isGetInsurancePending,
  isUpdatePending,
  onCancelUpdateInsurance,
  onDestroyForm,
  onUpdateInsurance,
  showInsuranceUpdateFormForInsuranceId,
  onShowInsuranceUpdateForm,
}) => {
  if (isGetInsurancePending) {
    return <BigSpinner />;
  }
  return (
    <CreatePage
      id={showInsuranceUpdateFormForInsuranceId}
      isUpdatePending={isUpdatePending}
      onCancelUpdateInsurance={() => onCancelUpdateInsurance(onDestroyForm)}
      onUpdateInsurance={(formValues) => onUpdateInsurance(formValues, onShowInsuranceUpdateForm)}
    />
  );
};

CreatePageContainer.propTypes = {
  isGetInsurancePending: T.bool.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancelUpdateInsurance: T.func.isRequired,
  onDestroyForm: T.func.isRequired,
  onShowInsuranceUpdateForm: T.func.isRequired,
  onUpdateInsurance: T.func.isRequired,
  showInsuranceUpdateFormForInsuranceId: T.string.isRequired,
};

const mapStateToProps = (state) => ({
  isGetInsurancePending: s.getIsGetInsurancePending(state),
  isUpdatePending: s.getIsCreateInsurancePending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onCancelUpdateInsurance(onDestroyForm) {
    dispatch(destroy(formName));
    onDestroyForm();
  },
  onUpdateInsurance(formValues, onShowInsuranceUpdateForm) {
    const parsedValues = parseForm(formValues);
    return dispatch(actIns.updateInsurance(parsedValues))
      .then(() => {
        onShowInsuranceUpdateForm();
        dispatch(destroy(formName));
        dispatch(actIns.listInsurances());
        dispatch(actPromo.listPromotions());
      })
      .catch((err) => handleValidationErrors(
        err,
        'Das Anlegen der Versicherung ist fehlgeschlagen',
      ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageContainer);
