import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change, untouch } from 'redux-form';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import _some from 'lodash/some';
import CreateWizardStepAddressesForm from './CreateWizardStepAddressesForm';
import CreateWizardStepObjectDataForm from './CreateWizardStepObjectDataForm';
import CreateWizardStepRateForm from './CreateWizardStepRateForm';
import CreateWizardStepRetailerForm from './CreateWizardStepRetailerForm';
import * as a from '../../../changeApplication/actions';
import * as m from '../../../changeApplication/model';
import * as o from '../../../salesman/model';
import * as s from '../../../changeApplication/selectors';
import * as u from '../../../user/selectors';
import * as salesmanSelector from '../../../salesman/selectors';
import {
  CONTRACT_TYPE_TA, CONTRACT_TYPE_VA, MAGIC_NEW_RETAILER_ID, APPROVED_APPLICATION, APPROVED_APPLICATION_BY_FINANCIAL_DECISION, CANCELLED_DECISION,
} from '../../../changeApplication/constants';
import formName from './formName';
import parseForm from './parseForm';
import BigSpinner from '../../BigSpinner';
import { formatDate } from '../../formatters';

const STEP_ADRESSES = 'ADRESSES';
const STEP_OBJECT = 'OBJECT';
const STEP_RATE = 'RATE';
const STEP_RETAILER = 'RETAILER';

class CreateWizard extends Component {
  constructor(props) {
    super(props);
    this.state = { step: STEP_OBJECT };
  }

  componentDidUpdate(prevProps) {
    const { retailerId, dispatch } = this.props;
    if (retailerId !== prevProps.retailerId) {
      dispatch(a.getRetailer(parseInt(retailerId, 10)));
    }
  }

  render() {
    const {
      isAdmin,
      application,
      contractTypes,
      dispatch,
      isComputeRatesPending,
      isSaleAndLeaseBack,
      checkApplication,
      isCreatePending,
      isGetContractTypesPending,
      isGetLegalFormsPending,
      isGetProductGroupsPending,
      isGetRetailersPending,
      leasingApplications,
      listSalesmen,
      legalForms,
      monthOfCancellation,
      objectData,
      checkApplicationData,
      onCancel,
      onSubmit,
      productGroups,
      rates,
      residualValue,
      retailer,
      retailers,
      retailerId,
      retailerGroupsLGH,
      retailerReferenceObj,
      paramsId,
      userRole,
    } = this.props;

    const lookupContractType = (id) => _find(contractTypes, (v) => v.id === id).description;
    const lookupProductGroup = (id) => _find(productGroups, (v) => v.id === id).description;
    const onStepObjectData = () => {
      dispatch(a.getApplication(paramsId));
      dispatch(change(formName, 'lease_term_and_rate', ''));
      dispatch(untouch(formName, 'lease_term_and_rate'));
      this.setState({ step: STEP_OBJECT });
    };

    const onStepRate = () => {
      dispatch(change(formName, 'sale_and_lease_back', undefined));
      if (isSaleAndLeaseBack) dispatch(change(formName, 'sale_and_lease_back', true));
      if (!objectData.object_name) {
        const desc = lookupProductGroup(objectData.product_group);
        dispatch(change(formName, 'object_name', desc));
      }
      dispatch(a.clearRates());
      const rateProps = {
        ..._pick(objectData, [
          'contract_type',
          'down_payment',
          'product_group',
          'purchase_price',
          'residual_value_percent',
        ]),
        retailer_groups: retailerGroupsLGH,
      };
      if (!isSaleAndLeaseBack && retailer) {
        rateProps.region = retailer.post_code;
        rateProps.retailer = retailer.id;
        rateProps.retailer_groups = retailer.retailer_groups.concat(retailerGroupsLGH);
      }
      if (retailerId === MAGIC_NEW_RETAILER_ID) {
        rateProps.region = objectData.retailer_post_code;
      }

      dispatch(a.computeRates(rateProps));

      this.setState({ step: STEP_RATE });
    };

    const onStepRetailer = () => {
      if (!objectData.object_name) {
        const desc = lookupProductGroup(objectData.product_group);
        dispatch(change(formName, 'object_name', desc));
      }
      dispatch(change(formName, 'sale_and_lease_back', undefined));
      if (isSaleAndLeaseBack) dispatch(change(formName, 'sale_and_lease_back', true));
      this.setState({ step: STEP_RETAILER });
    };
    const onStepAddresses = () => {
      dispatch(a.checkApplication(_pick(checkApplicationData, [
        'contract_type',
        'down_payment',
        'product_group',
        'purchase_price',
        'residual_value_percent',
        'lease_term',
        'rate',
        'id',
      ])));
      this.setState({ step: STEP_ADRESSES });
    };
    const onObject = () => {
      dispatch(a.getLeasingApplication(paramsId))
        .then((app) => {
          [
            'purchase_price',
          ].forEach((fieldName) => {
            dispatch(change(formName, fieldName, app[fieldName]));
          });
          if (app.manager_birth_date) {
            dispatch(change(formName, 'manager_birth_date', formatDate(app.manager_birth_date)));
          }
        });
    };

    const checkStepAfterObjectData = () => {
      if (isSaleAndLeaseBack) {
        onStepRate();
      } else {
        onStepRetailer();
      }
    };

    const checkStepBack = () => {
      if (isSaleAndLeaseBack) {
        onStepObjectData();
      } else {
        onStepRetailer();
      }
    };

    const showFinalPayment = objectData.contract_type === CONTRACT_TYPE_VA && _some(rates, 'final_payment');
    const showResidualValue = objectData.contract_type === CONTRACT_TYPE_TA;
    const wantsNewRetailer = retailerId === MAGIC_NEW_RETAILER_ID;
    const initialValues = application;
    initialValues.reference_obj = {
      is_reference_field_required: retailerReferenceObj.isReferenceFieldRequired,
      is_reference_value_required: retailerReferenceObj.isReferenceValueRequired,
      reference_description: retailerReferenceObj.referenceDescription,
    };
    initialValues.residual_value_percent = '10';

    switch (this.state.step) {
      case STEP_OBJECT:
        if (!application) {
          return <BigSpinner />;
        } if (application && !((application.status_id
           > APPROVED_APPLICATION && application.status_id
            < APPROVED_APPLICATION_BY_FINANCIAL_DECISION) || application.status_id
            === CANCELLED_DECISION)) {
          return null;
        }
        return (
          <CreateWizardStepObjectDataForm
            initialValues={initialValues}
            contractTypes={contractTypes}
            isGetContractTypesPending={isGetContractTypesPending}
            isGetProductGroupsPending={isGetProductGroupsPending}
            objectData={objectData}
            onCancel={onCancel}
            onSubmit={checkStepAfterObjectData}
            productGroups={productGroups}
            retailerReferenceObj={retailerReferenceObj}
            showResidualValue={showResidualValue}
          />
        );
      case STEP_RETAILER:
        return (
          <CreateWizardStepRetailerForm
            checkApplication={checkApplication}
            initialValues={initialValues}
            isGetRetailersPending={isGetRetailersPending}
            isPending={isCreatePending}
            onCancel={onCancel}
            application={application}
            onStepObjectData={onStepObjectData}
            checkApplicationData={checkApplicationData}
            onStepRate={onStepRate}
            onSubmit={onStepRate}
            retailers={retailers}
            wantsNewRetailer={wantsNewRetailer}
            objectData={objectData}
          />
        );
      case STEP_RATE:
        return (
          <CreateWizardStepRateForm
            isComputeRatesPending={isComputeRatesPending}
            lookupContractType={lookupContractType}
            lookupProductGroup={lookupProductGroup}
            monthOfCancellation={monthOfCancellation}
            objectData={objectData}
            onCancel={onCancel}
            onPrevious={checkStepBack}
            onStepObjectData={onStepObjectData}
            onStepRetailer={onStepRetailer}
            onSubmit={onStepAddresses}
            rates={rates}
            retailerReferenceObj={retailerReferenceObj}
            residualValue={residualValue}
            showFinalPayment={showFinalPayment}
            showResidualValue={showResidualValue}
            application={application}
            checkApplicationData={checkApplicationData}
          />
        );
      case STEP_ADRESSES:
        return (
          <CreateWizardStepAddressesForm
            isAdmin={isAdmin}
            application={application}
            isGetLegalFormsPending={isGetLegalFormsPending}
            isPending={isCreatePending}
            leasingApplications={leasingApplications}
            legalForms={legalForms}
            listSalesmen={listSalesmen}
            objectData={objectData}
            onCancel={onCancel}
            onPrevious={onStepRate}
            onStepObjectData={onStepObjectData}
            onStepRate={onStepRate}
            onStepRetailer={checkStepBack}
            onObject={onObject}
            onSubmit={onSubmit}
            userRole={userRole}
          />
        );
      default:
        return null;
    }
  }
}

CreateWizard.propTypes = {
  isAdmin: T.bool.isRequired,
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  dispatch: T.func.isRequired,
  isComputeRatesPending: T.bool.isRequired,
  isCreatePending: T.bool.isRequired,
  isGetContractTypesPending: T.bool.isRequired,
  isGetLegalFormsPending: T.bool.isRequired,
  isGetProductGroupsPending: T.bool.isRequired,
  isGetRetailersPending: T.bool.isRequired,
  isSaleAndLeaseBack: T.bool,
  leasingApplications: T.arrayOf(m.leasingApplicationSummaryShape).isRequired,
  listSalesmen: T.arrayOf(o.salesmanShape).isRequired,
  legalForms: T.arrayOf(m.legalFormShape).isRequired,
  monthOfCancellation: T.number.isRequired,
  objectData: T.shape({
    contract_type: T.number.isRequired,
    down_payment: T.number.isRequired,
    reference: T.string,
    object_name: T.string.isRequired,
    product_group: T.number.isRequired,
    purchase_price: T.number.isRequired,
    residual_value_percent: T.number.isRequired,
    retailer_id: T.number,
    retailer_post_code: T.string,
    sale_and_lease_back: T.bool,
  }),
  checkApplicationData: T.shape({
    id: T.number.isRequired,
    contract_type: T.number.isRequired,
    down_payment: T.number.isRequired,
    object_name: T.string.isRequired,
    product_group: T.number.isRequired,
    purchase_price: T.number.isRequired,
    residual_value_percent: T.number.isRequired,
    rate: T.number.isRequired,
    lease_term: T.number.isRequired,
  }).isRequired,
  checkApplication: T.arrayOf(String).isRequired,
  onCancel: T.func.isRequired,
  onSubmit: T.func.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
  rates: T.arrayOf(m.rateShape).isRequired,
  residualValue: T.number.isRequired,
  retailer: T.any,
  retailers: T.arrayOf(m.retailerShape).isRequired,
  retailerId: T.string,
  retailerGroupsLGH: T.arrayOf(T.number).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  application: m.leasingApplicationShape.isRequired,
  paramsId: T.number.isRequired,
  userRole: T.string.isRequired,
};

CreateWizard.defaultProps = {
  isSaleAndLeaseBack: false,
  objectData: {},
  retailer: null,
  retailer_post_code: null,
  retailerId: null,
  sale_and_lease_back: null,
};

const fetchObjectData = (state) => parseForm(formValueSelector(formName)(state,
  'contract_type',
  'down_payment',
  'reference',
  'object_name',
  'product_group',
  'purchase_price',
  'retailer_post_code',
  'residual_value_percent',
  'sale_and_lease_back'));

const fetchCheckApplicationData = (state) => parseForm(formValueSelector(formName)(state,
  'contract_type',
  'down_payment',
  'object_name',
  'product_group',
  'purchase_price',
  'residual_value_percent',
  'lease_term_and_rate',
  'id'));

const checkIsSaleAndLeaseBack = (state) => {
  const contract = formValueSelector(formName)(
    state,
    'contract_type',
  );
  return (contract === '1---Leasing (Sale-And-Lease-Back)'
  || contract === '11---Leasing mit Restwert (Sale-And-Lease-Back)');
};

const mapStateToProps = (state, ownProps) => ({
  isAdmin: u.getIsAdmin(state),
  contractTypes: s.getContractTypes(state),
  application: s.getApplication(ownProps.paramsId)(state),
  checkApplication: s.checkApplication(state),
  isComputeRatesPending: s.getIsComputeRatesPending(state),
  isGetContractTypesPending: s.getIsGetContractTypesPending(state),
  isGetApplicationPending: s.getIsGetApplicationPending(state),
  isGetLegalFormsPending: s.getIsGetLegalFormsPending(state),
  isGetProductGroupsPending: s.getIsGetProductGroupsPending(state),
  isGetRetailersPending: s.getIsGetRetailersPending(state),
  isSaleAndLeaseBack: checkIsSaleAndLeaseBack(state),
  leasingApplications: s.listLeasingApplications(state),
  listSalesmen: salesmanSelector.listSalesmen(state),
  legalForms: s.getLegalForms(state),
  monthOfCancellation: s.getMonthOfCancellation(state),
  objectData: fetchObjectData(state),
  checkApplicationData: fetchCheckApplicationData(state),
  productGroups: s.getProductGroups(state),
  rates: s.getRates(state),
  residualValue: s.getResidualValue(state),
  retailer: s.getRetailer(state),
  retailers: s.getRetailers(state),
  retailerId: formValueSelector(formName)(state, 'retailer_id'),
  retailerGroupsLGH: u.getRetailerGroupsLGH(state),
  retailerReferenceObj: u.getRetailerReferenceObj(state),
  userRole: u.getUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWizard);
