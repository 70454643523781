import React from 'react';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import validationState from './validationState';

const TextAreaField = ({
  disabled,
  input,
  meta,
  placeholder,
  required,
  title,
  type,
}) => (
  <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
    <b.ControlLabel>
      {title}
      {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
    </b.ControlLabel>
    <b.FormControl
      componentClass="textarea"
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      type={type}
      {...input}
    />
    {meta.touched && meta.error
      && <b.HelpBlock>{meta.error}</b.HelpBlock>}
  </b.FormGroup>
);

TextAreaField.defaultProps = {
  custom: {},
};

TextAreaField.propTypes = {
  ...fieldPropTypes,
};

export default TextAreaField;
