import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import * as typ from './actionTypes';

export const initialState = {
  error: null,
  isGetInsurancePending: false,
  isListInsurancesPending: false,
  insurances: [],
  insuranceSummariesById: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case typ.GET_INSURANCE_FULFILLED:
      return {
        ...state,
        error: null,
        isGetInsurancePending: false,
        insuranceSummariesById: {
          ...state.insuranceSummariesById,
          [action.payload.id]: action.payload,
        },
      };
    case typ.GET_INSURANCE_PENDING:
      return {
        ...state,
        isGetInsurancePending: true,
      };
    case typ.GET_INSURANCE_REJECTED:
      return {
        ...state,
        isGetInsurancePending: false,
        error: action.payload,
      };
    case typ.LIST_INSURANCES_FULFILLED:
      return {
        ...state,
        error: null,
        isListInsurancesPending: false,
        insurances: _map(action.payload.Items, (prom) => prom.id),
        insuranceSummariesById: _mapValues(
          _groupBy(action.payload.Items, (prom) => prom.id),
          (array) => array[0],
        ),
      };
    case typ.LIST_INSURANCES_PENDING:
      return {
        ...state,
        isListInsurancesPending: true,
      };
    case typ.LIST_INSURANCES_REJECTED:
      return {
        ...state,
        isListInsurancesPending: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
