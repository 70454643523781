import React from 'react';
import Helmet from 'react-helmet';

const ImprintPage = () => (
  <div>
    <Helmet title="Impressum" />
    <h1>Impressum</h1>

    <p>
      <b>ALBIS HiTec Leasing GmbH</b>
      <br />
      Ifflandstraße 4
      <br />
      22087 Hamburg
    </p>

    <p>
      T +49 (0) 40-808 100-400
      <br />
      F +49 (0) 40-808 100-434
    </p>

    <p>
      Geschäftsführer:
      <br />
      Sascha Lerchl
      <br />
      Andreas Arndt
    </p>

    <p>
      Geschäftsanschrift siehe oben
    </p>

    <p>
      Sitz der Gesellschaft: Hamburg
      <br />
      Registergericht: Amtsgericht Hamburg
      <br />
      Handelsregister Hamburg HRB 146798
      <br />
      USt.-IdNr.: DE 171622491
    </p>

    <p>
      Die ALBIS HiTec Leasing GmbH ist ein Finanzdienstleistungsinstitut im
      Sinne des Kreditwesengesetzes.
    </p>

    <p>
      <b>Zuständige Aufsichtsbehörden:</b>
      <br />
      Bundesanstalt für Finanzdienstleistungsaufsicht
      <br />
      Graurheindorfer Straße 108
      <br />
      53117 Bonn
    </p>

    <p>
      Deutsche Bundesbank
      <br />
      Hauptverwaltung Hamburg
      <br />
      Willy-Brandt-Straße 73
      <br />
      20459 Hamburg
    </p>

    <p>
      <b>LGH Portal</b>
      <br />
      Powered by
      {' '}
      <a href="https://www.justrelate.com/" target="_blank" rel="noopener noreferrer">JustRelate Group GmbH</a>
    </p>
  </div>
);

export default ImprintPage;
