import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import InputField from '../../InputField';
import MoneyField from '../../MoneyField';
import PercentField from '../../PercentField';
import SelectField from '../../SelectField';
import WizardButtons from './WizardButtons';
import WizardNavigation from './WizardNavigation';
import formName from './formName';
import { normalizeMoney, normalizePercent } from '../../normalizers';
import validate from './validate';
import * as m from '../../../convertOffer/model';
import { albisSalesContactUL } from '../../../albisSalesContact';
import ContractsExplenation from '../../static/ContractsExplanation';

const CreateWizardStepObjectDataForm = ({
  contractTypes,
  error,
  handleSubmit,
  isGetContractTypesPending,
  isGetProductGroupsPending,
  objectData,
  onCancel,
  pristine,
  productGroups,
  retailerReferenceObj,
  showResidualValue,
  submitting,
  userRole,
}) => (
  <b.Row>
    <b.Col sm={12} lg={3}>
      <WizardNavigation current={1} onClickHandlers={[]} />
    </b.Col>
    <b.Col sm={12} lg={9}>
      <b.Form onSubmit={handleSubmit}>
        {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
        <Field
          name="purchase_price"
          title="Kaufpreis (netto)"
          required
          component={MoneyField}
          type="text"
          placeholder="z.B. 10000,00"
          normalize={normalizeMoney}
          disabled
        />
        <Field
          name="down_payment"
          title="Mietsonderzahlung (netto)"
          component={MoneyField}
          type="text"
          placeholder="z.B. 500,00"
          normalize={normalizeMoney}
          disabled
        />
        <Field
          name="product_group"
          disabled="disabled"
          title={`Leasingobjekt (Klasse)${
            isGetProductGroupsPending ? ' …' : ''
          }`}
          required
          component={SelectField}
          values={_concat(
            [['', '']],
            _map(productGroups, (r) => [r.id, r.description]),
          )}
        />
        {objectData.product_group === 42 && (
          <div>
            <p>
              Sie haben &quot;KFZ&quot; ausgewählt. Bitte kontaktieren Sie nach
              dem erfolgreichen Versand Ihres Antrags den ALBIS-Vertrieb, um die
              genauen Konditionen zu besprechen.
            </p>
            {albisSalesContactUL}
          </div>
        )}
        <Field
          name="object_name"
          title="Leasingobjekt (Details)"
          component={InputField}
          type="text"
          placeholder="Bezeichnung des Leasingobjekts"
        />
        <Field
          name="contract_type"
          title={`Vertragsart${isGetContractTypesPending ? ' …' : ''}`}
          required
          component={SelectField}
          values={_concat(
            [['', '']],
            _map(contractTypes, (r) => [`${r.id}---${r.description}`, r.description]),
          )}
        />
        {showResidualValue && (
          <Field
            name="residual_value_percent"
            title="Restwert"
            required={showResidualValue}
            component={PercentField}
            type="text"
            normalize={normalizePercent}
          />
        )}
        {retailerReferenceObj.isReferenceFieldRequired && (
          <Field
            name="reference"
            title={retailerReferenceObj.referenceDescription}
            component={InputField}
            required={retailerReferenceObj.isReferenceValueRequired}
            type="text"
            placeholder={retailerReferenceObj.referenceDescription}
          />
        )}
        <WizardButtons
          onCancel={onCancel}
          pristine={pristine}
          submitting={submitting}
        />
        {(userRole === 'lessee' || userRole === 'guest') && (
          <ContractsExplenation />
        )}
      </b.Form>
    </b.Col>
  </b.Row>
);

CreateWizardStepObjectDataForm.propTypes = {
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  error: T.string,
  handleSubmit: T.func.isRequired,
  isGetContractTypesPending: T.bool.isRequired,
  isGetProductGroupsPending: T.bool.isRequired,
  objectData: T.shape({
    product_group: T.number.isRequired,
  }).isRequired,
  onCancel: T.func.isRequired,
  pristine: T.bool.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  showResidualValue: T.bool.isRequired,
  submitting: T.bool.isRequired,
  userRole: T.string,
};

CreateWizardStepObjectDataForm.defaultProps = {
  error: null,
  userRole: null,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepObjectDataForm);
