import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import CreateWizard from './CreateWizard';

const CreatePage = ({
  isCreatePending,
  onCancelCreateApplication,
  onCreateApplication,
}) => (
  <div>
    <Helmet title="Antrag stellen" />
    <CreateWizard
      isCreatePending={isCreatePending}
      onCancel={onCancelCreateApplication}
      onSubmit={onCreateApplication}
    />
  </div>
);

CreatePage.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancelCreateApplication: T.func.isRequired,
  onCreateApplication: T.func.isRequired,
};

export default CreatePage;
