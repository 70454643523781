import { PropTypes as T } from 'prop-types';

export const productGroupPropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const productGroupShape = T.shape(productGroupPropTypes);

export const contractTypePropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const contractTypeShape = T.shape(contractTypePropTypes);

export const legalFormPropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const legalFormShape = T.shape(legalFormPropTypes);

export const ratePropTypes = {
  lease_term: T.number.isRequired,
  rate: T.number.isRequired,
  insurance: T.number.isRequired,
  final_payment: T.number,
};

export const rateShape = T.shape(ratePropTypes);

export const leasingApplicationSummaryPropTypes = {
  id: T.number.isRequired,
  reference: T.string,
  lessee_city: T.string,
  lessee_name: T.string,
  lessee_post_code: T.string,
  lessee_street: T.string,
  object_name: T.string,
  purchase_price: T.number,
  salesman_name: T.string,
  status_received_date: T.string,
  status_text: T.string,
};

export const leasingApplicationSummaryShape = T.shape(leasingApplicationSummaryPropTypes);

export const leasingApplicationPropTypes = {
  accept_terms: T.bool,
  contract_type: T.number,
  down_payment: T.number,
  reference: T.string,
  final_payment: T.number,
  id: T.number.isRequired,
  lease_term: T.number,
  lessee_city: T.string,
  lessee_email: T.string,
  lessee_iban: T.string,
  lessee_name: T.string,
  password: T.string,
  password_confirm: T.string,
  lessee_phone: T.string.isRequired,
  lessee_post_code: T.string,
  lessee_street: T.string,
  manager_birth_date: T.string.isRequired,
  manager_city: T.string.isRequired,
  manager_name: T.string.isRequired,
  manager_post_code: T.string.isRequired,
  manager_salutation: T.string.isRequired,
  manager_street: T.string.isRequired,
  missing_contract_documents: T.arrayOf(T.string),
  missing_decision_documents: T.arrayOf(T.string),
  object_name: T.string,
  product_group: T.number,
  purchase_price_approved: T.number,
  purchase_price: T.number,
  rate: T.number,
  residual_value: T.number,
  retailer_branch: T.number,
  retailer_city: T.string,
  retailer_email: T.string,
  retailer_id: T.number,
  retailer_name: T.string,
  retailer_phone: T.string,
  retailer_post_code: T.string,
  retailer_street: T.string,
  status_received_date: T.string,
  status_text: T.string,
};

export const leasingApplicationShape = T.shape(leasingApplicationPropTypes);

export const retailerBranchPropTypes = {
  id: T.number.isRequired,
  text: T.string.isRequired,
};

export const retailerBranchShape = T.shape(retailerBranchPropTypes);

export const retailerPropTypes = {
  id: T.number.isRequired,
  name: T.string.isRequired,
  street: T.string,
  post_code: T.string,
  city: T.string,
  phone: T.string,
  email: T.string,
};

export const retailerShape = T.shape(retailerPropTypes);
