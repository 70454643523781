const validate = (values) => {
  const errors = {};
  if (!values.salutation) {
    errors.salutation = 'Bitte wählen Sie einen Anrede aus.';
  }
  if (!values.first_name) {
    errors.first_name = 'Bitte geben Sie einen Vornamen an.';
  }
  if (!values.last_name) {
    errors.last_name = 'Bitte geben Sie einen Nachnamen an.';
  }
  if (!values.phone) {
    errors.phone = 'Bitte geben Sie eine Telefonnummer an.';
  } else if (!values.phone.match(/^[0-9\s+-]+$/)) {
    errors.phone = 'Bitte geben Sie eine korrekte Telefonnummer an.';
  }
  if (!values.email) {
    errors.email = 'Bitte geben Sie eine E-Mail-Adresse an.';
  } else if (!values.email.match(/(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$)/)) {
    errors.email = 'Bitte geben Sie eine korrekte E-Mail-Adresse an.';
  }
  if (!values.active) {
    errors.active = 'Bitte wählen Sie aus, ob der Verkäufer aktiviert ist.';
  }
  if (!values.only_own) {
    errors.active = 'Bitte wählen Sie aus, ob der Verkäufer nur eigene Anträge/Angebote sehen kann.';
  }
  return errors;
};

export default validate;
