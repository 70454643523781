import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import Spinner from '../../Spinner';

const WizardButtons = ({
  isDetailsStep,
  isConditionStep,
  isSummaryStep,
  isPending,
  handleSubmit,
  onCancel,
  onPrevious,
  onSubmit,
  pristine,
  submitting,
}) => (
  <div>
    <b.Button bsSize="sm" onClick={onCancel}>
      Abbrechen
    </b.Button>
    <div style={{ float: 'right' }}>
      {onPrevious && (
        <b.Button bsSize="sm" onClick={onPrevious}>
          <FA name="angle-double-left icon-left" />
          Werte ändern
        </b.Button>
      )}
      {' '}
      {isDetailsStep && (
        <div>
          <b.Button
            onClick={handleSubmit((values) => onSubmit({
              ...values,
              condition: 'interest',
            }))}
            type="submit"
            bsStyle="primary"
            bsSize="sm"
            disabled={pristine || submitting}
          >
            {isPending && <Spinner />}
            Einzelkondition
            <FA name="angle-double-right icon-right" />
          </b.Button>
          {' '}
          <b.Button
            onClick={handleSubmit((values) => onSubmit({
              ...values,
              condition: 'factor',
            }))}
            type="submit"
            bsStyle="primary"
            bsSize="sm"
            disabled={pristine || submitting}
          >
            {isPending && <Spinner />}
            Konditionsstaffel
            <FA name="angle-double-right icon-right" />
          </b.Button>
        </div>
      )}
      {isConditionStep && (
        <b.Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          bsStyle="primary"
          bsSize="sm"
          disabled={pristine || submitting}
        >
          {isPending && <Spinner />}
          Zusammenfassung
          <FA name="angle-double-right icon-right" />
        </b.Button>
      )}
      {isSummaryStep && (
        <b.Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          bsStyle="primary"
          bsSize="sm"
          disabled={pristine || submitting}
        >
          {isPending && <Spinner />}
          Aktion erstellen
          <FA name="angle-double-right icon-right" />
        </b.Button>
      )}
      {' '}
    </div>
    <p className="hint-required">
      Pflichtfeld
      <span className="asterisk-required">*</span>
    </p>
  </div>
);

WizardButtons.propTypes = {
  isDetailsStep: T.bool,
  isConditionStep: T.bool,
  isSummaryStep: T.bool,
  isPending: T.bool,
  handleSubmit: T.func,
  onCancel: T.func.isRequired,
  onPrevious: T.func,
  onSubmit: T.func,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

WizardButtons.defaultProps = {
  handleSubmit: null,
  isDetailsStep: false,
  isConditionStep: false,
  isSummaryStep: false,
  isPending: false,
  onPrevious: null,
  onSubmit: null,
};

export default WizardButtons;
