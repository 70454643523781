import history from './routes/history';

export default {
  to({ pathname, query }) {
    history.replace({ pathname, query });
  },
  toPathname(p) {
    const pathname = p || '/';
    history.replace({ pathname });
  },
};
