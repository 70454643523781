import React from 'react';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import validationState from './validationState';

// Usage: <Field name="foo" title="FOO" component={SalutationRadioField} />

const SalutationRadioField = ({
  input,
  meta,
  required,
  title,
}) => (
  <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
    <b.ControlLabel>
      {title}
      {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
    </b.ControlLabel>
    <div>
      <b.Radio
        {...input}
        checked={input.value === '1'}
        value="1"
        inline
      >
        Herr
      </b.Radio>
      <b.Radio
        {...input}
        checked={input.value === '2'}
        value="2"
        inline
      >
        Frau
      </b.Radio>
    </div>
    {meta.touched && meta.error
      && <span className="help-block">{meta.error}</span>}
  </b.FormGroup>
);

SalutationRadioField.defaultProps = {
  custom: {},
};

SalutationRadioField.propTypes = {
  ...fieldPropTypes,
};

export default SalutationRadioField;
