import React from 'react';
import { render } from 'react-dom';
import Routes from './routes';
import configureAuthService from './authService';
import configureAxios from './axios';
import configureStore from './store';

import './assets/index.scss';
import './honeybadgerConfig';

const store = configureStore();

global.authService = configureAuthService(store);

global.axios = configureAxios();

render(<Routes store={store} />, document.getElementById('root'));
