import React from 'react';
import * as b from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FA from 'react-fontawesome';
import * as m from '../../../salesman/model';
import { formatSalutation } from '../../formatters';

const labelAndValue = (label, value) => (
  <b.Row>
    <b.Col xs={4} sm={2} md={3}>
      {label}
      :
    </b.Col>
    <b.Col xs={8} sm={10} md={9}>
      {value}
    </b.Col>
  </b.Row>
);

const Salesman = ({
  salesman,
}) => (
  <b.Row>
    <b.Col xs={12}>
      <div className="salesman">
        <b.Row>
          <b.Col xs={12} sm={9}>
            <div className="label-value-wrapper">
              {labelAndValue('Anrede', formatSalutation(salesman.salutation))}
              {labelAndValue('Vorname', salesman.first_name)}
              {labelAndValue('Nachname', salesman.last_name)}
            </div>
          </b.Col>
          <b.Col xs={12} sm={3}>
            <div className="label-value-btn-position">
              <LinkContainer to={`/salesmen/${salesman.id}`}>
                <b.Button bsStyle="default">
                  <FA name="eye icon-left" />
                  Verkäufer ansehen
                </b.Button>
              </LinkContainer>
            </div>
          </b.Col>
        </b.Row>
      </div>
    </b.Col>
  </b.Row>
);

Salesman.propTypes = {
  salesman: m.salesmanShape.isRequired,
};

export default Salesman;
