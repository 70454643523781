import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import redirect from '../../../redirect';
import formName from './formName';
import parseForm from './parseForm';
import CreatePage from './CreatePage';
import * as a from '../../../changeOffer/actions';
import * as leasingApplicationAction from '../../../leasingApplication/actions';
import * as s from '../../../changeOffer/selectors';
import * as b from '../../../salesman/actions';
import * as u from '../../../user/selectors';
import handleValidationErrors from '../../../handleValidationErrors';

class CreatePageContainer extends Component {
  componentWillMount() {
    const { dispatch, id, userRole } = this.props;
    dispatch(a.getContractTypes(userRole));
    dispatch(a.getProductGroups());
    dispatch(a.getLegalForms());
    dispatch(a.listOffers());
    dispatch(b.listSalesmen());
    dispatch(leasingApplicationAction.listLeasingApplications());
    dispatch(a.getOffer(id));
  }

  render() {
    const {
      isCreatePending,
      onCancelCreateOffer,
      onCreateOffer,
      id,
      userRole,
    } = this.props;
    return (
      <CreatePage
        isCreatePending={isCreatePending}
        onCancelCreateOffer={onCancelCreateOffer(userRole)}
        onCreateOffer={onCreateOffer(userRole)}
        params={id}
      />
    );
  }
}

CreatePageContainer.propTypes = {
  dispatch: T.func.isRequired,
  isCreatePending: T.bool.isRequired,
  onCancelCreateOffer: T.func.isRequired,
  onCreateOffer: T.func.isRequired,
  id: T.number.isRequired,
  userRole: T.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.params.id, 10);
  return {
    id,
    isCreatePending: s.getIsCreateOfferPending(state),
    userRole: u.getUserRole(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onCancelCreateOffer(userRole) {
    return () => {
      const path = userRole === 'guest' ? `/offers/${ownProps.params.id}` : '/offers';

      dispatch(destroy(formName));
      redirect.toPathname(path);
    };
  },
  onCreateOffer(userRole) {
    return (formValues) => {
      const parsedValues = parseForm(formValues);
      return dispatch(a.createOffer(parsedValues))
        .then((id) => {
          const path = userRole === 'guest' ? `/offers/${id}` : '/offers';

          dispatch(destroy(formName));
          redirect.toPathname(path);
        })
        .catch((err) => handleValidationErrors(
          err,
          'Das Anlegen des Angebots ist fehlgeschlagen',
        ));
    };
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageContainer);
