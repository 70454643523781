import React from 'react';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import _map from 'lodash/map';
import validationState from './validationState';

const SelectField = ({
  classNames,
  input,
  meta,
  required,
  title,
  values,
  disabled,
}) => (
  <b.FormGroup
    controlId={input.name}
    validationState={validationState(meta)}
    className={classNames}
  >
    {title
    && (
    <b.ControlLabel>
      {title}
      {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
    </b.ControlLabel>
    )}
    <b.FormControl
      disabled={disabled}
      componentClass="select"
      required={required}
      {...input}
    >
      {_map(values, ([value, display]) => <option key={`${input.name}_${value}`} value={value}>{display}</option>)}
    </b.FormControl>
    {meta.touched && meta.error
      && <span className="help-block">{meta.error}</span>}
  </b.FormGroup>
);

SelectField.defaultProps = {
  custom: {},
};

SelectField.propTypes = {
  ...fieldPropTypes,
};

export default SelectField;
