const parseForm = (values) => ({
  salutation: parseInt(values.salutation, 10) || 0,
  first_name: values.first_name || '',
  last_name: values.last_name || '',
  phone: values.phone || '',
  email: values.email || '',
  active: values.active === 'yes',
  only_own: values.only_own === 'yes',
});

export default parseForm;
