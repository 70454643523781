import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { connect } from 'react-redux';
import * as s from '../../../user/selectors';

const highlightNavItem = (text, isCurrent) => (
  isCurrent ? <b>{text}</b> : text
);

let registerStep = null;

const NavItem = ({
  isCurrent,
  onClick,
  step,
  title,
  isGuest,
}) => {
  let stepNo = step;
  if (title === '') {
    registerStep = stepNo;
    return (<div />);
  }
  if (registerStep && isGuest && registerStep < stepNo) stepNo -= 1;
  return (
    <b.NavItem eventKey={stepNo} disabled={!onClick} onClick={onClick}>
      {highlightNavItem(`Schritt ${stepNo}: ${title}`, isCurrent)}
    </b.NavItem>
  );
};

NavItem.propTypes = {
  isCurrent: T.bool.isRequired,
  onClick: T.func,
  step: T.number.isRequired,
  title: T.string.isRequired,
  isGuest: T.bool.isRequired,
};

NavItem.defaultProps = {
  onClick: null,
};

const WizardNavigation = ({
  isGuest,
  current,
  onClickHandlers,
}) => {
  let navItems = [
    'Objektdaten eingeben',
    'Lieferant auswählen',
    'Rate auswählen',
    'Adressen eingeben und Antrag absenden',
  ];
  if (isGuest) {
    navItems = [
      'Objektdaten eingeben',
      'Lieferant auswählen',
      'Rate auswählen',
      '',
      'Adressen eingeben und Antrag absenden',
    ];
  }
  return (
    <b.Nav stacked>
      {navItems.map((itemTitle, idx) => (
        <NavItem
          isCurrent={idx + 1 === current}
          key={itemTitle}
          onClick={onClickHandlers[idx]}
          step={idx + 1}
          title={itemTitle}
          isGuest={isGuest}
        />
      ))}
    </b.Nav>
  );
};

const mapStateToProps = (state) => ({
  isGuest: s.getIsGuest(state),
});

WizardNavigation.propTypes = {
  isGuest: T.bool.isRequired,
  current: T.number.isRequired,
  onClickHandlers: T.arrayOf(T.func.isRequired).isRequired,
};

export default connect(mapStateToProps)(WizardNavigation);
