import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import ListPage from './ListPage';
import BigSpinner from '../../BigSpinner';
import * as a from '../../../leasingApplication/actions';
import * as s from '../../../leasingApplication/selectors';
import * as u from '../../../user/selectors';
import * as m from '../../../leasingApplication/model';

class ListPageContainer extends Component {
  componentWillMount() {
    this.props.dispatch(a.listLeasingApplications());
    this.props.dispatch(a.getFilterStatuses());
  }

  render() {
    const {
      filterStatuses,
      isPending,
      leasingApplications,
      retailerReferenceObj,
      userRole,
    } = this.props;
    if (isPending) {
      return <BigSpinner />;
    }
    return (
      <ListPage
        leasingApplications={leasingApplications}
        filterStatuses={filterStatuses}
        retailerReferenceObj={retailerReferenceObj}
        userRole={userRole}
      />
    );
  }
}

ListPageContainer.propTypes = {
  dispatch: T.func.isRequired,
  filterStatuses: T.arrayOf(String).isRequired,
  isPending: T.bool.isRequired,
  leasingApplications: T.arrayOf(m.leasingApplicationSummaryShape).isRequired,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
  userRole: T.string,
};

ListPageContainer.defaultProps = {
  error: null,
  userRole: null,
};

const mapStateToProps = (state) => ({
  filterStatuses: s.getFilterStatuses(state),
  isPending: s.getIsListLeasingApplicationsPending(state),
  leasingApplications: s.listLeasingApplications(state),
  retailerReferenceObj: u.getRetailerReferenceObj(state),
  userRole: u.getUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPageContainer);
