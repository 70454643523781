import { PropTypes as T } from 'prop-types';

export const productGroupPropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const productGroupShape = T.shape(productGroupPropTypes);

export const contractTypePropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const contractTypeShape = T.shape(contractTypePropTypes);

export const legalFormPropTypes = {
  id: T.number.isRequired,
  description: T.string.isRequired,
};

export const legalFormShape = T.shape(legalFormPropTypes);

export const ratePropTypes = {
  lease_term: T.number.isRequired,
  rate: T.number.isRequired,
  insurance: T.number.isRequired,
  final_payment: T.number,
  lease_term_array: T.Array,
};

export const rateShape = T.shape(ratePropTypes);

export const offerSummaryPropTypes = {
  id: T.number.isRequired,
  reference: T.string,
  received_date: T.string,
  lessee_name: T.string,
  manager_first_name: T.string.isRequired,
  manager_last_name: T.string.isRequired,
  lessee_postal_code: T.string,
  lessee_street: T.string,
  lessee_city: T.string,
  price: T.number,
};

export const offerSummaryShape = T.shape(offerSummaryPropTypes);

export const offerPropTypes = {

  id: T.number.isRequired,
  reference: T.string,
  lease_term: T.array,
  lessee_city: T.string,
  lessee_name: T.string,
  lessee_phone: T.string.isRequired,
  lessee_post_code: T.string,
  lessee_street: T.string,
  manager_first_name: T.string.isRequired,
  manager_last_name: T.string.isRequired,
  object_name: T.string,
  product_group: T.number,
  price: T.number,
  residual_value: T.number,
  down_payment: T.number,
  contract_type: T.number,
  received_date: T.string,
};

export const offerShape = T.shape(offerPropTypes);
