import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as b from 'react-bootstrap';
import formName from './formName';
import WizardButtons from './WizardButtons';
import validate from './validate';
import * as mod from '../../../insurance/model';
import * as s from '../../../insurance/selectorsCreateForm';

const CreateWizardStepSummary = ({
  error,
  handleSubmit,
  onCancel,
  onPrevious,
  onSubmit,
  pristine,
  submitting,
  formValues,
}) => (
  <b.Form>
    {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
    <h4 className="pb-2">Versicherung erstellen - Zusammenfassung</h4>
    <div className="pb-3">
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Versicherungsbetrag:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.value}</p>
        </b.Col>
      </b.Row>
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Art:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.type}</p>
        </b.Col>
      </b.Row>
      {formValues.type === '%' && (
        <b.Row>
          <b.Col sm={6} lg={3}>
            <b>Standard Versicherungswert:</b>
          </b.Col>
          <b.Col sm={6} lg={3}>
            <p>{formValues.min_value}</p>
          </b.Col>
        </b.Row>
      )}
      <b.Row>
        <b.Col sm={6} lg={3}>
          <b>Deaktivieren:</b>
        </b.Col>
        <b.Col sm={6} lg={3}>
          <p>{formValues.is_deactivated ? 'aktiv' : 'inaktiv'}</p>
        </b.Col>
      </b.Row>
    </div>
    <WizardButtons
      handleSubmit={handleSubmit}
      isSummaryStep
      onCancel={onCancel}
      onPrevious={onPrevious}
      onSubmit={onSubmit}
      pristine={pristine}
      submitting={submitting}
    />
  </b.Form>
);

const mapStateToProps = (state) => ({
  formValues: s.getValues(state),
});

CreateWizardStepSummary.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  onCancel: T.func.isRequired,
  onPrevious: T.func.isRequired,
  onSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
  formValues: mod.insuranceCreateFormShape.isRequired,
};

CreateWizardStepSummary.defaultProps = {
  error: null,
};

export default connect(
  mapStateToProps,
  undefined,
)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(CreateWizardStepSummary));
