import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import * as s from '../../../insurance/selectors';
import CreateWizardStepDetails from './CreateWizardStepDetails';
import CreateWizardStepSummary from './CreateWizardStepSummary';

const STEP_DETAILS = 'Details';
const STEP_SUMMARY = 'Summary';

class CreateWizard extends Component {
  state = {
    step: STEP_DETAILS,
  };

  render() {
    const {
      isCreatePending,
      onCancel,
      onSubmit,
    } = this.props;

    const onStepDetails = () => {
      this.setState({
        step: STEP_DETAILS,
      });
    };
    const onStepSummary = () => {
      this.setState({
        step: STEP_SUMMARY,
      });
    };

    return (
      <div>
        {this.state.step === STEP_DETAILS
          && (
          <CreateWizardStepDetails
            onCancel={onCancel}
            onSubmit={onStepSummary}
          />
          )}
        {this.state.step === STEP_SUMMARY
          && (
          <CreateWizardStepSummary
            isPending={isCreatePending}
            onCancel={onCancel}
            onPrevious={onStepDetails}
            onSubmit={onSubmit}
          />
          )}
      </div>
    );
  }
}

CreateWizard.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onSubmit: T.func.isRequired,
};

const mapStateToProps = (state) => ({
  isCreatePending: s.getIsCreateInsurancePending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWizard);
