const initialValues = (salesman) => ({
  salutation: salesman.salutation,
  first_name: salesman.first_name,
  last_name: salesman.last_name,
  phone: salesman.phone,
  email: salesman.email,
  active: salesman.active ? 'yes' : 'no',
  only_own: salesman.only_own ? 'yes' : 'no',
});

export default initialValues;
