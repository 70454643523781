import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import * as a from '../../../salesman/actions';
import * as s from '../../../salesman/selectors';
import * as m from '../../../salesman/model';
import BigSpinner from '../../BigSpinner';
import DetailsPage from './DetailsPage';
import parseForm from '../create/parseForm';
import handleValidationErrors from '../../../handleValidationErrors';

class DetailsPageContainer extends Component {
  componentWillMount() {
    const { dispatch, id } = this.props;
    dispatch(a.getSalesman(id));
  }

  render() {
    const {
      isEditing,
      isGetPending,
      isUpdatePending,
      onCancelEdit,
      onEdit,
      onUpdate,
      salesman,
    } = this.props;
    if (isGetPending) {
      return <BigSpinner />;
    }
    if (!salesman) {
      return <div>Verkäufer nicht gefunden</div>;
    }
    return (
      <DetailsPage
        isEditing={isEditing}
        isUpdatePending={isUpdatePending}
        onCancelEdit={onCancelEdit}
        onEdit={onEdit}
        onUpdate={onUpdate}
        salesman={salesman}
      />
    );
  }
}

DetailsPageContainer.propTypes = {
  dispatch: T.func.isRequired,
  id: T.number.isRequired,
  isEditing: T.bool.isRequired,
  isGetPending: T.bool.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancelEdit: T.func.isRequired,
  onEdit: T.func.isRequired,
  onUpdate: T.func.isRequired,
  salesman: m.salesmanShape,
};

DetailsPageContainer.defaultProps = {
  salesman: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.params.id, 10);
  return {
    id,
    isEditing: s.getIsEditing(id)(state),
    isGetPending: s.getIsGetSalesmanPending(state),
    isUpdatePending: s.getIsSaveSalesmanPending(state),
    salesman: s.getSalesman(id)(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = parseInt(ownProps.params.id, 10);
  return {
    dispatch,
    onCancelEdit() {
      dispatch(a.editStop(id));
    },
    onEdit() {
      dispatch(a.editStart(id));
    },
    onUpdate(formValues) {
      const parsedValues = parseForm(formValues);
      parsedValues.id = id;
      return dispatch(a.saveSalesman(parsedValues))
        .then(() => dispatch(a.editStop(id)))
        .then(() => dispatch(a.getSalesman(id)))
        .catch((err) => handleValidationErrors(err, 'Das Speichern des Verkäufers ist fehlgeschlagen'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPageContainer);
