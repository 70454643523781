import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';

const ViewField = ({ title, value }) => (
  <b.Row>
    <b.Col sm={4}>
      <p>
        <b>
          {title}
          :
        </b>
      </p>
    </b.Col>
    <b.Col sm={8}>
      {value}
    </b.Col>
  </b.Row>
);

ViewField.propTypes = {
  title: T.string.isRequired,
  value: T.oneOfType([
    T.string,
    T.node,
  ]),
};

ViewField.defaultProps = {
  value: null,
};

export default ViewField;
