import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import Select from 'react-select';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _join from 'lodash/join';
import { fieldPropTypes } from 'redux-form';
import validationState from '../../validationState';
import * as m from '../../../offer/model';
import { formatMoney } from '../../formatters';

const colGroup = (showFinalPayment, showResidualValue) => {
  if (showFinalPayment && showResidualValue) {
    return (
      <colgroup>
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
      </colgroup>
    );
  }
  if (showFinalPayment || showResidualValue) {
    return (
      <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
      </colgroup>
    );
  }
  return (
    <colgroup>
      <col width="50%" />
      <col width="50%" />
    </colgroup>
  );
};

const tableHeader = (showFinalPayment, showResidualValue, monthOfCancellation) => (
  <thead>
    <tr>
      <td>gewünschte Vertragslaufzeit</td>
      <td>monatliche Rate</td>
      {showFinalPayment
        && (
        <td>
          Abschlusszahlung bei Laufzeitverkürzung auf
          {monthOfCancellation}
          {' '}
          Monate
        </td>
        )}
      {showResidualValue
        && <td>Restwert</td>}
    </tr>
  </thead>
);

const leaseTermsOptions = (rates) => {
  const label = (app) => _join(_compact([
    app.lease_term, 'Monate', '-', app.rate, 'EUR pro Monat']), '  ');
  const options = _map(rates, (app) => ({ value: app.lease_term, label: label(app) }));

  return options;
};

const tableRow = (showFinalPayment, showResidualValue, residualValue, rate) => {
  const leaseTermAndRate = `${rate.lease_term}|${rate.rate}`;
  return (
    <tr key={leaseTermAndRate}>
      <td>
        {rate.lease_term}
        {' '}
        Monate
      </td>
      <td className="money">{formatMoney(rate.rate)}</td>
      {showFinalPayment
        && <td className="money">{rate.final_payment ? formatMoney(rate.final_payment) : ''}</td>}
      {showResidualValue
        && <td className="money">{formatMoney(residualValue)}</td>}
    </tr>
  );
};

const LeaseTermsAndRatesField = ({
  input,
  meta,
  rates,
  required,
  showFinalPayment,
  showResidualValue,
  monthOfCancellation,
  residualValue,
  title,
}) => {
  if (rates.length !== 0) {
    return (
      <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
        <b.ControlLabel>
          {title}
          {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
        </b.ControlLabel>
        <b.Table striped bordered hover responsive>
          {colGroup(showFinalPayment, showResidualValue)}
          {tableHeader(showFinalPayment, showResidualValue, monthOfCancellation)}
          <tbody>
            {rates.map((rate) => tableRow(showFinalPayment, showResidualValue, residualValue, rate))}
          </tbody>
        </b.Table>
        <hr />
        <div><p>Mehrfachauswahl möglich</p></div>
        <fieldset>
          <Select
            {...input}
            name="lease_term_and_rate"
            placeholder="Laufzeit und Rate (netto)"
            options={leaseTermsOptions(rates)}
            multi
            removeSelected={false}
            onChange={(value) => {  // eslint-disable-line
              return input.onChange(value.map((p) => p.value));
            }}
            onBlur={() => input.onBlur([...input.value])}
          />
        </fieldset>
        {meta.touched && meta.error
          && <span className="help-block">{meta.error}</span>}
      </b.FormGroup>
    );
  }
  return (
    <div className="alert alert-info">
      Für die eingegebenen Werte sind keine Leasing-Raten möglich. Bitte ändern Sie Ihre Eingabe
    </div>
  );
};

LeaseTermsAndRatesField.defaultProps = {
  custom: {},
};

LeaseTermsAndRatesField.propTypes = {
  ...fieldPropTypes,
  monthOfCancellation: T.number.isRequired,
  rates: T.arrayOf(m.rateShape).isRequired,
  residualValue: T.number.isRequired,
  showFinalPayment: T.bool.isRequired,
  showResidualValue: T.bool.isRequired,
};

export default LeaseTermsAndRatesField;
