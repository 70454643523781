import {
  camelCase, groupBy, has, mapKeys, mapValues, omit, omitBy,
} from 'lodash';
import { CONTRACT_TYPE_TA } from '../../../promotion/constants';

const parseInsuranceParam = (param) => {
  const insuranceParams = param.split('~');
  const params = {
    id: insuranceParams[0],
    value: Number(insuranceParams[1]),
    type: insuranceParams[2],
    isDeactivated: insuranceParams[3] === 'true',
  };
  if (insuranceParams[2] === '%') {
    params.minValue = Number(insuranceParams[3]);
    params.isDeactivated = insuranceParams[4] === 'true';
  }
  return params;
};

const buildInterestObj = (values) => {
  const isInsuranceSelected = values.insurance_parameters && values.insurance_parameters !== 'Versicherung';
  return {
    interest: {
      insurance: isInsuranceSelected ? parseInsuranceParam(values.insurance_parameters) : null,
      interestValue: values.interest_condition,
    },
  };
};

/**
 * @param  {Array<String>} values array of strings like 'factor_value-row36-col5000'
 */
const groupByColumn = (values) => {
  const grouped = groupBy(values, (value) => value.split('-')[2]);
  return mapKeys(grouped, (_v, key) => parseInt(key.replace('col', ''), 10));
};

const buildContractTerm = (values, factorName) => {
  const insuranceParam = values[factorName.replace('factor_value', 'insurance_parameters')] || null;
  return {
    factorValue: values[factorName],
    insurance: insuranceParam ? parseInsuranceParam(insuranceParam) : null,
  };
};

const buildFactorObj = (values) => {
  const factorNames = Object.keys(values).filter((key) => key.includes('factor_value-'));
  const groupedFactorNames = groupByColumn(factorNames);

  return {
    factor: {
      elems: Object.values(mapValues(groupedFactorNames, (factorNameGroup, factorNameGroupKey) => ({
        contractTerms: Object.assign({},
          ...factorNameGroup.map((factorName) => ({ [factorName.split('-')[1].replace('row', '')]: buildContractTerm(values, factorName) }))),
        purchasePrice: Number(factorNameGroupKey),
      }))),
    },
  };
};

const buildConditionObj = (values) => (
  has(values, 'insurance_parameters') ? buildInterestObj(values) : buildFactorObj(values)
);

const omitRedundantValues = (values) => (
  omit(
    omitBy(
      values, (_v, k) => k.includes('factor_value-') || k.includes('insurance_parameters-'),
    ), [
      'contract_term_from',
      'contract_term_to',
      'down_payment_from',
      'down_payment_to',
      'financing_value_from',
      'financing_value_to',
      'insurance_parameters',
      'interest_condition',
      'promotion_period_from',
      'promotion_period_to',
      'purchase_price_from',
      'purchase_price_to',
      'residual_value_from',
      'residual_value_to',
      'region_from',
      'region_to',
    ],
  )
);

const structurizeValues = (values) => {
  const residualMandatory = Number(values.contract_type) === CONTRACT_TYPE_TA;

  let obj = {
    ...omitRedundantValues(values),
    condition: buildConditionObj(values),
    contractTerm: {
      from: values.contract_term_from,
      to: values.contract_term_to,
    },
    downPayment: {
      from: values.down_payment_from,
      to: values.down_payment_to,
    },
    financingValue: {
      from: values.financing_value_from,
      to: values.financing_value_to,
    },
    promotionPeriod: {
      from: values.promotion_period_from,
      to: values.promotion_period_to,
    },
    purchasePrice: {
      from: values.purchase_price_from,
      to: values.purchase_price_to,
    },
    region: {
      from: values.region_from,
      to: values.region_to,
    },
  };

  if (residualMandatory) {
    obj = {
      ...obj,
      residualValue: {
        from: values.residual_value_from,
        to: values.residual_value_to,
      },
    };
  }

  return mapKeys(obj, (_v, k) => camelCase(k));
};

export default structurizeValues;
