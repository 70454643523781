import React from 'react';
import * as r from 'react-router';
import CreatePageContainer from '../components/leasingApplication/create/CreatePageContainer';
import DetailsPageContainer from '../components/leasingApplication/details/DetailsPageContainer';
import ListPageContainer from '../components/leasingApplication/list/ListPageContainer';

const leasingApplicationRoutes = (
  <r.Route path="applications">
    <r.IndexRoute components={ListPageContainer} />
    <r.Route path="create" components={CreatePageContainer} />
    <r.Route path=":id(/:detail)" components={DetailsPageContainer} />
  </r.Route>
);

export default leasingApplicationRoutes;
