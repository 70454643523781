import React from 'react';
import * as r from 'react-router';
import PMDashboardPageContainer from '../components/PMdashboard/PMDashboardPageContainer';

const promotionModuleRoutes = (
  <r.Route path="sonderangebote">
    <r.IndexRoute components={PMDashboardPageContainer} />
  </r.Route>
);

export default promotionModuleRoutes;
