import React from 'react';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import validationState from './validationState';

// Usage: <Field name="foo" title="FOO" component={YesNoRadioField} />

const YesNoRadioField = ({
  input,
  meta,
  required,
  title,
}) => (
  <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
    <b.ControlLabel>
      {title}
      {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
    </b.ControlLabel>
    <div>
      <b.Radio
        {...input}
        checked={input.value === 'yes'}
        value="yes"
        inline
      >
        Ja
      </b.Radio>
      <b.Radio
        {...input}
        checked={input.value === 'no'}
        value="no"
        inline
      >
        Nein
      </b.Radio>
    </div>
    {meta.touched && meta.error
      && <span className="help-block">{meta.error}</span>}
  </b.FormGroup>
);

YesNoRadioField.defaultProps = {
  custom: {},
};

YesNoRadioField.propTypes = {
  ...fieldPropTypes,
};

export default YesNoRadioField;
