import React from 'react';
import _ from 'lodash';
import accounting from 'accounting';
import moment from 'moment';
import IBAN from 'iban';
import { albisSalesContactUL } from '../../../albisSalesContact';
import { CONTRACT_TYPE_TA, MAGIC_NEW_RETAILER_ID, PRODUCT_GROUP_KFZ } from '../../../changeApplication/constants';
import { contractTypeMapping } from '../../../utils/contractTypeMapping';

const isResidualPercentMandatory = (contractType) => contractTypeMapping(contractType) === CONTRACT_TYPE_TA;

const validate = (values) => {
  const errors = {};

  const p = accounting.unformat(values.purchase_price, ',');
  if (!p) {
    errors.purchase_price = 'Bitte geben Sie einen Kaufpreis an.';
  } else if (p <= 500.0) {
    errors.purchase_price = 'Der Kaufpreis muss größer als 500 EUR sein.';
  }

  const d = accounting.unformat(values.down_payment, ',');
  if (p && d && (p - d) < 500.0) {
    errors.down_payment = 'Die Differenz zwischen Kaufpreis und Mietsonderzahlung muss mindestens 500 EUR betragen.';
  }

  if (!values.product_group) {
    errors.product_group = 'Bitte wählen Sie eine Produktklasse aus.';
  } else if (_.includes(['99'], values.product_group)) {
    const name = 'Sonstiges';
    errors.product_group = (
      <div>
        <p>
          Sie haben &quot;
          {name}
          " ausgewählt.
          Bitte kontaktieren Sie den ALBIS-Vertrieb:
        </p>
        {albisSalesContactUL}
      </div>
    );
  }

  if (!values.contract_type) {
    errors.contract_type = 'Bitte wählen Sie eine Vertragsart aus.';
  }

  const residualMandatory = isResidualPercentMandatory(values.contract_type);
  const residualValueNumber = Number((values.residual_value_percent && values.residual_value_percent.replace(',', '.')) || 0);
  if (residualMandatory && !residualValueNumber) {
    errors.residual_value_percent = 'Bitte geben Sie einen Wert an.';
  }
  if (residualValueNumber && String(values.product_group) === PRODUCT_GROUP_KFZ
    && (residualValueNumber < 10 || residualValueNumber > 70)) {
    errors.residual_value_percent = 'Bitte geben Sie einen Restwert zwischen 10,00% und 70,00% ein.';
  }
  if (residualValueNumber && String(values.product_group) !== PRODUCT_GROUP_KFZ
    && (residualValueNumber < 10 || residualValueNumber > 30)) {
    errors.residual_value_percent = 'Bitte geben Sie einen Restwert zwischen 10,00% und 30,00% ein.';
  }
  if (isNaN(residualValueNumber)) {
    errors.residual_value_percent = 'Bitte geben Sie nur numerische Werte.';
  }

  if (!values.reference && values.reference_obj
    && values.reference_obj.is_reference_field_required
    && values.reference_obj.is_reference_value_required) {
    errors.reference = `Bitte wählen Sie eine ${values.reference_obj.reference_description} aus.`;
  }

  if (values.reference && values.reference_obj
    && values.reference_obj.is_reference_field_required && values.reference.length > 50) {
    errors.reference = `Die ${values.reference_obj.reference_description} darf nicht länger als 50 Zeichen sein.`;
  }

  if (!values.lease_term_and_rate) {
    errors.lease_term_and_rate = 'Bitte wählen Sie eine Laufzeit und Rate aus.';
  }

  if (!values.lessee_name) {
    errors.lessee_name = 'Bitte geben Sie einen Firmennamen an.';
  }

  if (values.lessee_name && values.lessee_name.length > 50) {
    errors.lessee_name = 'Der Firmenname darf nicht länger als 50 Zeichen sein.';
  }

  if (!values.lessee_legal_form) {
    errors.lessee_legal_form = 'Bitte wählen Sie eine Rechtsform aus.';
  }

  if (!values.lessee_street) {
    errors.lessee_street = 'Bitte geben Sie eine Straße an.';
  }

  if (!values.lessee_post_code) {
    errors.lessee_post_code = 'Bitte geben Sie eine PLZ an.';
  }
  if (values.lessee_post_code && values.lessee_post_code.length !== 5) {
    errors.lessee_post_code = 'Bitte geben Sie eine gültige PLZ ein.';
  }

  if (!values.lessee_city) {
    errors.lessee_city = 'Bitte geben Sie einen Ort an.';
  }

  if (!values.lessee_phone) {
    errors.lessee_phone = 'Bitte geben Sie eine Telefonnummer an.';
  } else if (!values.lessee_phone.match(/^[0-9\s+-]+$/)) {
    errors.lessee_phone = 'Bitte geben Sie eine korrekte Telefonnummer an.';
  }

  if (!values.lessee_email) {
    errors.lessee_email = 'Bitte geben Sie eine E-Mail-Adresse an.';
  } else if (!values.lessee_email.match(/(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$)/)) {
    errors.lessee_email = 'Bitte geben Sie eine korrekte E-Mail-Adresse an.';
  }

  if (!values.lessee_city) {
    errors.lessee_city = 'Bitte geben Sie einen Ort an.';
  }

  if (!values.lessee_iban) {
    errors.lessee_iban = 'Bitte geben Sie eine IBAN an.';
  }
  if (values.lessee_iban && !IBAN.isValid(values.lessee_iban)) {
    errors.lessee_iban = 'Bitte geben Sie eine gültige IBAN ein.';
  }

  if (!values.salesman) {
    errors.salesman = 'Bitte wählen Sie einen Verkäufer aus.';
  }

  if (!values.manager_salutation) {
    errors.manager_salutation = 'Bitte wählen Sie einen Anrede aus.';
  }

  if (!values.manager_first_name) {
    errors.manager_first_name = 'Bitte geben Sie einen Vornamen an.';
  }

  if (!values.manager_last_name) {
    errors.manager_last_name = 'Bitte geben Sie einen Nachnamen an.';
  }

  if (!values.manager_street) {
    errors.manager_street = 'Bitte geben Sie eine Straße an.';
  }

  if (!values.manager_post_code) {
    errors.manager_post_code = 'Bitte geben Sie eine PLZ an.';
  }
  if (values.manager_post_code && values.manager_post_code.length !== 5) {
    errors.manager_post_code = 'Bitte geben Sie eine gültige PLZ ein.';
  }

  if (!values.manager_city) {
    errors.manager_city = 'Bitte geben Sie einen Ort an.';
  }

  if (!values.manager_birth_date) {
    errors.manager_birth_date = 'Bitte geben Sie ein Geburtsdatum an.';
  }
  if (values.manager_birth_date) {
    const date = moment(values.manager_birth_date, 'DD-MM-YYYY');
    const today = moment().startOf('day');
    const ageBarrier = today.subtract(21, 'years');
    if (!date.isValid()) {
      errors.manager_birth_date = 'Bitte geben Sie ein korrektes Geburtsdatum an.';
    } else if (date.isAfter(ageBarrier)) {
      errors.manager_birth_date = 'Das Alter muss mindestens 21 Jahre betragen.';
    }
  }

  if (!values.accept_terms) {
    errors.accept_terms = 'Bitte bestätigen Sie die Einwilligungserklärung.';
  } else if (values.accept_terms !== 'yes') {
    errors.accept_terms = 'Bitte bestätigen Sie die Einwilligungserklärung mit Ja.';
  }

  if (!values.retailer_id) {
    errors.retailer_id = 'Bitte wählen Sie ein Großhandelszentrum / einen Lieferanten aus oder fügen Sie einen neuen Lieferanten hinzu.';
  } else if (values.retailer_id === MAGIC_NEW_RETAILER_ID) {
    if (!values.retailer_name) {
      errors.retailer_name = 'Bitte geben Sie einen Lieferantennamen an.';
    }
    if (!values.retailer_post_code) {
      errors.retailer_post_code = 'Bitte geben Sie eine Lieferanten-PLZ an.';
    }
    if (values.retailer_post_code && values.retailer_post_code.length !== 5) {
      errors.retailer_post_code = 'Bitte geben Sie eine gültige PLZ ein.';
    }
    if (!values.retailer_city) {
      errors.retailer_city = 'Bitte geben Sie einen Lieferantenort an.';
    }
    if (!values.retailer_street) {
      errors.retailer_street = 'Bitte geben Sie eine Lieferantenstraße an.';
    }
    if (!values.retailer_phone) {
      errors.retailer_phone = 'Bitte geben Sie ein Lieferantentelefon an.';
    } else if (!values.retailer_phone.match(/^[0-9\s+-]+$/)) {
      errors.retailer_phone = 'Bitte geben Sie eine korrekte Telefonnummer an.';
    }
    if (!values.retailer_email) {
      errors.retailer_email = 'Bitte geben Sie eine Lieferanten-E-Mail an.';
    }
  }

  return errors;
};

export default validate;
export { isResidualPercentMandatory };
