import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { Link } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { emailsWhitelist } from '../emailsWhitelist';

const NavItem = ({ eventKey, title, to }) => (
  <LinkContainer to={to}>
    <b.NavItem eventKey={eventKey}>{title}</b.NavItem>
  </LinkContainer>
);

NavItem.propTypes = {
  eventKey: T.number.isRequired,
  title: T.string.isRequired,
  to: T.string.isRequired,
};

const NavigationLeft = ({ isAdmin, isLoggedIn, userEmail }) => {
  if (!isLoggedIn) {
    return null;
  }
  return (
    <b.Nav>
      <NavItem eventKey={1.1} title="Anträge" to="/applications" />
      <NavItem eventKey={1.2} title="Angebote" to="/offers" />
      {isAdmin && <NavItem eventKey={1.3} title="Verkäufer" to="/salesmen" />}
      {
        (isAdmin || emailsWhitelist.indexOf(userEmail) !== -1)
          && <NavItem eventKey={1.4} title="Sonderangebote" to="/sonderangebote" />
      }
    </b.Nav>
  );
};

NavigationLeft.propTypes = {
  isAdmin: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  userEmail: T.string.isRequired,
};

const NavigationRight = ({
  onClickChangePassword,
  onClickLogout,
  isLoggedIn,
  isGuest,
}) => {
  if (!isLoggedIn) {
    return null;
  }
  return (
    <b.Nav pullRight>
      <b.NavItem
        className="sign-in-out-btn"
        eventKey={2.1}
        onClick={onClickLogout}
      >
        Abmelden
      </b.NavItem>
      {(!isGuest
        && (
        <b.NavItem eventKey={2.2} onClick={onClickChangePassword}>
          Passwort ändern
        </b.NavItem>
        )
      )}
    </b.Nav>
  );
};

NavigationRight.propTypes = {
  isLoggedIn: T.bool.isRequired,
  onClickChangePassword: T.func.isRequired,
  onClickLogout: T.func.isRequired,
  isGuest: T.bool.isRequired,
};

const Navigation = ({
  onClickChangePassword,
  onClickLogout,
  isAdmin,
  isGuest,
  isLoggedIn,
  userEmail,
}) => (
  <b.Navbar>
    <b.Navbar.Header>
      <b.Navbar.Brand>
        <Link to="/">
          <b>LGH</b>
        </Link>
      </b.Navbar.Brand>
      <b.Navbar.Toggle />
    </b.Navbar.Header>
    <b.Navbar.Collapse>
      <NavigationLeft isAdmin={isAdmin} isLoggedIn={isLoggedIn} userEmail={userEmail} />
      <NavigationRight
        isLoggedIn={isLoggedIn}
        onClickChangePassword={onClickChangePassword}
        onClickLogout={onClickLogout}
        isGuest={isGuest}
      />
    </b.Navbar.Collapse>
  </b.Navbar>
);

Navigation.propTypes = {
  isAdmin: T.bool.isRequired,
  isGuest: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  onClickChangePassword: T.func.isRequired,
  onClickLogout: T.func.isRequired,
  userEmail: T.string.isRequired,
};

export default Navigation;
