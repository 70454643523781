import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import CreateWizardStepDetails from './CreateWizardStepDetails';
import CreateWizardStepSummary from './CreateWizardStepSummary';

const STEP_DETAILS = 'Details';
const STEP_SUMMARY = 'Summary';

class CreateWizard extends Component {
  state = {
    step: STEP_DETAILS,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.idChanged();
    }
  }

  onStepDetails = () => {
    this.setState({
      step: STEP_DETAILS,
    });
  };

  onStepSummary = () => {
    this.setState({
      step: STEP_SUMMARY,
    });
  };

  idChanged = () => {
    this.setState({
      step: STEP_DETAILS,
    });
  }

  render() {
    const {
      id,
      isUpdatePending,
      onCancel,
      onSubmit,
    } = this.props;

    return (
      <div>
        {this.state.step === STEP_DETAILS
          && (
          <CreateWizardStepDetails
            id={id}
            onCancel={onCancel}
            onSubmit={this.onStepSummary}
          />
          )}
        {this.state.step === STEP_SUMMARY
          && (
          <CreateWizardStepSummary
            isPending={isUpdatePending}
            onCancel={onCancel}
            onPrevious={this.onStepDetails}
            onSubmit={onSubmit}
          />
          )}
      </div>
    );
  }
}

CreateWizard.propTypes = {
  id: T.string.isRequired,
  isUpdatePending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onSubmit: T.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(undefined, mapDispatchToProps)(CreateWizard);
