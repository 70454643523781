import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { connect } from 'react-redux';
import _concat from 'lodash/concat';
import _map from 'lodash/map';
import {
  change, formValueSelector, reduxForm, Field,
} from 'redux-form';
import MoneyField from '../../MoneyField';
import DateRangeField from '../../DateRangeField';
import SelectField from '../../SelectField';
import PercentField from '../../PercentField';
import CheckboxField from '../../CheckboxField';
import WizardButtons from './WizardButtons';
import formName from './formName';
import {
  normalizeMoney,
  normalizeIntegers,
  normalizeZipCodes,
  normalizePercent,
} from '../../normalizers';
import validate from './validate';
import * as m from '../../../offer/model';
import * as s from '../../../promotion/selectors';
import InputField from '../../InputField';

class CreateWizardStepDetails extends Component {
  onAcceptActivation = () => {
    this.props.dispatch(change(formName, 'is_activation_accepted', true));
  };

  onDenyActivation = () => {
    this.props.dispatch(change(formName, 'is_activation_accepted', false));
    this.props.dispatch(change(formName, 'is_deactivated', true));
  };

  render() {
    const {
      condition,
      contractTypes,
      error,
      formData,
      handleSubmit,
      initialValues,
      isGetContractTypesPending,
      isGetProductGroupsPending,
      onCancel,
      onSave,
      onSubmit,
      pristine,
      productGroups,
      showResidualValue,
      submitting,
    } = this.props;

    return (
      <b.Row>
        <b.Col sm={12}>
          <b.Form>
            {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
            <h4 className="pb-3">Aktion aktualisieren</h4>
            <div>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>
                    Laufzeit
                    <span title="Pflichtfeld" className="asterisk-required">
                      *
                    </span>
                  </b.ControlLabel>
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="promotion_period_from"
                    required
                    component={DateRangeField}
                    placeholder="von (z.B. 01/01/2020)"
                  />
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="promotion_period_to"
                    required
                    component={DateRangeField}
                    placeholder="bis (z.B. 01/01/2021)"
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>
                    Aktionsname
                    <span title="Pflichtfeld" className="asterisk-required">
                      *
                    </span>
                  </b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="promotion_name"
                    component={InputField}
                    type="text"
                    placeholder="(z.B. Black Friday promotion)"
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>Aktionsbeschreibung</b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="promotion_description"
                    component={InputField}
                    type="text"
                    placeholder="(z.B. Before Christmas promotion )"
                  />
                </b.Col>
              </b.Row>
              {
              // <b.Row>
              //   <b.Col sm={4}>
              //     <b.ControlLabel>Vertragslaufzeit</b.ControlLabel>
              //   </b.Col>
              //   <b.Col sm={4}>
              //     <Field
              //       addon="Months"
              //       name="contract_term_from"
              //       component={InputField}
              //       type="text"
              //       placeholder="von (z.B. 12)"
              //       normalize={normalizeIntegers}
              //     />
              //   </b.Col>
              //   <b.Col sm={4}>
              //     <Field
              //       addon="Months"
              //       name="contract_term_to"
              //       component={InputField}
              //       type="text"
              //       placeholder="bis (z.B. 36)"
              //       normalize={normalizeIntegers}
              //     />
              //   </b.Col>
              // </b.Row>
              }
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>Kaufpreis</b.ControlLabel>
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="purchase_price_from"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    placeholder="von (z.B. 1000,00)"
                    normalize={normalizeMoney}
                  />
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="purchase_price_to"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    placeholder="bis (z.B. 5000,00)"
                    normalize={normalizeMoney}
                  />
                </b.Col>
              </b.Row>
              <b.Row style={{ display: 'none' }}>
                <b.Col sm={4}>
                  <b.ControlLabel>Down payment</b.ControlLabel>
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="down_payment_from"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    placeholder="von (z.B. 300,00)"
                    normalize={normalizeMoney}
                    disabled
                  />
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="down_payment_to"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    placeholder="bis (z.B. 500,00)"
                    normalize={normalizeMoney}
                    disabled
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>Finanzierungswert</b.ControlLabel>
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="financing_value_from"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    normalize={normalizeMoney}
                  />
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="financing_value_to"
                    component={MoneyField}
                    formName={formName}
                    type="text"
                    normalize={normalizeMoney}
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>
                    {`Vertragsart${
                      isGetContractTypesPending ? ' …' : ''
                    }`}
                  </b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="contract_type"
                    component={SelectField}
                    values={_concat(
                      [['', '']],
                      _map(contractTypes, (r) => [r.id, r.description]),
                    )}
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>
                    {`Produktgruppe${
                      isGetProductGroupsPending ? ' …' : ''
                    }`}
                  </b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="product_group"
                    component={SelectField}
                    values={_concat(
                      [['', '']],
                      _map(productGroups, (r) => [r.id, r.description]),
                    )}
                  />
                </b.Col>
              </b.Row>
              {showResidualValue && (
                <b.Row>
                  <b.Col sm={4}>
                    <b.ControlLabel>
                      Residual value
                      <span title="Pflichtfeld" className="asterisk-required">
                        *
                      </span>
                    </b.ControlLabel>
                  </b.Col>
                  <b.Col sm={4}>
                    <Field
                      name="residual_value_from"
                      component={PercentField}
                      type="text"
                      placeholder="z.B. 1,25"
                      normalize={normalizePercent}
                    />
                  </b.Col>
                  <b.Col sm={4}>
                    <Field
                      name="residual_value_to"
                      component={PercentField}
                      type="text"
                      placeholder="z.B. 15,50"
                      normalize={normalizePercent}
                    />
                  </b.Col>
                </b.Row>
              )}
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>Region</b.ControlLabel>
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="region_from"
                    component={InputField}
                    type="text"
                    placeholder="von (z.B. 50000)"
                    normalize={normalizeZipCodes}
                  />
                </b.Col>
                <b.Col sm={4}>
                  <Field
                    name="region_to"
                    component={InputField}
                    type="text"
                    placeholder="bis (z.B. 50500)"
                    normalize={normalizeZipCodes}
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>Händler</b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="retailers"
                    component={InputField}
                    type="text"
                    placeholder="(z.B. 123, 1234, 123456)"
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel>Händlergruppe</b.ControlLabel>
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    name="retailer_group"
                    component={InputField}
                    type="text"
                    placeholder="(z.B. 1234)"
                    normalize={normalizeIntegers}
                  />
                </b.Col>
              </b.Row>
              <b.Row>
                <b.Col sm={4}>
                  <b.ControlLabel />
                </b.Col>
                <b.Col sm={8}>
                  <Field
                    title="Deaktivieren"
                    name="is_deactivated"
                    type="checkbox"
                    component={CheckboxField}
                  />
                </b.Col>
              </b.Row>
              {!formData.is_deactivated
                && !formData.is_activation_accepted
                  && formData.is_initial_deactivated
                    && initialValues.insurances_deactivated_assigned.length !== 0
                    && (
                    <div className="alert alert-danger">
                      <div>
                        Sind Sie sicher? Diese Aktion verwendet deaktivierte Versicherungen.
                        Für diese Aktion wird daher der Standard Versicherungswert verwendet.
                        Die deaktivierte Versicherung wird in den folgenden Aktionen verwendet:
                      </div>
                      <ul>
                        {initialValues.insurances_deactivated_assigned.map((ins) => (
                          <li key={ins.id}>
                            {`${ins.value} ${ins.type}`}
                            {' '}
                            (
                            {ins.id.substr(0, 5)}
                            )
                          </li>
                        ))}
                      </ul>
                      <div className="pt-2 text-center">
                        <b.Button bsSize="sm" bsStyle="danger" onClick={this.onDenyActivation} className="mr-2">
                          Nein, nicht aktivieren
                        </b.Button>
                        <b.Button bsSize="sm" bsStyle="success" onClick={this.onAcceptActivation}>
                          Ja, Aktion aktivieren
                        </b.Button>
                      </div>
                    </div>
                    )}
              <WizardButtons
                condition={condition}
                disableIfInactiveInsurances={!formData.is_activation_accepted
                  && !formData.is_deactivated
                    && initialValues.insurances_deactivated_assigned.length !== 0}
                handleSubmit={handleSubmit}
                isDetailsStep
                onCancel={onCancel}
                onSave={onSave}
                onSubmit={onSubmit}
                pristine={pristine}
                submitting={submitting}
              />
            </div>
          </b.Form>
        </b.Col>
      </b.Row>
    );
  }
}

CreateWizardStepDetails.propTypes = {
  dispatch: T.func.isRequired,
  condition: T.string.isRequired,
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  error: T.string,
  formData: T.any.isRequired,
  handleSubmit: T.func.isRequired,
  initialValues: T.any.isRequired,
  isGetContractTypesPending: T.bool.isRequired,
  isGetProductGroupsPending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onSave: T.func.isRequired,
  onSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
  showResidualValue: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

CreateWizardStepDetails.defaultProps = {
  error: null,
};

const fetchFormData = (state) => formValueSelector(formName)(state, 'is_deactivated',
  'is_activation_accepted', 'is_initial_deactivated');

const mapStateToProps = (state, ownProps) => {
  const condition = s.getPromotionCondition(ownProps.id)(state);
  const initialValues = s.getInitialValuesForUpdateForm(ownProps.id)(state);
  return {
    condition,
    initialValues: {
      ...initialValues,
      insurances_deactivated_assigned: s.getInsurancesDeactivedAssigned(ownProps.id)(state),
    },
    formData: fetchFormData(state),
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  enableReinitialize: false,
  validate,
})(CreateWizardStepDetails));
