const parseForm = (values) => {
  const parsedData = {
    id: values.id,
    value: (values.value && parseFloat(values.value.replace(',', '.'), 10)) || 0,
    type: values.type,
    isDeactivated: values.is_deactivated || false,
    isDeactivationAccepted: values.is_deactivation_accepted,
    isInitialDeactivated: values.is_initial_deactivated,
  };
  if (values.type === '%') parsedData.minValue = (values.min_value && parseFloat(values.min_value.replace(',', '.'), 10)) || 0;

  return parsedData;
};

export default parseForm;
