import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navigation from './Navigation';
import * as userActions from '../user/actions';
import * as s from '../user/selectors';
import { apiUrl } from '../axios';

const NavigationContainer = ({
  onClickChangePassword,
  onClickLogout,
  isAdmin,
  isGuest,
  isLoggedIn,
  userEmail,
}) => (
  <Navigation
    isAdmin={isAdmin}
    isGuest={isGuest}
    isLoggedIn={isLoggedIn}
    onClickChangePassword={onClickChangePassword}
    onClickLogout={onClickLogout}
    userEmail={userEmail}
  />
);

NavigationContainer.propTypes = {
  isAdmin: T.bool.isRequired,
  isGuest: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  onClickChangePassword: T.func.isRequired,
  onClickLogout: T.func.isRequired,
  userEmail: T.string.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: s.getIsAdmin(state),
  isGuest: s.getIsGuest(state),
  isLoggedIn: s.getIsLoggedIn(state),
  userEmail: s.getCurrentUserEmail(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onClickChangePassword() {
    window.location = `${apiUrl}/change_password`;
  },
  onClickLogout() {
    dispatch(userActions.logout()).then(() => {
      window.location = `${apiUrl}/login`;
    });
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NavigationContainer),
);
