import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import formName from './formName';
import parseForm from './parseForm';
import structurizeValues from './structurizeValues';
import * as act from '../../../promotion/actions';
import * as s from '../../../promotion/selectors';
import CreatePage from './CreatePage';

import handleValidationErrors from '../../../handleValidationErrors';

const CreatePageContainer = ({
  isCreatePending,
  onCancelCreatePromotion,
  onCreatePromotion,
  onDestroyForm,
}) => (
  <CreatePage
    isCreatePending={isCreatePending}
    onCancelCreatePromotion={() => onCancelCreatePromotion(onDestroyForm)}
    onCreatePromotion={(args) => onCreatePromotion(onDestroyForm, args)}
  />
);

CreatePageContainer.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancelCreatePromotion: T.func.isRequired,
  onDestroyForm: T.func.isRequired,
  onCreatePromotion: T.func.isRequired,
};

const mapStateToProps = (state) => ({
  isCreatePending: s.getIsCreatePromotionPending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onCancelCreatePromotion(onDestroyForm) {
    dispatch(destroy(formName));
    onDestroyForm();
  },
  onCreatePromotion(onDestroyForm, formValues) {
    const parsedValues = parseForm(formValues);
    const structurizedValues = structurizeValues(parsedValues);
    return dispatch(act.createPromotion(structurizedValues))
      .then(() => {
        onDestroyForm();
        dispatch(destroy(formName));
        dispatch(act.listPromotions());
      })
      .catch((err) => handleValidationErrors(
        err,
        'Das Anlegen des Sonderangebots ist fehlgeschlagen',
      ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageContainer);
