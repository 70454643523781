import React from 'react';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import validationState from './validationState';

const InputField = ({
  classNames,
  disabled,
  input,
  meta,
  onKeyUp,
  placeholder,
  required,
  title,
  type,
}) => (
  <b.FormGroup
    controlId={input.name}
    validationState={validationState(meta)}
    className={classNames}
  >
    {title
      && (
      <b.ControlLabel>
        {title}
        {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
      </b.ControlLabel>
      )}
    <b.FormControl
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      type={type}
      {...input}
      onKeyUp={onKeyUp}
    />
    {meta.touched && meta.error
      && <b.HelpBlock>{meta.error}</b.HelpBlock>}
  </b.FormGroup>
);

InputField.propTypes = {
  ...fieldPropTypes,
};

InputField.defaultProps = {
  custom: {},
};

export default InputField;
