import { NAME } from './constants';

export const CLEAR_RATES = `${NAME}/CLEAR_RATES`;
export const COMPUTE_RATES_FULFILLED = `${NAME}/COMPUTE_RATES_FULFILLED`;
export const COMPUTE_RATES_PENDING = `${NAME}/COMPUTE_RATES_PENDING`;
export const COMPUTE_RATES_REJECTED = `${NAME}/COMPUTE_RATES_REJECTED`;
export const CREATE_LEASING_APPLICATION_FULFILLED = `${NAME}/CREATE_LEASING_APPLICATION_FULFILLED`;
export const CREATE_LEASING_APPLICATION_PENDING = `${NAME}/CREATE_LEASING_APPLICATION_PENDING`;
export const CREATE_LEASING_APPLICATION_REJECTED = `${NAME}/CREATE_LEASING_APPLICATION_REJECTED`;
export const GET_CONTRACT_TYPES_FULFILLED = `${NAME}/GET_CONTRACT_TYPES_FULFILLED`;
export const GET_CONTRACT_TYPES_PENDING = `${NAME}/GET_CONTRACT_TYPES_PENDING`;
export const GET_CONTRACT_TYPES_REJECTED = `${NAME}/GET_CONTRACT_TYPES_REJECTED`;
export const GET_LEASING_APPLICATION_FULFILLED = `${NAME}/GET_LEASING_APPLICATION_FULFILLED`;
export const GET_LEASING_APPLICATION_PENDING = `${NAME}/GET_LEASING_APPLICATION_PENDING`;
export const GET_LEASING_APPLICATION_REJECTED = `${NAME}/GET_LEASING_APPLICATION_REJECTED`;
export const GET_LEGAL_FORMS_FULFILLED = `${NAME}/GET_LEGAL_FORMS_FULFILLED`;
export const GET_LEGAL_FORMS_PENDING = `${NAME}/GET_LEGAL_FORMS_PENDING`;
export const GET_LEGAL_FORMS_REJECTED = `${NAME}/GET_LEGAL_FORMS_REJECTED`;
export const GET_PRODUCT_GROUPS_FULFILLED = `${NAME}/GET_PRODUCT_GROUPS_FULFILLED`;
export const GET_PRODUCT_GROUPS_PENDING = `${NAME}/GET_PRODUCT_GROUPS_PENDING`;
export const GET_PRODUCT_GROUPS_REJECTED = `${NAME}/GET_PRODUCT_GROUPS_REJECTED`;
export const GET_RETAILER = `${NAME}/GET_RETAILER`;
export const GET_RETAILER_BRANCHES_FULFILLED = `${NAME}/GET_RETAILER_BRANCHES_FULFILLED`;
export const GET_RETAILER_BRANCHES_PENDING = `${NAME}/GET_RETAILER_BRANCHES_PENDING`;
export const GET_RETAILER_BRANCHES_REJECTED = `${NAME}/GET_RETAILER_BRANCHES_REJECTED`;
export const GET_RETAILERS_FULFILLED = `${NAME}/GET_RETAILERS_FULFILLED`;
export const GET_RETAILERS_PENDING = `${NAME}/GET_RETAILERS_PENDING`;
export const GET_RETAILERS_REJECTED = `${NAME}/GET_RETAILERS_REJECTED`;
export const LIST_LEASING_APPLICATIONS_FULFILLED = `${NAME}/LIST_LEASING_APPLICATIONS_FULFILLED`;
export const LIST_LEASING_APPLICATIONS_PENDING = `${NAME}/LIST_LEASING_APPLICATIONS_PENDING`;
export const LIST_LEASING_APPLICATIONS_REJECTED = `${NAME}/LIST_LEASING_APPLICATIONS_REJECTED`;
export const GET_OFFER_FULFILLED = `${NAME}/GET_OFFER_FULFILLED`;
export const GET_OFFER_PENDING = `${NAME}/GET_OFFER_PENDING`;
export const GET_OFFER_REJECTED = `${NAME}/GET_OFFER_REJECTED`;
