import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import DashboardPage from './DashboardPage';
import * as les from '../../lessee/actions';
import * as u from '../../user/selectors';

class DashboardPageContainer extends Component {
  componentDidMount() {
    const { dispatch, userRole } = this.props;
    if (userRole === 'lessee') {
      dispatch(les.getLesseeData());
    }
  }

  render() {
    return <DashboardPage />;
  }
}

DashboardPageContainer.propTypes = {
  dispatch: T.func.isRequired,
  userRole: T.string.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: u.getUserRole(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPageContainer);
