import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import * as b from 'react-bootstrap';
import * as s from '../alert/selectors';

const AlertsContainer = ({ alerts }) => {
  if (alerts.length < 1) {
    return null;
  }
  return (
    <div className="alerts-container">
      <b.Alert>
        {alerts.map((item) => (
          <p key={item.id}>
            {item.message}
          </p>
        ))}
      </b.Alert>
    </div>
  );
};

AlertsContainer.propTypes = {
  alerts: T.arrayOf(T.shape({
    id: T.string.isRequired,
    message: T.string.isRequired,
  })).isRequired,
};

const mapStateToProps = (state) => ({
  alerts: s.getAll(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
