import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import redirect from '../../../redirect';
import parseForm from './parseForm';
import CreatePage from './CreatePage';
import * as a from '../../../salesman/actions';
import * as s from '../../../salesman/selectors';
import handleValidationErrors from '../../../handleValidationErrors';

class CreatePageContainer extends Component {
  componentWillMount() {
    // const { dispatch } = this.props;
  }

  render() {
    const {
      isCreatePending,
      onCancelCreate,
      onCreate,
    } = this.props;
    return (
      <CreatePage
        isCreatePending={isCreatePending}
        onCancelCreate={onCancelCreate}
        onCreate={onCreate}
      />
    );
  }
}

CreatePageContainer.propTypes = {
  // dispatch: T.func.isRequired,
  isCreatePending: T.bool.isRequired,
  onCancelCreate: T.func.isRequired,
  onCreate: T.func.isRequired,
};

CreatePageContainer.defaultProps = {
};

const mapStateToProps = (state) => ({
  isCreatePending: s.getIsSaveSalesmanPending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onCancelCreate() {
    redirect.toPathname('/salesmen');
  },
  onCreate(formValues) {
    const parsedValues = parseForm(formValues);
    return dispatch(a.saveSalesman(parsedValues))
      .then((id) => {
        redirect.toPathname(`/salesmen/${id}`);
      })
      .catch((err) => handleValidationErrors(err, 'Das Anlegen des Verkäufers ist fehlgeschlagen'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePageContainer);
