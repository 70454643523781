import React from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import formName from './formName';
import parseForm from './parseForm';
import * as act from '../../../insurance/actions';
import * as s from '../../../insurance/selectors';
import CreatePage from './CreatePage';

import handleValidationErrors from '../../../handleValidationErrors';

const CreatePageContainer = ({
  isCreatePending,
  onCancelCreateInsurance,
  onCreateInsurance,
  onDestroyForm,
}) => (
  <CreatePage
    isCreatePending={isCreatePending}
    onCancelCreateInsurance={() => onCancelCreateInsurance(onDestroyForm)}
    onCreateInsurance={(args) => onCreateInsurance(onDestroyForm, args)}
  />
);

CreatePageContainer.propTypes = {
  isCreatePending: T.bool.isRequired,
  onCancelCreateInsurance: T.func.isRequired,
  onDestroyForm: T.func.isRequired,
  onCreateInsurance: T.func.isRequired,
};

const mapStateToProps = (state) => ({
  isCreatePending: s.getIsCreateInsurancePending(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onCancelCreateInsurance(onDestroyForm) {
    dispatch(destroy(formName));
    onDestroyForm();
  },
  onCreateInsurance(onDestroyForm, formValues) {
    const parsedValues = parseForm(formValues);
    return dispatch(act.createInsurance(parsedValues))
      .then(() => {
        onDestroyForm();
        dispatch(destroy(formName));
        dispatch(act.listInsurances());
      })
      .catch((err) => handleValidationErrors(
        err,
        'Das Anlegen der Versicherung ist fehlgeschlagen',
      ));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePageContainer);
