import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import { fieldPropTypes } from 'redux-form';
import { connect } from 'react-redux';
import validationState from './validationState';
import * as actCreate from '../promotion/actionsCreateForm';
import * as actUpdate from '../promotion/actionsUpdateForm';
import { FORM_NAME_CREATE, FORM_NAME_UPDATE } from '../promotion/constants';

const MoneyField = ({
  change,
  disabled,
  formName,
  input,
  meta,
  placeholder,
  required,
  title,
  type,
}) => (
  <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
    {title
      && (
      <b.ControlLabel>
        {title}
        {required && <span title="Pflichtfeld" className="asterisk-required">*</span>}
      </b.ControlLabel>
      )}
    <b.InputGroup>
      <b.FormControl
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        type={type}
        {...input}
        onChange={(event) => change(formName, input.onChange, event)}
      />
      <b.InputGroup.Addon>EUR</b.InputGroup.Addon>
    </b.InputGroup>
    {meta.touched && meta.error
      && <b.HelpBlock>{meta.error}</b.HelpBlock>}
  </b.FormGroup>
);

MoneyField.defaultProps = {
  custom: {},
};

MoneyField.propTypes = {
  ...fieldPropTypes,
  change: T.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  change(formName, callback, event) {
    callback(event);
    if (formName === FORM_NAME_CREATE) {
      dispatch(actCreate.changeMoneyField());
    }
    if (formName === FORM_NAME_UPDATE) {
      dispatch(actUpdate.changeMoneyField());
    }
  },
});

export default connect(
  undefined,
  mapDispatchToProps,
)(MoneyField);
