import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import FA from 'react-fontawesome';

import { connect } from 'react-redux';
import * as b from 'react-bootstrap';

import BigSpinner from '../../BigSpinner';
import * as a from '../../../offer/actions';
import * as s from '../../../offer/selectors';
import * as u from '../../../user/selectors';
import * as m from '../../../offer/model';

import Offer from './OfferContentComponent';

class OfferSummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  componentWillMount() {
    this.props.dispatch(a.getOffer(this.props.id));
  }

  render() {
    const { isPending, offer, retailerReferenceObj } = this.props;
    if (isPending || !offer) {
      return <BigSpinner />;
    }
    return (
      <div>
        <LinkContainer to="/offers/create">
          <b.Button bsStyle="primary">
            <FA name="plus-circle icon-left" />
            Neues Angebot erstellen
          </b.Button>
        </LinkContainer>
        <br />
        <br />
        <b.Alert>
          Zum Aufbewahren des Angebots, drucken Sie es sich bitte aus.
          Die Speicherung von Angeboten steht erst nach erfolgter Registrierung zur Verfügung.
          Die Registrierung können Sie beim Erstellen eines Antrages durchführen
          (Button
          {' '}
          <q>Antrag erstellen</q>
          ).
        </b.Alert>
        <Offer app={offer} retailerReferenceObj={retailerReferenceObj} />
      </div>
    );
  }
}

OfferSummaryView.defaultProps = {
  offer: { id: 0 },
};

OfferSummaryView.propTypes = {
  id: T.number.isRequired,
  dispatch: T.func.isRequired,
  isPending: T.bool.isRequired,
  offer: m.offerShape,
  retailerReferenceObj: T.shape({
    isReferenceFieldRequired: T.bool.isRequired,
    isReferenceValueRequired: T.bool.isRequired,
    referenceDescription: T.string.isRequired,
  }).isRequired,
};
const mapStateToProps = (state, ownProps) => ({
  id: parseInt(ownProps.params.id, 10),
  isPending: s.getIsGetOfferPending(state),
  offer: s.getOffer(ownProps.params.id)(state),
  retailerReferenceObj: u.getRetailerReferenceObj(state),
});
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OfferSummaryView);
