import React from 'react';
import { PropTypes as T } from 'prop-types';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import Spinner from '../../Spinner';

const WizardButtons = ({
  disableIfInactiveInsurances,
  isDetailsStep,
  isConditionStep,
  isSummaryStep,
  isPending,
  handleSubmit,
  onCancel,
  onPrevious,
  onSave,
  onSubmit,
  pristine,
  submitting,
}) => {
  const forDetailsStep = () => (
    <div>
      <b.Button
        disabled={pristine || submitting || disableIfInactiveInsurances}
        onClick={onSave}
        type="submit"
        bsStyle="success"
        bsSize="sm"
      >
        Speichern/Zusammenfassung...
        <FA name="angle-double-right icon-right" />
      </b.Button>
      {' '}
      <b.Button
        onClick={handleSubmit((values) => onSubmit({
          ...values,
          condition: 'interest',
        }))}
        type="submit"
        bsStyle="primary"
        bsSize="sm"
        disabled={submitting || disableIfInactiveInsurances}
      >
        {isPending && <Spinner />}
        Einzelkondition
        <FA name="angle-double-right icon-right" />
      </b.Button>
      {' '}
      <b.Button
        onClick={handleSubmit((values) => onSubmit({
          ...values,
          condition: 'factor',
        }))}
        type="submit"
        bsStyle="primary"
        bsSize="sm"
        disabled={submitting || disableIfInactiveInsurances}
      >
        {isPending && <Spinner />}
        Konditionsstaffel
        <FA name="angle-double-right icon-right" />
      </b.Button>
    </div>
  );
  const forConditionStep = () => (
    <b.Button
      onClick={handleSubmit(onSubmit)}
      type="submit"
      bsStyle="primary"
      bsSize="sm"
    >
      {isPending && <Spinner />}
      Zusammenfassung
      <FA name="angle-double-right icon-right" />
    </b.Button>
  );
  const forSummaryStep = () => (
    <b.Button
      onClick={handleSubmit(onSubmit)}
      type="submit"
      bsStyle="primary"
      bsSize="sm"
      disabled={pristine || submitting}
    >
      {isPending && <Spinner />}
      Aktion aktualisieren
      <FA name="angle-double-right icon-right" />
    </b.Button>
  );
  return (
    <div>
      <b.Button bsSize="sm" onClick={onCancel}>
        Abbrechen
      </b.Button>
      {' '}
      <div style={{ float: 'right' }}>
        {onPrevious && (
          <b.Button bsSize="sm" onClick={onPrevious}>
            <FA name="angle-double-left icon-left" />
            Werte ändern
          </b.Button>
        )}
        {' '}
        {isDetailsStep && forDetailsStep()}
        {isConditionStep && forConditionStep()}
        {isSummaryStep && forSummaryStep()}
        {' '}
      </div>
      <p className="hint-required">
        Pflichtfeld
        <span className="asterisk-required">*</span>
      </p>
    </div>
  );
};

WizardButtons.propTypes = {
  disableIfInactiveInsurances: T.bool,
  isDetailsStep: T.bool,
  isConditionStep: T.bool,
  isSummaryStep: T.bool,
  isPending: T.bool,
  handleSubmit: T.func,
  onCancel: T.func.isRequired,
  onPrevious: T.func,
  onSave: T.func,
  onSubmit: T.func,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

WizardButtons.defaultProps = {
  disableIfInactiveInsurances: false,
  handleSubmit: null,
  isDetailsStep: false,
  isConditionStep: false,
  isSummaryStep: false,
  isPending: false,
  onPrevious: null,
  onSave: null,
  onSubmit: null,
};

export default WizardButtons;
