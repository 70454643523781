import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import * as m from '../../../promotion/model';
import * as s from '../../../promotion/selectors';
import * as act from '../../../promotion/actionsUpdateForm';
import CreateWizardStepDetails from './CreateWizardStepDetails';
import CreateWizardStepConditionInterest from './CreateWizardStepConditionInterest';
import CreateWizardStepConditionFactor from './CreateWizardStepConditionFactor';
import CreateWizardStepSummary from './CreateWizardStepSummary';
import { CONTRACT_TYPE_TA } from '../../../promotion/constants';
import formName from './formName';

const STEP_DETAILS = 'Details';
const STEP_CONDITIONS = 'Conditions';
const STEP_SUMMARY = 'Summary';

class CreateWizard extends Component {
  state = {
    step: STEP_DETAILS,
    condition: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.idChanged();
    }
  }

  onStepConditions = (values) => {
    this.props.dispatch(change(formName, 'condition', values.condition));
    this.setState({
      step: STEP_CONDITIONS,
      condition: values.condition,
    });
  };

  onStepDetails = () => {
    this.setState({
      step: STEP_DETAILS,
    });
  };

  onStepSummary = () => {
    this.props.onUpdateTable();
    this.setState({
      step: STEP_SUMMARY,
    });
  };

  onBackToStepConditions = () => {
    this.setState({
      step: STEP_CONDITIONS,
    });
  };

  idChanged = () => {
    this.setState({
      step: STEP_DETAILS,
      condition: '',
    });
  }

  checkConditionForSummaryStep = (formData) => {
    const { condition } = this.state;
    if (condition === 'interest' || (!condition && formData.insurance_parameters)) return 'interest';
    return 'factor';
  }

  render() {
    const {
      contractTypes,
      id,
      isUpdatePending,
      isGetContractTypesPending,
      isGetProductGroupsPending,
      formData,
      onCancel,
      onSubmit,
      productGroups,
    } = this.props;

    const showResidualValue = Number(formData.contract_type) === CONTRACT_TYPE_TA;

    return (
      <div>
        {this.state.step === STEP_DETAILS
          && (
          <CreateWizardStepDetails
            contractTypes={contractTypes}
            id={id}
            isGetContractTypesPending={isGetContractTypesPending}
            isGetProductGroupsPending={isGetProductGroupsPending}
            onCancel={onCancel}
            onSave={this.onStepSummary}
            onSubmit={this.onStepConditions}
            productGroups={productGroups}
            showResidualValue={showResidualValue}
          />
          )}
        {this.state.step === STEP_CONDITIONS
          && this.state.condition === 'interest' && (
            <CreateWizardStepConditionInterest
              isUpdatePending={isUpdatePending}
              onPrevious={this.onStepDetails}
              onCancel={onCancel}
              onSubmit={this.onStepSummary}
            />
        )}
        {this.state.step === STEP_CONDITIONS
          && this.state.condition === 'factor' && (
            <CreateWizardStepConditionFactor
              isUpdatePending={isUpdatePending}
              onCancel={onCancel}
              onPrevious={this.onStepDetails}
              onSubmit={this.onStepSummary}
              elems={this.state.elems}
            />
        )}
        {this.state.step === STEP_SUMMARY
          && (
          <CreateWizardStepSummary
            condition={this.state.condition}
            onCancel={onCancel}
            onPrevious={this.onBackToStepConditions}
            onSubmit={(args) => onSubmit({
              ...args, condition: this.checkConditionForSummaryStep(formData),
            })}
            showResidualValue={showResidualValue}
          />
          )}
      </div>
    );
  }
}

CreateWizard.propTypes = {
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  dispatch: T.func.isRequired,
  formData: T.any.isRequired,
  id: T.string.isRequired,
  isUpdatePending: T.bool.isRequired,
  isGetContractTypesPending: T.bool.isRequired,
  isGetProductGroupsPending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onSubmit: T.func.isRequired,
  onUpdateTable: T.func.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
};

const fetchFormData = (state) => formValueSelector(formName)(state,
  'contract_type',
  'insurance_parameters',
  'elems');

const mapStateToProps = (state) => ({
  contractTypes: s.getContractTypes(state),
  formData: fetchFormData(state),
  isGetContractTypesPending: s.getIsGetContractTypesPending(state),
  isGetProductGroupsPending: s.getIsGetProductGroupsPending(state),
  productGroups: s.getProductGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onUpdateTable() {
    dispatch(act.updateTable());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWizard);
