import { NAME } from './constants'; // eslint-disable-line

export const EDIT_START = `${NAME}/EDIT_START`;
export const EDIT_STOP = `${NAME}/EDIT_STOP`;
export const GET_SALESMAN_FULFILLED = `${NAME}/GET_SALESMAN_FULFILLED`;
export const GET_SALESMAN_PENDING = `${NAME}/GET_SALESMAN_PENDING`;
export const GET_SALESMAN_REJECTED = `${NAME}/GET_SALESMAN_REJECTED`;
export const LIST_SALESMEN_FULFILLED = `${NAME}/LIST_SALESMEN_FULFILLED`;
export const LIST_SALESMEN_PENDING = `${NAME}/LIST_SALESMEN_PENDING`;
export const LIST_SALESMEN_REJECTED = `${NAME}/LIST_SALESMEN_REJECTED`;
export const SAVE_SALESMAN_FULFILLED = `${NAME}/SAVE_SALESMAN_FULFILLED`;
export const SAVE_SALESMAN_PENDING = `${NAME}/SAVE_SALESMAN_PENDING`;
export const SAVE_SALESMAN_REJECTED = `${NAME}/SAVE_SALESMAN_REJECTED`;
