import React from 'react';
import { fieldPropTypes } from 'redux-form';
import * as b from 'react-bootstrap';
import DatePicker from 'react-bootstrap-date-picker';
import validationState from './validationState';

const DateRangeField = ({
  disabled,
  input,
  meta,
  placeholder,
  required,
}) => (
  <b.FormGroup controlId={input.name} validationState={validationState(meta)}>
    <DatePicker
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      {...input}
    />
    {meta.touched && meta.error
      && <b.HelpBlock>{meta.error}</b.HelpBlock>}
  </b.FormGroup>
);

DateRangeField.defaultProps = {
  custom: {},
};

DateRangeField.propTypes = {
  ...fieldPropTypes,
};

export default DateRangeField;
