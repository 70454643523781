import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import * as typ from './actionTypes';

export const initialState = {
  error: null,
  isListPromotionsPending: false,
  isGetContractTypesPending: false,
  isGetProductGroupsPending: false,
  isGetPromotionPending: false,
  promotions: [],
  promotionSummariesById: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case typ.GET_PROMOTION_FULFILLED:
      return {
        ...state,
        error: null,
        isGetPromotionPending: false,
        promotionSummariesById: {
          ...state.promotionSummariesById,
          [action.payload.id]: action.payload,
        },
      };
    case typ.GET_PROMOTION_PENDING:
      return {
        ...state,
        isGetPromotionPending: true,
      };
    case typ.GET_PROMOTION_REJECTED:
      return {
        ...state,
        isGetPromotionPending: false,
        error: action.payload,
      };
    case typ.LIST_PROMOTIONS_FULFILLED:
      return {
        ...state,
        error: null,
        isListPromotionsPending: false,
        promotions: _map(action.payload.Items, (prom) => prom.id),
        promotionSummariesById: _mapValues(
          _groupBy(action.payload.Items, (prom) => prom.id),
          (array) => array[0],
        ),
      };
    case typ.LIST_PROMOTIONS_PENDING:
      return {
        ...state,
        isListPromotionsPending: true,
      };
    case typ.LIST_PROMOTIONS_REJECTED:
      return {
        ...state,
        isListPromotionsPending: false,
        error: action.payload,
      };
    case typ.GET_CONTRACT_TYPES_FULFILLED:
      return {
        ...state,
        error: null,
        isGetContractTypesPending: false,
        contractTypes: action.payload,
      };
    case typ.GET_CONTRACT_TYPES_PENDING:
      return {
        ...state,
        isGetContractTypesPending: true,
      };
    case typ.GET_CONTRACT_TYPES_REJECTED:
      return {
        ...state,
        isGetContractTypesPending: false,
        error: action.payload,
      };
    case typ.GET_PRODUCT_GROUPS_FULFILLED:
      return {
        ...state,
        error: null,
        isGetProductGroupsPending: false,
        productGroups: action.payload,
      };
    case typ.GET_PRODUCT_GROUPS_PENDING:
      return {
        ...state,
        isGetProductGroupsPending: true,
      };
    case typ.GET_PRODUCT_GROUPS_REJECTED:
      return {
        ...state,
        isGetProductGroupsPending: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
