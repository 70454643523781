/* global axios */

import * as t from './actionTypes';

export function getLesseeData(httpClient = axios) {
  return (dispatch) => {
    dispatch({ type: t.LESSEE_DATA_PENDING });
    return httpClient.get('/api/lessee')
      .then((res) => {
        dispatch({
          type: t.LESSEE_DATA_FULFILLED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: t.LESSEE_DATA_REJECTED,
          payload: err,
        });
      });
  };
}
