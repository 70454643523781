import React, { Component } from 'react';
import { PropTypes as T } from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import * as m from '../../../promotion/model';
import * as s from '../../../promotion/selectors';
import * as act from '../../../promotion/actionsCreateForm';
import CreateWizardStepDetails from './CreateWizardStepDetails';
import CreateWizardStepConditionInterest from './CreateWizardStepConditionInterest';
import CreateWizardStepConditionFactor from './CreateWizardStepConditionFactor';
import CreateWizardStepSummary from './CreateWizardStepSummary';
import parseForm from './parseForm';
import { CONTRACT_TYPE_TA } from '../../../promotion/constants';
import formName from './formName';

const STEP_DETAILS = 'Details';
const STEP_CONDITIONS = 'Conditions';
const STEP_SUMMARY = 'Summary';

class CreateWizard extends Component {
  state = {
    step: STEP_DETAILS,
    condition: '',
  };

  render() {
    const {
      contractTypes,
      isCreatePending,
      isGetContractTypesPending,
      isGetProductGroupsPending,
      formData,
      onCancel,
      onContinueForCondition,
      onSubmit,
      productGroups,
    } = this.props;

    const onStepConditions = (values) => {
      onContinueForCondition(values.condition);
      this.setState({
        step: STEP_CONDITIONS,
        condition: values.condition,
      });
    };
    const onStepDetails = () => {
      this.setState({
        step: STEP_DETAILS,
      });
    };
    const onStepSummary = () => {
      this.setState({
        step: STEP_SUMMARY,
      });
    };
    const onBackToStepConditions = () => {
      this.setState({
        step: STEP_CONDITIONS,
      });
    };

    const showResidualValue = formData.contract_type === CONTRACT_TYPE_TA;

    return (
      <div>
        {this.state.step === STEP_DETAILS
          && (
          <CreateWizardStepDetails
            contractTypes={contractTypes}
            isGetContractTypesPending={isGetContractTypesPending}
            isGetProductGroupsPending={isGetProductGroupsPending}
            onCancel={onCancel}
            onSubmit={onStepConditions}
            productGroups={productGroups}
            showResidualValue={showResidualValue}
          />
          )}
        {this.state.step === STEP_CONDITIONS
          && this.state.condition === 'interest' && (
            <CreateWizardStepConditionInterest
              onPrevious={onStepDetails}
              onCancel={onCancel}
              onSubmit={onStepSummary}
            />
        )}
        {this.state.step === STEP_CONDITIONS
          && this.state.condition === 'factor' && (
            <CreateWizardStepConditionFactor
              onCancel={onCancel}
              onPrevious={onStepDetails}
              onSubmit={onStepSummary}
              elems={this.state.elems}
            />
        )}
        {this.state.step === STEP_SUMMARY
          && (
          <CreateWizardStepSummary
            condition={this.state.condition}
            isPending={isCreatePending}
            onCancel={onCancel}
            onPrevious={onBackToStepConditions}
            onSubmit={onSubmit}
            showResidualValue={showResidualValue}
          />
          )}
      </div>
    );
  }
}

CreateWizard.propTypes = {
  contractTypes: T.arrayOf(m.contractTypeShape).isRequired,
  formData: T.shape({
    contract_type: T.number.isRequired,
  }).isRequired,
  isCreatePending: T.bool.isRequired,
  isGetContractTypesPending: T.bool.isRequired,
  isGetProductGroupsPending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onContinueForCondition: T.func.isRequired,
  onSubmit: T.func.isRequired,
  productGroups: T.arrayOf(m.productGroupShape).isRequired,
};

const fetchFormData = (state) => parseForm(formValueSelector(formName)(state,
  'contract_type',
  'promotion_name'));

const mapStateToProps = (state) => ({
  contractTypes: s.getContractTypes(state),
  formData: fetchFormData(state),
  isCreatePending: s.getIsCreatePromotionPending(state),
  isGetContractTypesPending: s.getIsGetContractTypesPending(state),
  isGetProductGroupsPending: s.getIsGetProductGroupsPending(state),
  productGroups: s.getProductGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onContinueForCondition(condition) {
    dispatch(act.continueForCondition(condition));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWizard);
