import React from 'react';
import { PropTypes as T } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as b from 'react-bootstrap';
import Spinner from '../../Spinner';
import InputField from '../../InputField';
import SalutationRadioField from '../../SalutationRadioField';
import YesNoRadioField from '../../YesNoRadioField';
import validate from '../create/validate';

const EditForm = ({
  error,
  handleSubmit,
  isPending,
  onCancel,
  pristine,
  submitting,
}) => (
  <b.Form onSubmit={handleSubmit}>
    {error && <b.Alert bsStyle="danger">{error}</b.Alert>}
    <Field
      name="salutation"
      title="Anrede"
      required
      component={SalutationRadioField}
    />
    <Field
      name="first_name"
      title="Vorname"
      required
      component={InputField}
      type="text"
    />
    <Field
      name="last_name"
      title="Nachname"
      required
      component={InputField}
      type="text"
    />
    <Field
      name="phone"
      title="Telefonnummer"
      required
      component={InputField}
      type="text"
    />
    <Field
      name="email"
      title="E-Mail"
      required
      component={InputField}
      type="text"
    />
    <Field
      name="active"
      title="Aktiv?"
      required
      component={YesNoRadioField}
    />
    <Field
      name="only_own"
      title="Kann nur eigene Anträge/Angebote sehen?"
      required
      component={YesNoRadioField}
    />
    <b.Button bsSize="sm" onClick={onCancel}>Abbrechen</b.Button>
    <div style={{ float: 'right' }}>
      <b.Button type="submit" bsStyle="primary" bsSize="sm" disabled={pristine || submitting}>
        {isPending && <Spinner />}
        Verkäufer speichern
      </b.Button>
    </div>
    <p className="hint-required">
      Pflichtfeld
      <span className="asterisk-required">*</span>
    </p>
  </b.Form>
);

EditForm.propTypes = {
  error: T.string,
  handleSubmit: T.func.isRequired,
  isPending: T.bool.isRequired,
  onCancel: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

EditForm.defaultProps = {
  error: null,
};

export default reduxForm({
  form: 'edit_salesman',
  validate,
})(EditForm);
