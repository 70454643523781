import React from 'react';
import { PropTypes as T } from 'prop-types';
import Helmet from 'react-helmet';
import * as b from 'react-bootstrap';
import FA from 'react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import * as m from '../../../salesman/model';
import Salesman from './Salesman';

const ListPage = ({ salesmen }) => (
  <div>
    <Helmet title="Verkäufer" />
    <LinkContainer to="/salesmen/create">
      <b.Button bsStyle="primary">
        <FA name="plus-circle icon-left" />
        Neuen Verkäufer anlegen
      </b.Button>
    </LinkContainer>
    {salesmen.map((s) => <Salesman key={s.id} salesman={s} />)}
  </div>
);

ListPage.propTypes = {
  salesmen: T.arrayOf(m.salesmanShape).isRequired,
};

export default ListPage;
