import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import { NAME, CONTRACT_TYPE_TA } from './constants';

export const getContractTypes = (state) => state[NAME].contractTypes || [];
export const getIsGetContractTypesPending = (state) => !!state[NAME].isGetContractTypesPending;
export const getIsGetLegalFormsPending = (state) => !!state[NAME].isGetLegalFormsPending;
export const getIsGetProductGroupsPending = (state) => !!state[NAME].isGetProductGroupsPending;
export const getIsGetPromotionPending = (state) => !!state[NAME].isGetPromotionPending;
export const getIsListPromotionsPending = (state) => !!state[NAME].isListPromotionsPending;
export const getIsCreatePromotionPending = (state) => !!state[NAME].isCreatePromotionPending;
export const getLegalForms = (state) => state[NAME].legalForms || [];
export const getProductGroups = (state) => state[NAME].productGroups || [];
export const listPromotions = (state) => _map(state[NAME].promotions, (id) => state[NAME].promotionSummariesById[id]);
export const getPromotion = (id) => (state) => state[NAME].promotionSummariesById[id];
export const getPromotionsAssigned = (insuranceId) => (state) => {
  const promotionConditionsArray = state[NAME].promotions
    .map((promotionId) => ({
      ...getPromotion(promotionId)(state).condition,
      promotionName: getPromotion(promotionId)(state).promotionName,
      promotionId,
    }));

  const promotionsAssigned = promotionConditionsArray.filter((condition) => {
    if (Object.keys(condition)[0] === 'interest') {
      if (!condition.interest.insurance) return false;
      return condition.interest.insurance.id === insuranceId;
    }
    if (Object.keys(condition)[0] === 'factor') {
      const insurancesOfPromotionArrayOfArrays = condition.factor.elems.map((el) => el.contractTerms)
        .map((ct) => Object.values(ct));
      const insurancesOfPromotionArray = [].concat(...insurancesOfPromotionArrayOfArrays);
      const insurancesMatched = insurancesOfPromotionArray
        .filter((ins) => ins.insurance && ins.insurance.id === insuranceId);
      if (insurancesMatched.length !== 0) return true;
    }
    return false;
  });
  return promotionsAssigned;
};
export const getInsurancesDeactivedAssigned = (id) => (state) => {
  let insurancesAssigned = [];
  const { condition } = getPromotion(id)(state);
  const isConditionInterest = Object.keys(condition)[0] === 'interest';
  if (isConditionInterest) {
    if (condition.interest.insurance && condition.interest.insurance.isDeactivated) {
      return [condition.interest.insurance];
    }
    return [];
  }
  const { elems } = condition.factor;
  elems.forEach((el) => {
    Object.keys(el.contractTerms).forEach((ct) => {
      if (el.contractTerms[ct].insurance && el.contractTerms[ct].insurance.isDeactivated) {
        insurancesAssigned = [...insurancesAssigned, el.contractTerms[ct].insurance];
      }
    });
  });

  const uniqueInsurances = _uniqBy(insurancesAssigned, 'id');

  return uniqueInsurances;
};
export const getPromotionCondition = (id) => (state) => Object.keys(getPromotion(id)(state).condition)[0];
export const getInitialValuesForUpdateForm = (id) => (state) => {
  const promotion = getPromotion(id)(state);
  const condition = Object.keys(promotion.condition)[0];

  const residualMandatory = Number(promotion.contractType) === CONTRACT_TYPE_TA;

  let initialValues = {
    id,
    promotion_period_from: promotion.promotionPeriod.from.toString(),
    promotion_period_to: promotion.promotionPeriod.to.toString(),
    promotion_name: promotion.promotionName.toString(),
    promotion_description: promotion.promotionDescription ? promotion.promotionDescription.toString() : '',
    contract_term_from: promotion.contractTerm.from.toString(),
    contract_term_to: promotion.contractTerm.to ? promotion.contractTerm.to.toString() : 'unendlich',
    purchase_price_from: promotion.purchasePrice.from.toString(),
    purchase_price_to: promotion.purchasePrice.to ? promotion.purchasePrice.to.toString() : 'unendlich',
    down_payment_from: promotion.downPayment.from.toString(),
    down_payment_to: promotion.downPayment.to !== null ? promotion.downPayment.to.toString() : 'unendlich',
    is_deactivated: promotion.isDeactivated,
    is_initial_deactivated: promotion.isDeactivated,
    is_activation_accepted: false,
    financing_value_from: promotion.financingValue.from.toString(),
    financing_value_to: promotion.financingValue.to ? promotion.financingValue.to.toString() : 'unendlich',
    contract_type: promotion.contractType && promotion.contractType.toString(),
    product_group: promotion.productGroup && promotion.productGroup.toString(),
    region_from: promotion.region.from ? promotion.region.from.toString() : '00000',
    region_to: promotion.region.to ? promotion.region.to.toString() : '99999',
    retailers: promotion.retailers.join(', '),
    retailer_group: promotion.retailerGroup && promotion.retailerGroup.toString(),
  };

  if (residualMandatory) {
    initialValues = {
      ...initialValues,
      residual_value_from: promotion.residualValue.from
        && promotion.residualValue.from.toString().replace(',', '.'),
      residual_value_to: promotion.residualValue.to
        && promotion.residualValue.to.toString().replace(',', '.'),
    };
  }

  if (condition === 'interest') {
    initialValues.interest_condition = promotion.condition.interest.interestValue.toString();
    const ins = promotion.condition.interest.insurance;
    if (ins) {
      let insForSelectField = `${ins.id}~${ins.value}~${ins.type}~${ins.isDeactivated}`;
      if (ins.type === '%') insForSelectField = `${ins.id}~${ins.value}~${ins.type}~${ins.minValue}~${ins.isDeactivated}`;
      initialValues.insurance_parameters = insForSelectField;
      initialValues['insurance_parameters-string'] = insForSelectField
        .substring(insForSelectField.indexOf('~'))
        .replace(/~|true|false/g, ' ');
    }

    // for default factor table view
    initialValues.elems = [
      {
        purchasePrice: 15000,
        contractTerms: {
          36: {
            factorValue: null,
            insurance: {},
          },
          48: {
            factorValue: null,
            insurance: {},
          },
          60: {
            factorValue: null,
            insurance: {},
          },
        },
      },
      {
        purchasePrice: 5000,
        contractTerms: {
          36: {
            factorValue: null,
            insurance: null,
          },
          48: {
            factorValue: null,
            insurance: {},
          },
          60: {
            factorValue: null,
            insurance: {},
          },
        },
      },
      {
        purchasePrice: 10000,
        contractTerms: {
          36: {
            factorValue: null,
            insurance: {},
          },
          48: {
            factorValue: null,
            insurance: {},
          },
          60: {
            factorValue: null,
            insurance: {},
          },
        },
      },
    ];
  }
  if (condition === 'factor') {
    const { elems } = promotion.condition.factor;
    initialValues.elems = elems;
    let purchasePrice = '';
    let contractTerms = 0;
    let ins = null;
    let insForSelectField = null;
    elems.forEach((el) => {
      purchasePrice = el.purchasePrice;
      contractTerms = el.contractTerms;
      ins = null;
      insForSelectField = null;

      Object.keys(contractTerms).forEach((ct) => {
        ins = contractTerms[ct].insurance;
        insForSelectField = null;
        if (ins) {
          insForSelectField = `${ins.id}~${ins.value}~${ins.type}~${ins.isDeactivated}`;
          if (ins.type === '%') insForSelectField = `${ins.id}~${ins.value}~${ins.type}~${ins.minValue}~${ins.isDeactivated}`;
          initialValues[
            `insurance_parameters-row${ct}-col${purchasePrice}`
          ] = insForSelectField;
          initialValues[
            `insurance_parameters-string-row${ct}-col${purchasePrice}`
          ] = insForSelectField
            .substring(insForSelectField.indexOf('~'))
            .replace(/~|true|false/g, ' ');
        }
        if (contractTerms[ct].factorValue) {
          initialValues[
            `factor_value-row${ct}-col${purchasePrice}`
          ] = contractTerms[ct].factorValue.toString();
        }
      });
    });
  }
  return initialValues;
};
